import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { apiError: string | null } = {
  apiError: null,
};

const ApiErrorSlice = createSlice({
  name: "apiError",
  initialState,
  reducers: {
    setApiError(state, action: PayloadAction<string | null>) {
      state.apiError = action.payload;
    },
  },
});

export const { setApiError } = ApiErrorSlice.actions;
export default ApiErrorSlice.reducer;
