import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchResult } from "interfaces/elastic-search";
import { getBooleanFromString } from "../utils/local-storage";

export type LanguageType =
  | "NL"
  | "BE"
  | "DE"
  | "CH"
  | "EN"
  | "FR"
  | "LU"
  | "HU"
  | "CZ"
  | "APAC"
  | "US"
  | "PL"
  | "ES"
  | "SK";
export const isLanguageType = (code: string) =>
  [
    "NL",
    "BE",
    "DE",
    "CH",
    "EN",
    "FR",
    "LU",
    "HU",
    "CZ",
    "APAC",
    "US",
    "PL",
    "ES",
    "SK",
  ].includes(code);
export type CountryType =
  | "NL"
  | "BE"
  | "DE"
  | "CH"
  | "UK" // UK for country and EN for language
  | "FR"
  | "LU"
  | "HU"
  | "CZ"
  | "APAC"
  | "US"
  | "PL"
  | "ES"
  | "SK";
export type defaultCustomerid = {
  selectedCustomer: { customerNo: string };
};
export const isCountryType = (code: string) =>
  [
    "NL",
    "BE",
    "DE",
    "CH",
    "UK",
    "FR",
    "LU",
    "HU",
    "CZ",
    "APAC",
    "US",
    "PL",
    "ES",
    "SK",
  ].includes(code);
export const defaultAllAssortment = true;
export const defaultCountryCode = "NL";
export const defaultLanguageCode = "NL";

export const allAssortmentsValue = localStorage.getItem("allAssortments");
export const countryCodeValue = localStorage.getItem("countryCode");
export const erpSystemCodeValue = localStorage.getItem("erpSystemCode");
export const languageCodeValue = localStorage.getItem("languageCode");

interface ContextState {
  languageCode: LanguageType;
  countryCode: CountryType;
  erpSystemCode: string;
  allAssortments: boolean;
  isSelectorOpen: boolean;
  searchQuery: string;
  customerid: string;
  brandLabel: string;
  brandName: string;
  headerSearchBrandName: string;
  searchResult: SearchResult | null;
  callLoading: boolean | null;
  isSearchModalActive: boolean;
  turboModeFilters: any[];
}

const initialState: ContextState = {
  languageCode: !!languageCodeValue
    ? (languageCodeValue as LanguageType)
    : defaultLanguageCode,
  countryCode: !!countryCodeValue
    ? (countryCodeValue as CountryType)
    : defaultCountryCode,
  allAssortments: !!allAssortmentsValue
    ? getBooleanFromString(allAssortmentsValue, defaultAllAssortment)
    : defaultAllAssortment,
  erpSystemCode: !!erpSystemCodeValue ? erpSystemCodeValue : defaultCountryCode,
  isSelectorOpen: false,
  searchQuery: "",
  customerid: "",
  brandLabel: "",
  brandName: "",
  headerSearchBrandName: "",
  searchResult: null,
  callLoading: null,
  isSearchModalActive: false,
  turboModeFilters: [],
};

const ContextSlice = createSlice({
  name: "context",
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<LanguageType>) {
      state.languageCode = action.payload;
    },
    setCountry(state, action: PayloadAction<CountryType>) {
      state.countryCode = action.payload;
    },
    setERPSystem(state, action: PayloadAction<CountryType>) {
      const isMockedERPSystem = ["HU", "CZ", "APAC", "PL", "ES", "SK"].includes(
        action.payload
      );

      if (isMockedERPSystem) {
        state.erpSystemCode = defaultCountryCode;
      } else {
        state.erpSystemCode = action.payload;
      }
    },
    setAllAssortments(state, action: PayloadAction<boolean>) {
      state.allAssortments = action.payload;
    },
    setIsSelectorOpen(state, action: PayloadAction<boolean>) {
      state.isSelectorOpen = action.payload;
    },
    setSearchQuery(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload;
    },
    setCustomerId(state, action: PayloadAction<string>) {
      state.customerid = action.payload;
    },
    setBrandLabel(state, action: PayloadAction<string>) {
      state.brandLabel = action.payload;
    },
    setBrandName(state, action: PayloadAction<string>) {
      state.brandName = action.payload;
    },
    setHeaderSearchBrandName(state, action: PayloadAction<string>) {
      state.headerSearchBrandName = action.payload;
    },
    setSearchResult(state, action: PayloadAction<SearchResult | null>) {
      return { ...state, searchResult: action.payload };
    },
    setCallLoading(state, action: PayloadAction<boolean | null>) {
      state.callLoading = action.payload;
    },
    setIsSearchModalActive(state, action: PayloadAction<boolean>) {
      state.isSearchModalActive = action.payload;
    },
    setTurboModeFilters(state, action: PayloadAction<any>) {
      state.turboModeFilters = action.payload;
    },
    setMultipleValue(state, action: PayloadAction<Partial<ContextState>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const {
  setLanguage,
  setCountry,
  setERPSystem,
  setAllAssortments,
  setIsSelectorOpen,
  setMultipleValue,
  setSearchQuery,
  setCustomerId,
  setBrandLabel,
  setBrandName,
  setHeaderSearchBrandName,
  setSearchResult,
  setCallLoading,
  setIsSearchModalActive,
  setTurboModeFilters,
} = ContextSlice.actions;
export default ContextSlice.reducer;
