import { Icon } from "@eriksdigital/atomic-ui/components";
import { breakpoints } from "@eriksdigital/atomic-ui/styles";
import styled, { createGlobalStyle } from "styled-components";

export const StyledIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.heading.primary};
  height: ${({ theme }) => theme.sizes.sz20};
  width: ${({ theme }) => theme.sizes.sz20};
  transform: translate(5px, 5px);
`;

export const MainSearch = styled.div`
  position: relative;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  ${breakpoints.tabletIpadPortrait`
    flex-direction: row;
  `};
  h1 {
    margin-right: auto;
  }
`;

export const ResultTypeHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Box = styled.div`
  background-color: #fff;
  padding: ${({ theme }) => `${theme.spacing.sp8} ${theme.spacing.sp24}`};
  margin-bottom: ${({ theme }) => theme.spacing.sp48};
`;

export const BoxFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MoreButton = styled.span`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
`;

export const MainContent = styled.div`
  margin: ${({ theme }) => `${theme.spacing.sp8} 0 5.3rem`};
  h1 {
    margin-bottom: 1rem;
  }
`;

export const GlobalHome = createGlobalStyle`
  body {
    background-color: #f8f8f8;
  }
`;

export const StickyHeaderContainer = styled.div<{
  top: string;
}>`
  position: fixed;
  top: ${({ top }) => top};
  width: 100%;
  z-index: 9;
  background-color: ${({ theme }) => theme.colors.default.lightGray};
  border-top: 1px solid ${({ theme }) => theme.colors.default.midGray};
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.midGray};
  padding: ${({ theme }) => theme.spacing.sp16} 0;
`;

export const CompareContainer = styled.div`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  color: ${({ theme }) => theme.colors.default.darkGray};
  margin-left: -${({ theme }) => theme.spacing.sp16};
  margin-bottom: -${({ theme }) => theme.spacing.sp16};
`;
