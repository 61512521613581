import React, { ReactNode, useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { contextSelector } from "../state/selector";

export const ConnectedIntlProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { languageCode } = useSelector(contextSelector);

  const [messages, setMessages] = useState<{}>({});

  useEffect(() => {
    const loadMessages = async () => {
      try {
        const m = await import(`../locales/${languageCode}`);
        setMessages(m.default);
      } catch (error) {
        console.error(error);
      }
    };

    loadMessages();
  }, [languageCode]);

  return (
    <IntlProvider locale={languageCode} messages={messages}>
      {children}
    </IntlProvider>
  );
};
