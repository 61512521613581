import gql from "graphql-tag";

export const CustomerField = gql`
  fragment CustomerField on Customer {
    customerCountryCode
    companyCountryCode
    customerCity
    customerName2
    econostoCode
    customerNo
    customerName
  }
`;

export const SEARCH_CUSTOMER = gql`
  ${CustomerField}
  query SearchCustomers(
    $query: String!
    $searchIndex: String!
    $countryCode: String!
  ) {
    SearchCustomers(
      query: $query
      searchIndex: $searchIndex
      countryCode: $countryCode
    ) {
      ...CustomerField
    }
  }
`;

export const GET_SINGLE_CUSTOMER = gql`
  ${CustomerField}
  query getSingleCustomer($customerNo: String!, $companyCountryCode: String!) {
    Customer(customerNo: $customerNo, companyCountryCode: $companyCountryCode) {
      ...CustomerField
    }
  }
`;
