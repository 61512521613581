import styled from "styled-components";
import { Link } from "@eriksdigital/atomic-ui/components";

export const NewsContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.sp16} 0;
  border: 1px solid ${({ theme }) => theme.colors.default.gray};
  padding: ${({ theme }) => `${theme.spacing.sp4} ${theme.spacing.sp16}`};
  border-radius: ${({ theme }) => theme.spacing.sp16};
  border-left: 4px solid ${({ theme }) => theme.colors.default.yellow};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IconContainer = styled.div`
  margin-right: ${({ theme }) => theme.spacing.sp8};
  padding-top: ${({ theme }) => theme.spacing.sp8};
  svg {
    height: ${({ theme }) => theme.spacing.sp24};
    width: ${({ theme }) => theme.spacing.sp24};
  }
`;

export const LinkContainer = styled(Link)`
  margin-left: auto;
`;

export const HeaderBannerWrap = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft16};
  margin: ${({ theme }) => theme.spacing.sp12} 0
    ${({ theme }) => theme.spacing.sp32};
`;

export const HeaderBannerPill = styled.div`
  background-color: ${({ theme }) => theme.colors.background.cta};
  color: ${({ theme }) => theme.colors.default.black};
  padding: ${({ theme }) => theme.spacing.sp4}
    ${({ theme }) => theme.spacing.sp8};
  border-radius: ${({ theme }) => theme.spacing.sp16};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
  margin-right: ${({ theme }) => theme.spacing.sp8};
  min-width: fit-content;
`;

export const HeaderBannerDate = styled.div``;

export const HeaderBannerMessage = styled.div`
  a {
    font-size: ${({ theme }) => theme.fonts.fontSize.ft16};
  }
`;
