import React from "react";
import { useParams, useLocation, LinkProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/react-hooks";
import { Breadcrumb, Link } from "@eriksdigital/atomic-ui/components";

import {
  ITEM_BREADCRUMB_QUERY,
  PRODUCT_BREADCRUMB_QUERY,
  PRODUCT_GROUP_BREADCRUMB_QUERY,
} from "./queries";
import { contextSelector } from "state/selector";
import { ItemBreadCrumb } from "./schema/ItemBreadCrumb";
import { ProductBreadCrumb } from "./schema/ProductBreadCrumb";
import { ProductGroupBreadCrumb } from "./schema/ProductGroupBreadCrumb";

import { getItemFilter } from "containers/CompareItems/QueryComponent";
import { Loading } from "components/Loader";
import { Error } from "../Error";

import { BreadcrumbContainer, StyledLink } from "./style";
import { getCoreParams, getModifiedParams } from "utils/param";

export const NavLink = (
  props: React.PropsWithoutRef<LinkProps<any>> &
    React.RefAttributes<HTMLAnchorElement>
) => {
  return (
    <Link as="span">
      <StyledLink {...props} />
    </Link>
  );
};

type OrNull<P> = P | null;

// TO AVOID EMPTY BREADCRUMB
// We will show the description in the language code, otherwise we will show the first one.
const getItem = <P extends { languageCode: string }>(
  code: string,
  items: OrNull<P>[] | null | undefined
): OrNull<P> => {
  if (!items) return null;
  const itemInLanguageCode = (items || []).find(
    (i) => i?.languageCode === code
  );
  const firstItem = items?.[0];
  return itemInLanguageCode ?? firstItem ?? null;
};

const variants = {
  item: {
    query: ITEM_BREADCRUMB_QUERY,
    getTitle: (languageCode: string, data: ItemBreadCrumb) => {
      const selectedItem = getItem(languageCode, data?.Item?.[0]?.Descriptions);
      return selectedItem?.shortDescription ?? null;
    },
    getParams: (params: any) => {
      return {
        partNumber: params.partNumber,
        filter: getItemFilter(params.allAssortments, params.countryCode),
      };
    },
    getHistoryPages: (
      languageCode: string,
      data: ItemBreadCrumb,
      search = ""
    ) => {
      const productGroup = data?.Item?.[0]?.Product?.[0]?.ProductGroup?.[0];
      const productGroupNames = productGroup?.Names;
      const getSelectedProductGroupNames = getItem(
        languageCode,
        productGroupNames
      );
      const productGroupName = getSelectedProductGroupNames?.name;
      const linkToProductGroup = productGroup
        ? [
            {
              name: productGroupName,
              to: {
                pathname: `/group/${encodeURIComponent(
                  productGroup?.groupIdentifier
                )}`,
                search: search.toString(),
              },
            },
          ]
        : [];

      const product = data?.Item?.[0]?.Product?.[0];
      const productDescription = product?.Descriptions;
      const getSelectedProductDescription = getItem(
        languageCode,
        productDescription
      );
      const productName = getSelectedProductDescription?.shortDescription;
      const linkToProduct = product
        ? [
            {
              name: productName,
              to: {
                pathname: `/product/${encodeURIComponent(product?.partNumber)}`,
                search: search.toString(),
              },
            },
          ]
        : [];

      const coreParams = getModifiedParams(
        search.toString(),
        getCoreParams(search.toString())
      );

      return [
        { name: "Home", to: { pathname: "/", search: coreParams.toString() } },
        ...linkToProductGroup,
        ...linkToProduct,
      ];
    },
  },
  product: {
    query: PRODUCT_BREADCRUMB_QUERY,
    getTitle: (languageCode: string, data: ProductBreadCrumb) => {
      const selectedItem = getItem(
        languageCode,
        data?.Product?.[0]?.Descriptions
      );
      return selectedItem?.shortDescription ?? null;
    },
    getParams: (params: any) => {
      return { partNumber: params.partNumber };
    },
    getHistoryPages: (
      languageCode: string,
      data: ProductBreadCrumb,
      search = ""
    ) => {
      const productGroup = data?.Product?.[0]?.ProductGroup?.[0];
      const productGroupNames = productGroup?.Names;
      const getSelectedProductGroupNames = getItem(
        languageCode,
        productGroupNames
      );
      const productGroupName = getSelectedProductGroupNames?.name;
      const linkToProductGroup = productGroup
        ? [
            {
              name: productGroupName,
              to: {
                pathname: `/group/${encodeURIComponent(
                  productGroup?.groupIdentifier
                )}`,
                search: search.toString(),
              },
            },
          ]
        : [];

      const coreParams = getModifiedParams(
        search.toString(),
        getCoreParams(search.toString())
      );

      return [
        { name: "Home", to: { pathname: "/", search: coreParams.toString() } },
        ...linkToProductGroup,
      ];
    },
  },
  productGroup: {
    query: PRODUCT_GROUP_BREADCRUMB_QUERY,
    getTitle: (languageCode: string, data: ProductGroupBreadCrumb) => {
      const selectedItem = getItem(
        languageCode,
        data?.ProductGroup?.[0]?.Names
      );
      return selectedItem?.name ?? null;
    },
    getParams: (params: any) => {
      return { groupIdentifier: params.groupIdentifier };
    },
    getHistoryPages: (languageCode: string, data: any, search = "") => {
      const coreParams = getModifiedParams(
        search.toString(),
        getCoreParams(search.toString())
      );
      return [
        { name: "Home", to: { pathname: "/", search: coreParams.toString() } },
      ];
    },
  },
};

export const NavSubHeader = ({
  pageType,
}: {
  pageType: "item" | "product" | "productGroup";
}) => {
  const params = useParams();
  const { search } = useLocation();
  const { languageCode, allAssortments, erpSystemCode } = useSelector(
    contextSelector
  );

  const { data, error, loading } = useQuery(variants[pageType].query, {
    variables: {
      ...variants[pageType].getParams({
        ...params,
        allAssortments,
        erpSystemCode,
      }),
    },
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <BreadcrumbContainer>
      <Breadcrumb
        link={NavLink}
        currentPage={variants[pageType].getTitle(languageCode, data)}
        historyPages={variants[pageType].getHistoryPages(
          languageCode,
          data,
          search
        )}
      />
    </BreadcrumbContainer>
  );
};
