import styled from "styled-components";
import { breakpoints } from "@eriksdigital/atomic-ui/styles";
import { Icon, Button, SearchForm } from "@eriksdigital/atomic-ui/components";

const defaultPXSize = 16;
export const pxToRem = (px: number) => `${px / defaultPXSize}rem`;

export const StyledBrandCrossIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.heading.primary};
  height: ${({ theme }) => theme.sizes.sz20};
  width: ${({ theme }) => theme.sizes.sz20};
  transform: translate(5px, 5px);
`;

export const StyledCrossIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.heading.dim};
  height: ${({ theme }) => theme.sizes.sz20};
  width: ${({ theme }) => theme.sizes.sz20};
  margin: 4px 15px 0 0px;
  cursor: pointer;
`;

export const StyledSearchIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.default.gray};
  height: ${({ theme }) => theme.sizes.sz24};
  width: ${({ theme }) => theme.sizes.sz24};
  transform: translate(9px, 6px);
  margin: 0 5px;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  ${breakpoints.tabletIpadPortrait`
    flex-direction: row;
  `};
  h1 {
    margin-right: auto;
  }
`;

export const SearchFormWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  > div,
  input {
    &,
    &:focus-within,
    &:hover {
      border: none;
      background: none;
      border-radius: 0;
    }
  }
`;

export const SearchFormSelectedBrand = styled.span`
  display: flex;
  align-items: baseline;
  background-color: ${({ theme }) => theme.colors.grays.lightGray};
  padding: ${({ theme }) => theme.spacing.sp4}
    ${({ theme }) => theme.spacing.sp8};
  margin-left: ${({ theme }) => theme.spacing.sp16};
  border-radius: ${({ theme }) => theme.spacing.sp16};
  max-width: max-content;
  width: 100%;
`;

export const SearchFormSelectedBrandLabel = styled.div`
  .brand {
    color: ${({ theme }) => theme.colors.text.cta};
    font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
  }
`;

export const SearchFormClearInputButton = styled(Button)`
  padding: 0 ${({ theme }) => theme.spacing.sp16};
`;

export const SearchFormContainer = styled.div<{
  sticky: boolean;
}>`
  display: flex;
  align-items: baseline;
  background-color: ${({ theme }) => theme.colors.default.white};
  border: 1px solid ${({ theme }) => theme.colors.default.gray};
  border-radius: 20px;

  ${(props) =>
    props.sticky ? "margin: 0 auto; width: 1288px;" : "min-width: 100%;"};
`;

export const DropdownContainer = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.colors.heading.primary};
  #select-source {
    border: none;
    background: none;
    > div {
      padding: ${({ theme }) => `0.6255rem ${theme.spacing.sp16}`};
      padding-right: ${({ theme }) => theme.spacing.sp16};
    }
    > ul {
      border-top-color: ${({ theme }) => theme.colors.heading.primary};
      padding: 0;
      border-radius: ${({ theme }) => theme.spacing.sp8};
      border-top-right-radius: 0;
    }
  }
`;

export const StyledSearchForm = styled(SearchForm)<{ type: string }>`
  ${(props) => props.type === "header-search" && "z-index: 30; border: none"}
`;

export const AutoSuggestBrandsWrapper = styled.div<{ searchRealm: string }>`
  margin-left: ${(props) =>
    props.searchRealm === "header-search" ? "0" : pxToRem(120)};
  position: absolute;
  z-index: 2;
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing.sp16}
    ${({ theme }) => theme.spacing.sp16};
  min-width: ${(props) =>
    props.searchRealm === "header-search" ? "100%" : " 83%"};
  display: flex;
  border: solid 1px #efefef;
  align-items: baseline;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const AutoSuggestBrandsLabel = styled.div`
  margin-right: ${({ theme }) => theme.spacing.sp8};
`;

export const AutoSuggestBrand = styled.div`
  padding-right: ${({ theme }) => theme.spacing.sp8};
  color: ${({ theme }) => theme.colors.text.black};
  padding: ${pxToRem(2)} ${({ theme }) => theme.spacing.sp16};
  background-color: ${({ theme }) => theme.colors.text.cta};
  border-radius: ${({ theme }) => theme.spacing.sp16};
  margin: 0 ${({ theme }) => theme.spacing.sp8} ${pxToRem(5)} 0;
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const ResultTypeHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledHeaderSearchIcon = styled(Icon)`
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 40;
`;

export const Box = styled.div`
  background-color: #fff;
  padding: ${({ theme }) => `${theme.spacing.sp8} ${theme.spacing.sp24}`};
  margin-bottom: ${({ theme }) => theme.spacing.sp48};
`;

export const BoxFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MoreButton = styled.span`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
`;

export const MainContent = styled.div`
  margin: ${({ theme }) => `${theme.spacing.sp8} 0 ${theme.spacing.sp16}`};
  h1 {
    margin-bottom: 1rem;
  }
`;
