import axios from "axios";

import { elasticSearchUri } from "elasticSearchSettings";

import { SearchResult, SourceType } from "interfaces/elastic-search";

interface RequestDataProps {
  searchTerm: {
    inputValue?: string;
    brandName?: string;
  };
  erpSystemCode?: string;
  allAssortments?: boolean;
  languageCode?: string;
  source?: SourceType;
  limit?: number;
  query?: string;
  skip?: number;
}

export const searchData = async (requestData: RequestDataProps) => {
  const {
    searchTerm: { inputValue, brandName },
    erpSystemCode,
    allAssortments,
    languageCode,
    source,
    limit = 5,
    skip = 0,
  } = requestData;

  return axios
    .post<SearchResult>(`${elasticSearchUri}/ALL_${languageCode}`, {
      erpSystem: erpSystemCode,
      query: inputValue?.length === 0 ? "*" : inputValue,
      showAllAssortments: allAssortments,
      source,
      filter: { brand: brandName },
      limit,
      skip,
    })
    .then((res) => {
      return res.data;
    });
};
