import React from "react";
import { Icon } from "@eriksdigital/atomic-ui/components";
import { LoadingIcon } from "@eriksdigital/atomic-ui/components/Icons";
import { colors } from "@eriksdigital/atomic-ui/styles";

export const Loading = () => (
  <Icon
    data-testid="loading-icon"
    as={LoadingIcon}
    size="sz16"
    color={colors.default.blueD}
  />
);
