import { Icon } from "@eriksdigital/atomic-ui/components";
import { ClipboardIcon } from "@eriksdigital/atomic-ui/components/Icons";
import { colors } from "@eriksdigital/atomic-ui/styles";
import React from "react";
import useCopy from "../../hooks/useCopy";
import { StyleContainerProps } from "../../interfaces/container";
import { SpanContainer, TitleContainer } from "./style";
interface CopyToClipboardProps extends StyleContainerProps {
  text: string;
}
const CopyToClipboard = ({
  text,
  children,
  Container = SpanContainer,
}: CopyToClipboardProps & React.PropsWithChildren<any>) => {
  const copy = useCopy();
  return (
    <Container onClick={copy(text)}>
      <TitleContainer>{children}</TitleContainer>
      <Icon as={ClipboardIcon} size="sz16" color={colors.heading.primary} />
    </Container>
  );
};

export default CopyToClipboard;
