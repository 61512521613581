import React from "react";
import { FormattedMessage } from "react-intl";
import {
  CompareBarContainer,
  CompareBarThumbnailContainer,
  CompareBarContentContainer,
  ClearContainer,
} from "./style";
import { MainLayout, Button, Icon } from "@eriksdigital/atomic-ui/components";
import {
  SideArrowIcon,
  TrashBinIcon,
} from "@eriksdigital/atomic-ui/components/Icons";

import { useDispatch, useSelector } from "react-redux";
import { compareSelector } from "../../state/selector";
import CompareBarThumbnail from "./CompareBarThumbnail";
import { emptyItems, getPartNumbers } from "../../state/compare";
import { useHistory, useLocation } from "react-router-dom";

export const CompareBar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { items } = useSelector(compareSelector);
  const isInComparePage = location.pathname.includes("/compare/");
  if (!items || !items.length || !!isInComparePage) return <></>;
  const showCompare = () => {
    const params = new URLSearchParams(location.search);
    history.push({
      pathname: `/compare/${getPartNumbers(items).join(",")}`,
      search: "?" + params.toString(),
    });
  };
  return (
    <CompareBarContainer>
      <MainLayout>
        <CompareBarContentContainer>
          <ClearContainer>
            <Button
              onClick={() => dispatch(emptyItems())}
              id="compare-button"
              leftIcon={<Icon as={TrashBinIcon} size="sz32" />}
              rightIcon={null}
              variant="secondary"
            >
              <FormattedMessage id="compare.sticky-header.clear" />
            </Button>
          </ClearContainer>
          <div>
            <FormattedMessage
              id="compare.summary-bar.items-selected"
              values={{ quantity: items.length }}
            />
          </div>
          <CompareBarThumbnailContainer>
            {items.map((item) => (
              <CompareBarThumbnail item={item} />
            ))}
          </CompareBarThumbnailContainer>
          <div>
            <Button
              onClick={showCompare}
              id="compare-button"
              rightIcon={<Icon as={SideArrowIcon} size="sz32" />}
            >
              <FormattedMessage id="compare.summary-bar.compare-selection" />
            </Button>
          </div>
        </CompareBarContentContainer>
      </MainLayout>
    </CompareBarContainer>
  );
};

export default CompareBar;
