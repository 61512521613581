import React, { useEffect, useState } from "react";
import { CustomersContainer } from "../style";
import {
  CustomerCard,
  CustomerCardLoading,
} from "components/Modal/CustomerModal";
import { BlackH4 } from "../../DocumentsDownload/style";
import { setSelectedCustomer, TempCustomer } from "../../../state/customer";
import { useDispatch, useSelector } from "react-redux";
import { customerSelector } from "../../../state/selector";
import { useQuery } from "@apollo/react-hooks";
import { GET_SINGLE_CUSTOMER } from "./queries";
import {
  getSingleCustomer,
  getSingleCustomerVariables,
  getSingleCustomer_Customer,
} from "./schema/getSingleCustomer";
import { contextSelector } from "../../../state/selector";

interface FavouriteCustomerProps {
  customerNo: string;
}

export const FavouriteCustomer = ({ customerNo }: FavouriteCustomerProps) => {
  const [customer, setCustomer] = useState<getSingleCustomer_Customer | null>(
    null
  );
  const dispatch = useDispatch();
  const { selectedCustomer } = useSelector(customerSelector);
  const { countryCode } = useSelector(contextSelector);

  const { loading, error, data } = useQuery<
    getSingleCustomer,
    getSingleCustomerVariables
  >(GET_SINGLE_CUSTOMER, {
    variables: {
      customerNo,
      companyCountryCode: countryCode,
    },
  });

  const handleCustomerSelect = (customer: TempCustomer) => {
    dispatch(setSelectedCustomer(customer));
  };

  useEffect(() => {
    const c = data?.Customer?.[0];
    if (c) setCustomer(c);
  }, [data]);

  if (!!loading) {
    return (
      <CustomersContainer>
        {[1, 2, 3].map((i) => (
          <CustomerCardLoading key={i} />
        ))}
      </CustomersContainer>
    );
  }

  if (!loading && !!error) {
    return (
      <CustomersContainer>
        <BlackH4>{error.name}</BlackH4>
      </CustomersContainer>
    );
  }

  if (!customer) return <></>;

  return (
    <CustomerCard
      customer={customer}
      onClick={handleCustomerSelect}
      isSelected={
        !!selectedCustomer &&
        customer.customerNo === selectedCustomer.customerNo
      }
    />
  );
};
