import React from "react";
import { CustomersContainer } from "../style";

import {
  CustomerCard,
  CustomerCardLoading,
} from "components/Modal/CustomerModal";
import { ApolloError } from "apollo-client";
import { BlackH4 } from "../../DocumentsDownload/style";
import { setSelectedCustomer, TempCustomer } from "state/customer";
import { useDispatch, useSelector } from "react-redux";
import { customerSelector } from "state/selector";

interface Customers {
  loading?: boolean;
  error?: ApolloError;
  customers?: TempCustomer[];
}
const Customers = ({ loading = false, error, customers }: Customers) => {
  const dispatch = useDispatch();
  const { selectedCustomer } = useSelector(customerSelector);

  if (!!loading) {
    return (
      <CustomersContainer>
        {[1, 2, 3].map((index) => (
          <CustomerCardLoading key={index} />
        ))}
      </CustomersContainer>
    );
  }
  if (!loading && !!error) {
    return (
      <CustomersContainer>
        <BlackH4>{error.name}</BlackH4>
      </CustomersContainer>
    );
  }
  const handleCustomerSelect = (customer: TempCustomer) => {
    dispatch(setSelectedCustomer(customer));
  };
  return (
    <CustomersContainer>
      {(customers || []).map((c, index) => (
        <CustomerCard
          customer={c}
          key={index}
          onClick={handleCustomerSelect}
          isSelected={
            !!selectedCustomer && c.customerNo === selectedCustomer.customerNo
          }
        />
      ))}
    </CustomersContainer>
  );
};

export default Customers;
