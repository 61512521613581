/* istanbul ignore file */

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

import { createGlobalStyle, ThemeProvider } from "styled-components";
import { ApolloProvider } from "@apollo/react-hooks";
import { defaultTheme } from "@eriksdigital/atomic-ui/styles";
import App from "./App";
import { ConnectedIntlProvider } from "./utils/ConnectedIntlProvider";
import { NotificationProvider } from "./utils/NotificationContext";

import fontFace from "./fontFace";
import { createApolloClient } from "./createApolloClient";

import { Provider } from "react-redux";
import store from "./state/store";

const msalInstance = new PublicClientApplication(msalConfig);
export const client = createApolloClient();

// eslint-disable-next-line
if (process.env.NODE_ENV !== "production") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React);
}

const GlobalStyle = createGlobalStyle`
  ${fontFace}

*{
  box-sizing:border-box;
  margin:0;
  padding:0;
}
  body {
    font-family: geomanist;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Route path="/">
            <ThemeProvider theme={defaultTheme}>
              <ConnectedIntlProvider>
                <NotificationProvider>
                  <App />
                </NotificationProvider>
                <GlobalStyle />
              </ConnectedIntlProvider>
            </ThemeProvider>
          </Route>
        </BrowserRouter>
      </ApolloProvider>
    </Provider>
  </MsalProvider>,
  document.getElementById("root")
);
