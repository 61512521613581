import gql from "graphql-tag";

export const ITEM_ERP_ITEMS_QUERY = gql`
  query getItemRelatedERPItems(
    $partNumber: String!
    $languageCode: String!
    $orderBy: [_ERPItemOrdering]
  ) {
    Item(partNumber: $partNumber) {
      Descriptions: has_description_i(filter: { languageCode: $languageCode }) {
        source
      }
      brand
      ERPItems: has_erp_item(orderBy: $orderBy) {
        erpSystem
        shopId
        erpPartNumber
        active
        coreassort
        sellable
        stock
        priceCurrency
        openListPrice
        ean
        quantityMeasure
        quantityMultiple
        nominalQuantity
        packagingQuantity
      }
    }
  }
`;
