import { CountryType, LanguageType } from "state/context";

export const fullLanguageName: Partial<Record<LanguageType, string>> = {
  NL: "Nederlands",
  FR: "Français",
  EN: "English",
  DE: "Deutsch",
};

export type CountryLanguageCombo = {
  countryCode: CountryType;
  countryName: string;
  languages: {
    code: LanguageType;
    name?: string;
  }[];
};

export interface DropdownValue {
  label: string;
  value: string;
}

export const getAvailableCountry = (
  list: CountryLanguageCombo[] = countryLanguageCombos
): DropdownValue[] => {
  return list.map((item: CountryLanguageCombo) => ({
    label: item.countryName,
    value: item.countryCode,
  }));
};

export const getAvailableLanguage = (
  currentCountryCode: string,
  list: CountryLanguageCombo[] = countryLanguageCombos
): DropdownValue[] => {
  const currentCountry = list.find(
    ({ countryCode }) => currentCountryCode === countryCode
  );
  if (!currentCountry || !currentCountry.languages) return [];
  return currentCountry.languages.map(({ code, name = "" }) => ({
    label: name,
    value: code,
  }));
};

export const countryLanguageCombos: CountryLanguageCombo[] = [
  {
    countryCode: "BE",
    countryName: "Belgium",
    languages: [
      { code: "NL", name: fullLanguageName["NL"] },
      { code: "FR", name: fullLanguageName["FR"] },
      { code: "EN", name: fullLanguageName["EN"] },
      { code: "DE", name: fullLanguageName["DE"] },
    ],
  },
  {
    countryCode: "FR",
    countryName: "France",
    languages: [
      { code: "FR", name: fullLanguageName["FR"] },
      { code: "EN", name: fullLanguageName["EN"] },
    ],
  },
  {
    countryCode: "DE",
    countryName: "Germany",
    languages: [
      { code: "DE", name: fullLanguageName["DE"] },
      { code: "EN", name: fullLanguageName["EN"] },
    ],
  },
  {
    countryCode: "LU",
    countryName: "Luxembourg",
    languages: [
      { code: "FR", name: fullLanguageName["FR"] },
      { code: "DE", name: fullLanguageName["DE"] },
      { code: "EN", name: fullLanguageName["EN"] },
    ],
  },
  {
    countryCode: "NL",
    countryName: "The Netherlands",
    languages: [
      { code: "NL", name: fullLanguageName["NL"] },
      { code: "EN", name: fullLanguageName["EN"] },
      { code: "DE", name: fullLanguageName["DE"] },
    ],
  },
  {
    countryCode: "CH",
    countryName: "Switzerland",
    languages: [
      { code: "FR", name: fullLanguageName["FR"] },
      { code: "DE", name: fullLanguageName["DE"] },
      { code: "EN", name: fullLanguageName["EN"] },
    ],
  },
  {
    countryCode: "UK",
    countryName: "United Kingdom",
    languages: [{ code: "EN", name: fullLanguageName["EN"] }],
  },
  {
    countryCode: "HU",
    countryName: "Hungary",
    languages: [{ code: "EN", name: fullLanguageName["EN"] }],
  },
  {
    countryCode: "CZ",
    countryName: "Czech Republic",
    languages: [{ code: "EN", name: fullLanguageName["EN"] }],
  },
  {
    countryCode: "APAC",
    countryName: "APAC",
    languages: [{ code: "EN", name: fullLanguageName["EN"] }],
  },
  {
    countryCode: "US",
    countryName: "United States of America",
    languages: [{ code: "EN", name: fullLanguageName["EN"] }],
  },
  {
    countryCode: "PL",
    countryName: "Poland",
    languages: [{ code: "EN", name: fullLanguageName["EN"] }],
  },
  {
    countryCode: "ES",
    countryName: "Spain",
    languages: [{ code: "EN", name: fullLanguageName["EN"] }],
  },
  {
    countryCode: "SK",
    countryName: "Slovakia",
    languages: [{ code: "EN", name: fullLanguageName["EN"] }],
  },
];
