import React, { MouseEvent } from "react";
import { Button } from "@eriksdigital/atomic-ui/components";
import { colors } from "@eriksdigital/atomic-ui/styles";
import { createImageUrl } from "./URLBuilder";
import { Media } from "./types";

export const DEFAULT_IMAGE = "https://via.placeholder.com/150";
export const DEFAULT_NO_IMAGE =
  "https://eriksdigitalcdn.azureedge.net/shop/detail/hlr-system/webshop/no_image_xl.gif";
export const DEFAULT_SOURCE = "PIM";

export const mediaPicker = ({
  atchastPath,
  attachusg_id,
  tracepartsPartId,
  tracepartsClassificationId,
  source,
  ...rest
}: Media) => {
  const hasSource = source === null ? "" : source;
  switch (attachusg_id) {
    case "Image":
      return (
        <img
          src={
            source === null
              ? DEFAULT_NO_IMAGE
              : createImageUrl(atchastPath, "detail", hasSource)
          }
          alt=""
          {...rest}
        />
      );
    case "TraceParts":
      return (
        <iframe
          title="3D"
          {...rest}
          src={`https://www.traceparts.com/els/eriks-shop/en/api/viewer/3d?SupplierID=${tracepartsClassificationId}&PartNumber=${tracepartsPartId}`}
          width="370px"
          height="370px"
        />
      );
    default:
      return null;
  }
};

export const mediaPickerThumb = (
  {
    atchastPath,
    attachusg_id,
    tracepartsPartId,
    tracepartsClassificationId,
    source,
    ...rest
  }: {
    atchastPath: string;
    attachusg_id: string;
    tracepartsPartId: string;
    tracepartsClassificationId: string;
    source: string;
  },
  i: number,
  currentlySelectedMedia: boolean,
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
) => {
  const styles = {
    border: currentlySelectedMedia
      ? `2px solid ${colors.default.blueD}`
      : undefined,
  };

  switch (attachusg_id) {
    case "Image":
      return (
        <>
          {source !== null ? (
            <Button
              id={`button-${atchastPath}`}
              key={`button-${atchastPath}`}
              variant="ghost"
              name={i.toString()}
              onClick={onClick}
            >
              <img
                style={styles}
                src={createImageUrl(atchastPath, "thumb40", source)}
                alt=""
              />
            </Button>
          ) : null}
        </>
      );
    case "TraceParts":
      return (
        <Button
          id="button-traceparts"
          key="button-traceparts"
          variant="ghost"
          name={i.toString()}
          onClick={onClick}
        >
          <img style={styles} alt="3D" />
        </Button>
      );
    default:
      return null;
  }
};
