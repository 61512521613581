import styled from "styled-components";
import { Placeholder } from "@eriksdigital/atomic-ui/components";
import { spacing } from "@eriksdigital/atomic-ui/styles";

export const LoadingGroupAncestorsWrap = styled.div`
  .loader {
    margin-bottom: ${({ theme }) => theme.spacing.sp8};
  }
`;

export const LoadingGroupAncestorsPlaceholder = styled(Placeholder)<{
  props: {
    item: number;
  };
}>`
  margin-bottom: ${({ theme }) => theme.spacing.sp8};
  ${(props) => props.item === 3 && `padding-left: ${spacing.sp16};`}
  ${(props) => props.item === 4 && `padding-left: ${spacing.sp32};`}
`;
