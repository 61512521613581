import styled from "styled-components";
import { H4, Link } from "@eriksdigital/atomic-ui/components";

export const BlackH4 = styled(H4)`
  color: ${({ theme }) => theme.colors.heading.secondary};
  margin: ${({ theme }) => theme.spacing.sp8} 0;
`;

export const TableDocuments = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.sp8};
`;

export const DocumentGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 30rem;
  padding: ${({ theme }) => theme.spacing.sp8} 0;
`;

export const DocumentLabel = styled.div`
  color: ${({ theme }) => theme.colors.default.black};
  flex: 3;
`;

export const DocumentDownloadContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.sp8} 0
    ${({ theme }) => theme.spacing.sp16};
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;

  svg {
    margin-right: ${({ theme }) => theme.spacing.sp8};
  }
`;
