import gql from "graphql-tag";
import { FullItemFields } from "../../fragments/item";

export const SINGLE_ITEM_QUERY = gql`
  ${FullItemFields}
  query getSingleItem(
    $partNumber: String!
    $languageCode: String!
    $countryCode: String!
    $filter: _ItemFilter
  ) {
    Item(partNumber: $partNumber, filter: $filter) {
      ...FullItemFields
    }
  }
`;
