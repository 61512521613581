const cdnUrl = "https://eriksdigitalcdn.azureedge.net/";
const webShopUrl = "https://eriksdigitalcdn.azureedge.net/";
const HASHTAG = "#";

type ImageSizes =
  | "detail"
  | "thumb"
  | "thumb40"
  | "thumb60"
  | "thumb80"
  | "direct"
  | "category";

const imageSizes = {
  detail: `${cdnUrl}shop/detail/`,
  thumb: `${cdnUrl}shop/thumb/`,
  thumb40: `${cdnUrl}shop/thumb40/`,
  thumb60: `${cdnUrl}shop/thumb60/`,
  thumb80: `${cdnUrl}shop/thumb80/`,
  direct: `${cdnUrl}shop/direct/`,
  category: `${cdnUrl}shop/category/`,
};

const imageSizesPip = {
  detail: `${cdnUrl}pip/productimages/detail/`,
  thumb: `${cdnUrl}pip/productimages/thumb/`,
  thumb40: `${cdnUrl}pip/productimages/thumb40/`,
  thumb60: `${cdnUrl}pip/productimages/thumb60/`,
  thumb80: `${cdnUrl}pip/productimages/thumb80/`,
  direct: `${cdnUrl}pip/productimages/direct/`,
  category: `${cdnUrl}pip/productimages/category/`,
};

export const createImageUrl = (
  path: string,
  size: ImageSizes,
  source: string = ""
) => {
  if (path == null) {
    return HASHTAG;
  }
  if (path.startsWith("http")) {
    return path;
  }
  if (source === "PIP") {
    return `${imageSizesPip[size]}${path}`;
  }
  return `${imageSizes[size]}${path}`;
};

export const createPdfUrl = (path?: string) => {
  if (path == null) {
    return HASHTAG;
  }
  if (path.startsWith("http") || path.startsWith("file")) {
    return path;
  }
  return `${webShopUrl}${path}`;
};

type DocumentTypes = "Pdf" | "Image";

export const createDocumentUrl = (
  documentType: keyof DocumentTypes,
  path: string = HASHTAG,
  size: ImageSizes
) => {
  switch (documentType) {
    case "Pdf":
      return createPdfUrl(path);
    case "Image":
      return createImageUrl(path, size);
    default:
      return path;
  }
};
