import styled from "styled-components";
import { Placeholder } from "@eriksdigital/atomic-ui/components";

export const LoadingSearchResultsWrap = styled.div`
  padding: 16px;
`;

export const LoadingSearchResultsTitle = styled(Placeholder)`
  height: 16px;
  width: 80px;
  margin-bottom: 10px;
`;

export const LoadingSearchResultsItems = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const LoadingSearchResultsItemDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LoadingSearchResultsProductsWrapper = styled.div`
  display: flex;
`;

export const LoadingSearchResultsProducts = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
`;
