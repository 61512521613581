import React from "react";
import { Placeholder } from "@eriksdigital/atomic-ui/components";

import {
  LoadingSearchResultsWrap,
  LoadingSearchResultsItems,
  LoadingSearchResultsTitle,
  LoadingSearchResultsItemDetails,
  LoadingSearchResultsProductsWrapper,
  LoadingSearchResultsProducts,
} from "./style";

export const LoadingSearchResults = () => {
  return (
    <LoadingSearchResultsWrap>
      <LoadingSearchResultsTitle type="custom" />
      {[1, 2, 3, 4, 5].map((e) => (
        <LoadingSearchResultsItems>
          <Placeholder key={e} height="50px" width="50px" type="custom" />
          <LoadingSearchResultsItemDetails>
            <Placeholder
              type="custom"
              height="15px"
              width="300px"
              style={{ margin: "5px 0 5px 10px" }}
            />
            <Placeholder
              type="custom"
              height="15px"
              width="350px"
              style={{ margin: "5px 0 5px 10px" }}
            />
          </LoadingSearchResultsItemDetails>
        </LoadingSearchResultsItems>
      ))}
      <LoadingSearchResultsProductsWrapper>
        <LoadingSearchResultsProducts>
          {[1, 2, 3, 4, 5].map((e) => (
            <Placeholder
              type="custom"
              height="15px"
              width="360px"
              style={{ margin: "5px 10px 8px 0" }}
            />
          ))}
        </LoadingSearchResultsProducts>
        <LoadingSearchResultsProducts>
          {[1, 2, 3, 4, 5].map((e) => (
            <Placeholder
              type="custom"
              height="15px"
              width="360px"
              style={{ margin: "5px 0 8px 10px" }}
            />
          ))}
        </LoadingSearchResultsProducts>
      </LoadingSearchResultsProductsWrapper>
    </LoadingSearchResultsWrap>
  );
};
