import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getObjectFromString } from "../utils/local-storage";

export interface WishlistItem {
  partNumber: string;
  quantity: number;
}
interface WishlistState {
  isOpen: boolean;
  wishlist: WishlistItem[];
}

const currentWishlist = localStorage.getItem("wishlist");
let initialState: WishlistState = {
  isOpen: false,
  wishlist:
    currentWishlist && getObjectFromString(currentWishlist)
      ? getObjectFromString(currentWishlist)
      : [],
};

const WishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    setIsOpen(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
    addToWishlist(state, action: PayloadAction<WishlistItem>) {
      const existingWishlist = state.wishlist.find(
        ({ partNumber }) => partNumber === action.payload.partNumber
      );
      const addedQuantity = state.wishlist.map((item: WishlistItem) =>
        item.partNumber === action.payload.partNumber
          ? {
              ...item,
              quantity: item.quantity + action.payload.quantity,
            }
          : item
      );
      state.wishlist = !!existingWishlist
        ? addedQuantity
        : [...state.wishlist, action.payload];
    },
    removeFromWishlist(state, action: PayloadAction<string>) {
      state.wishlist = state.wishlist.filter(
        ({ partNumber }) => partNumber !== action.payload
      );
    },
    changeQuantityWishlist(state, action: PayloadAction<WishlistItem>) {
      state.wishlist = state.wishlist.map((item: WishlistItem) =>
        item.partNumber === action.payload.partNumber
          ? {
              ...item,
              quantity: action.payload.quantity,
            }
          : item
      );
    },
    emptyWishlist(state, action: PayloadAction<void>) {
      state.wishlist = [];
    },
    setMultipleValue(state, action: PayloadAction<Partial<WishlistState>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const getPartNumbers = (items: WishlistItem[]) =>
  items.map(({ partNumber }: WishlistItem) => partNumber);

export const {
  setIsOpen,
  addToWishlist,
  removeFromWishlist,
  changeQuantityWishlist,
  emptyWishlist,
  setMultipleValue,
} = WishlistSlice.actions;
export default WishlistSlice.reducer;
