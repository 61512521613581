import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useMsal } from "@azure/msal-react";
import { Button, Avatar, Image } from "@eriksdigital/atomic-ui/components";
import { MyAccountIcon } from "@eriksdigital/atomic-ui/components/Icons";

import { userSelector } from "state/selector";

import { Loading } from "components/Loader";

import { UserDetalsWrap, StyledPopupHost, StyledMyAccountIcon } from "./styles";

export const HeaderUserDetails = () => {
  const intl = useIntl();
  const { instance } = useMsal();
  const { user } = useSelector(userSelector);

  // MSAL logout
  const handleLogout = () => {
    instance
      .logoutRedirect()
      .then(() => localStorage.removeItem("idToken"))
      .catch((err: { message: string }) => {
        throw new Error(err.message);
      });
  };

  if (user === null) {
    return <Loading />;
  }

  return (
    user && (
      <UserDetalsWrap id="UserPopupHostBottomRight">
        <Avatar
          name={`${intl.formatMessage({
            id: "header.user-details.hello",
          })}, ${user.displayName}`}
          showName
          size={{ phone: 20, tabletPortrait: 20 }}
        >
          {user.photo ? (
            <Image
              alt=""
              position={null}
              ratio={null}
              smartImageProps={{}}
              sources={[]}
              src={user.photo}
              video={{}}
            />
          ) : (
            <StyledMyAccountIcon as={MyAccountIcon} />
          )}
        </Avatar>
        <StyledPopupHost
          animation="fadeIn"
          border="secondary"
          fallbackRenderSide="top"
          parent="UserPopupHostBottomRight"
          side="bottomRight"
        >
          <Button
            id="sign-out-button"
            variant="ghost"
            onClick={() => handleLogout()}
          >
            <FormattedMessage id={"header.user-details.sign-out"} />
          </Button>
        </StyledPopupHost>
      </UserDetalsWrap>
    )
  );
};
