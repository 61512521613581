import { ApolloError } from "apollo-client";
import React from "react";

export const Error = ({ error }: { error: ApolloError | undefined }) => {
  return (
    <div data-testid="error-message">
      {JSON.stringify(error?.graphQLErrors.map((err) => err.message))}
    </div>
  );
};
