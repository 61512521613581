import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import {
  MainLayout,
  GridColumn,
  GridRow,
  H1,
} from "@eriksdigital/atomic-ui/components";

import { colors, sizes } from "@eriksdigital/atomic-ui/styles";

import { contextSelector } from "state/selector";
import { FullWidthBackground, StyledLink } from "utils/style";
import CountryLanguageSelector from "components/CountryLanguageSelector";
import { News } from "components/News";
import Search from "components/Search";

import HeaderIcons from "../HeaderIcons";
import { ReactComponent as Logo } from "assets/logo.svg";

import {
  HeaderUpper,
  HeaderUpperElement,
  HeaderCenter,
  HeaderLeft,
  SearchContainer,
  HeaderWrapper,
  PageWrapper,
  HeaderRightIcons,
  GlobalHome,
  SearchBarWrapper,
} from "./style";
import { HeaderSearch, HeaderHeadwayApp, HeaderUserDetails } from "..";
import LastUpdated from "components/LastUpdated";

import { setSearchQuery } from "state/context";

import { useDispatch, useSelector } from "react-redux";
import { getCoreParams, getModifiedParams } from "utils/param";

const HeaderMain = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  const location = useLocation();
  const dispatch = useDispatch();
  const [scrollTop, setScrollTop] = useState(0);
  const intl = useIntl();
  const [inputValue, setInputValue] = useState<string>("");
  const { languageCode, brandName, searchQuery } = useSelector(contextSelector);

  // This will be used after upgrade of turbo mode
  const coreParams = getModifiedParams(
    searchParams.toString(),
    getCoreParams(searchParams.toString())
  );

  const headerRef = useRef<HTMLDivElement>(null);
  const headerHeight =
    headerRef && headerRef.current ? headerRef.current.offsetHeight : 150;

  const handleInputValue = (value: string) => {
    dispatch(setSearchQuery(value));
    setInputValue(value);
  };

  const onScroll = (e: any) => {
    setScrollTop(e.target.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  useEffect(() => {
    if (searchQuery) {
      setInputValue(searchQuery);
    }
  }, [searchQuery]);

  return (
    <>
      <PageWrapper id="header" data-testid="header" ref={headerRef}>
        <FullWidthBackground
          height={sizes.sz40}
          backgroundColor={colors.default.lightGray}
        >
          <MainLayout>
            <HeaderUpper>
              <HeaderUpperElement>
                <CountryLanguageSelector />
                <StyledLink
                  as="a"
                  href={`https://eriksr2.sharepoint.com/sites/ProjectWiki/SitePages/FAQ/${languageCode}.aspx`}
                  target="_blank"
                >
                  <FormattedMessage id="header.faqs" />
                </StyledLink>
              </HeaderUpperElement>
              <HeaderUpperElement>
                <HeaderHeadwayApp />
                <HeaderUserDetails />
              </HeaderUpperElement>
            </HeaderUpper>
          </MainLayout>
        </FullWidthBackground>
        <FullWidthBackground
          height={sizes.sz104}
          backgroundColor={colors.default.white}
        >
          <MainLayout>
            <HeaderWrapper>
              <HeaderLeft>
                <Link to={{ pathname: "/", search: coreParams.toString() }}>
                  <Logo />
                </Link>
              </HeaderLeft>
              <HeaderCenter>
                {location.pathname !== "/" && <HeaderSearch />}
              </HeaderCenter>
              <HeaderRightIcons>
                <HeaderIcons />
              </HeaderRightIcons>
            </HeaderWrapper>
          </MainLayout>
        </FullWidthBackground>
      </PageWrapper>
      {location.pathname === "/" && (
        <MainLayout>
          <GridRow>
            <GridColumn desktop={12}>
              <GlobalHome />
              <News location="elasticHomeMaintenance" />
              <SearchContainer>
                <H1>
                  <FormattedMessage id="home.welcome" />
                </H1>
                <LastUpdated />
              </SearchContainer>

              {!searchQuery.length && !brandName.length ? (
                <News location="elasticHomeYammer" />
              ) : null}

              <SearchBarWrapper
                isSearchStick={scrollTop < headerHeight ? true : false}
              >
                <Search
                  searchRealm="home-search"
                  isSearchStick={scrollTop < headerHeight ? false : true}
                  placeholder={intl.formatMessage({
                    id: "search.placeholder",
                  })}
                  handleInputValue={handleInputValue}
                  inputValue={inputValue}
                />
              </SearchBarWrapper>
            </GridColumn>
          </GridRow>
        </MainLayout>
      )}
    </>
  );
};

export default HeaderMain;
