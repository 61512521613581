/**
 * We needed to move from Context API to Redux because our state gets more complex
 * Redux is used for standardization with other toolkit in Eriks shop
 * In case you want to change it, please discuss it with the other Eriks teams first
 */

import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./rootReducer";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;

export default store;
