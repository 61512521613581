import React, { memo, useEffect, useState } from "react";

import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { contextSelector } from "state/selector";
import {
  setIsSearchModalActive,
  setSearchQuery,
  setSearchResult,
} from "state/context";

import { HeaderSearchResults } from "components/Header";
import Search from "components/Search";

import { StyledHeaderSearchInputWrapper, HeaderSearchOverlay } from "./styles";

const HeaderSearch = () => {
  const intl = useIntl();
  const source = "ALL";
  const { searchResult, callLoading, isSearchModalActive } = useSelector(
    contextSelector
  );
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState<string>("");
  const [innerBrand, setInnerBrand] = useState("");

  useEffect(() => {
    dispatch(setSearchResult(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputValue = (value: string) => {
    setInputValue(value);
    dispatch(setSearchQuery(value));
  };

  const handleInnerBrand = (value: string) => {
    setInnerBrand(value);
  };

  const handleShowModal = (value: boolean) => {
    dispatch(setIsSearchModalActive(value));

    if (!value) {
      dispatch(setSearchResult(null));
    }
  };

  return (
    <div>
      {isSearchModalActive && (
        <>
          <HeaderSearchOverlay onClick={() => handleShowModal(false)} />
          <HeaderSearchResults
            searchResult={searchResult}
            handleShowModal={handleShowModal}
            handleInputValue={handleInputValue}
            innerBrand={innerBrand}
            handleInnerBrand={handleInnerBrand}
            callLoading={callLoading}
          />
        </>
      )}

      <StyledHeaderSearchInputWrapper>
        <Search
          searchRealm="header-search"
          data-testid="search-form-header"
          source={source}
          placeholder={intl.formatMessage({ id: "search.placeholder" })}
          isSearchModalActive={isSearchModalActive}
          handleShowModal={handleShowModal}
          handleInputValue={handleInputValue}
          innerBrand={innerBrand}
          handleInnerBrand={handleInnerBrand}
          inputValue={inputValue}
          data-hj-whitelist
        />
      </StyledHeaderSearchInputWrapper>
    </div>
  );
};

export default memo(HeaderSearch);
