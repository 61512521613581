import styled from "styled-components";
import { H5, Icon } from "@eriksdigital/atomic-ui/components";

const defaultPXSize = 16;
export const pxToRem = (px: number) => `${px / defaultPXSize}rem`;

export const StyledSearchResultsModal = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
`;

export const StyledSearchResultsWrapper = styled.div`
  width: 780px;
  height: fit-content;
  margin-top: 30px;
  z-index: 20;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
`;

export const StyledSearchResultTitle = styled.div`
  display: flex;
  margin: 10px 16px 65px;
  justify-content: space-between;
`;

export const StyledSearchResultsHeading = styled(H5)`
  color: black;
`;

export const StyledSearchResultProductsWrapper = styled.div`
  display: flex;
`;

export const StyledSearchResultProducts = styled.div`
  flex: 1 1 40%;
  margin: 0 16px;
`;

export const StyledSearchResultProduct = styled.div`
  margin-bottom: 10px;
`;

export const StyledSearchResultTypeHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const StyledSearchResults = styled.div`
  display: flex;
  flex-wrap: wrap;

  div:first-child {
    flex: 1 1 100%;
  }
`;

export const StyledSearchResultProductsInnerWrap = styled.div`
  background-color: #f8f8f8;
  padding: 20px;
  min-height: 248px;
  margin-bottom: 16px;
  text-align: left;
`;

export const StyledSearchResultItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 ${pxToRem(10)} 0;
`;

export const StyledSearchResultItemImage = styled.div`
  max-width: ${({ theme }) => theme.sizes.sz48};
  margin-right: ${({ theme }) => theme.spacing.sp12};
`;

export const StyledStarchResultsViewAll = styled.div`
  background: #005ca9;
  border-radius: 0px 0px 10px 10px;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  text-align: center;
  font-weight: 600;
  padding: 10px 0;
  color: #fff;
`;

export const StyledStarchResultsAutoSuggestBrandsWrapper = styled.div`
  display: flex;
  align-items: baseline;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 ${({ theme }) => theme.spacing.sp16};
  padding-bottom: ${({ theme }) => theme.spacing.sp8};
`;

export const StyledStarchResultsAutoSuggestBrand = styled.div`
  padding-right: ${({ theme }) => theme.spacing.sp8};
  color: ${({ theme }) => theme.colors.text.black};
  padding: ${pxToRem(2)} ${({ theme }) => theme.spacing.sp16};
  background-color: ${({ theme }) => theme.colors.text.cta};
  border-radius: ${({ theme }) => theme.spacing.sp16};
  margin: 0 ${pxToRem(5)} ${pxToRem(5)};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const StyledCrossIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.heading.dim};
  height: ${({ theme }) => theme.sizes.sz20};
  width: ${({ theme }) => theme.sizes.sz20};
  cursor: pointer;
`;

export const StyledMoreButton = styled.span`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledNoSearchResults = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  min-height: 300px;
  justify-content: center;
`;
