import React from "react";
import { Placeholder } from "@eriksdigital/atomic-ui/components";

import { LoadingItemsWrapp } from "./style";

export const LoadingItems = (props: { items: number }) => {
  const { items } = props;

  const squares = Array.from(Array(items).keys());

  return (
    <LoadingItemsWrapp>
      {[...squares].map((e) => (
        <Placeholder
          data-testid="loading-placeholder"
          key={e}
          height="324px"
          width="100%"
          type="custom"
        />
      ))}
    </LoadingItemsWrapp>
  );
};
