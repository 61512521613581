import React from "react";
import { FavouriteCustomersContainer } from "../style";
import { useSelector } from "react-redux";
import { customerSelector } from "../../../state/selector";
import { FavouriteCustomer } from "components/Modal/CustomerModal";
import { H4 } from "@eriksdigital/atomic-ui/components";

export const FavouriteCustomers = () => {
  const { favouriteCustomers } = useSelector(customerSelector);
  if (!favouriteCustomers.length) return <></>;
  return (
    <FavouriteCustomersContainer>
      <H4>Favourite Customers</H4>
      {(favouriteCustomers || []).map((customerNo) => (
        <FavouriteCustomer customerNo={customerNo} key={customerNo} />
      ))}
    </FavouriteCustomersContainer>
  );
};
