import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@eriksdigital/atomic-ui/components";

import { setAllAssortments } from "state/context";
import { contextSelector } from "state/selector";

import { AssortmentContainer, SwitchLabel } from "./style";

const AssortmentFilter = () => {
  const dispatch = useDispatch();
  const { allAssortments } = useSelector(contextSelector);
  const handleToggleMode = () => dispatch(setAllAssortments(!allAssortments));

  return (
    <AssortmentContainer>
      <Switch
        checked={allAssortments}
        id="assortmentfilter"
        name="assortmentfilter"
        onChange={handleToggleMode}
        small
      />
      <SwitchLabel htmlFor="assortmentfilter">
        <FormattedMessage id="header.allAssortments" />
      </SwitchLabel>
    </AssortmentContainer>
  );
};

export default AssortmentFilter;
