import { graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  const userMe = async () => {
    try {
      const response = await fetch(graphConfig.graphMeEndpoint, options);
      return await response.json();
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const userPhoto = async () => {
    try {
      const response = await fetch(graphConfig.graphPhotoEndpoint, options);
      const blob = await response.blob();

      const convertBlobToBase64 = async (photoBlob) => {
        return blobToBase64(photoBlob);
      };

      const blobToBase64 = (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

      if (blob.type === "image/jpeg" || blob.type === '"image/png"') {
        return await convertBlobToBase64(blob);
      }

      return null;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const user = await userMe();
  const photo = await userPhoto();

  return { ...user, photo };
}
