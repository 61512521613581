import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { filter, orderBy } from "lodash";
import { Link, Icon } from "@eriksdigital/atomic-ui/components";
import { WarningIcon } from "@eriksdigital/atomic-ui/components/Icons";
import { colors } from "@eriksdigital/atomic-ui/styles";

import { notificationSelector, contextSelector } from "state/selector";

import {
  NewsContainer,
  IconContainer,
  LinkContainer,
  HeaderBannerWrap,
  HeaderBannerPill,
  HeaderBannerMessage,
} from "./style";

interface News {
  id: string;
  created_at: Date;
  message: string;
  title?: string;
  link?: string;
  type?: string;
}

interface NewsLocation {
  location: string;
}

export const News = (location: NewsLocation) => {
  const { messages } = useSelector(notificationSelector);
  const { languageCode } = useSelector(contextSelector);
  const intl = useIntl();
  const newestNews = orderBy(messages, "created_at", "desc");

  const yammerNews = filter(newestNews, { type: "yammer" });
  const maintenanceNews = filter(newestNews, { type: "maintenance" });
  const whatsNewLabel = intl.formatMessage({ id: "header.whats-new" });
  const clickLabel = intl.formatMessage({ id: "header.whats-new.click" });
  const clickHereLabel = intl.formatMessage({
    id: "header.whats-new.click-here",
  });
  const readMoreLabel = intl.formatMessage({
    id: "header.whats-new.read-more",
  });
  const [yammerMessage, setYammerMessage] = useState<string>("");

  useEffect(() => {
    if (yammerNews.length) {
      const message = JSON.parse(yammerNews[0].message);
      setYammerMessage(message[languageCode]);
    }
  }, [yammerNews, languageCode]);

  return (
    <>
      {location.location === "elasticHomeYammer" && (
        <div>
          {yammerNews.map((news: News) => (
            <HeaderBannerWrap key={news.id}>
              <HeaderBannerPill>{`${whatsNewLabel} `}</HeaderBannerPill>
              <HeaderBannerMessage>
                {yammerMessage}
                <span>
                  {` ${clickLabel} `}
                  <Link
                    alt="yammer link"
                    href={news.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {clickHereLabel}
                  </Link>
                  {` ${readMoreLabel}`}
                </span>
              </HeaderBannerMessage>
            </HeaderBannerWrap>
          ))}
        </div>
      )}

      {location.location === "elasticHomeMaintenance" &&
        maintenanceNews.map((news: News) => (
          <NewsContainer key={news.id}>
            <IconContainer>
              <Icon
                as={WarningIcon}
                fill={colors.default.yellow}
                viewBox="0 0 35 35"
              />
            </IconContainer>
            <div>
              {news.title && <strong>{news.title} </strong>}
              {news.message}
            </div>
            {news.link && (
              <LinkContainer
                href={news.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Read More
              </LinkContainer>
            )}
          </NewsContainer>
        ))}
    </>
  );
};
