import React, { useState } from "react";
import HeadwayWidget from "@headwayapp/react-widget";
import { useIntl } from "react-intl";

import { Icon } from "@eriksdigital/atomic-ui/components";
import { NotificationIcon } from "../HeaderIcons/NotificationIcon";

export const HeaderHeadwayApp = () => {
  const intl = useIntl();
  const [showNotification, setShowNotification] = useState(false);

  const handleShowNotification = () => {
    setShowNotification(true);
  };

  return (
    <HeadwayWidget
      id="wiki-widget"
      account="7wwYz7"
      translations={{
        title: `${intl.formatMessage({
          id: "headwayapp.title",
        })}`,
        readMore: `${intl.formatMessage({
          id: "headwayapp.readMore",
        })}`,
        labels: {
          new: `${intl.formatMessage({ id: "headwayapp.new" })}`,
          improvement: `${intl.formatMessage({
            id: "headwayapp.improvement",
          })}`,
          fix: `${intl.formatMessage({ id: "headwayapp.fix" })}`,
        },
        footer: `${intl.formatMessage({
          id: "headwayapp.readMore",
        })}`,
      }}
      widgetPosition="center-left"
      onWidgetReady={() => {
        handleShowNotification();
      }}
    >
      {showNotification && <Icon as={NotificationIcon} />}
    </HeadwayWidget>
  );
};
