import styled from "styled-components";

export const AssortmentContainer = styled.div`
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const SwitchLabel = styled.label`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing.sp8};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft16};
  cursor: pointer;
`;
