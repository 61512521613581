import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getObjectFromString } from "../utils/local-storage";
import { CountryType, defaultCountryCode, countryCodeValue } from "./context";
// TODO: Change when API ready
export interface TempCustomer {
  customerCountryCode: string;
  companyCountryCode: string;
  customerCity: string;
  customerName2: string;
  customerNo: string;
  customerName: string;
}
interface CustomerState {
  isOpen: boolean;
  searchCountry: CountryType;
  searchCompany: string;
  isSearchLoading: boolean;
  searchCustomers: TempCustomer[];
  selectedCustomer: TempCustomer | null;
  favouriteCustomers: string[];
}

const currentCustomer = localStorage.getItem("selectedCustomer");
const favouriteCustomers = localStorage.getItem("favouriteCustomers");
const initialState: CustomerState = {
  isOpen: false,
  searchCountry: !!countryCodeValue
    ? (countryCodeValue as CountryType)
    : defaultCountryCode,
  searchCompany: "",
  isSearchLoading: false,
  searchCustomers: [],
  selectedCustomer:
    currentCustomer && getObjectFromString(currentCustomer)
      ? getObjectFromString(currentCustomer)
      : null,
  favouriteCustomers:
    favouriteCustomers && getObjectFromString(favouriteCustomers)
      ? getObjectFromString(favouriteCustomers)
      : [],
};

const CustomerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setIsOpen(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
    setSearchCountry(state, action: PayloadAction<CountryType>) {
      state.searchCountry = action.payload;
    },
    setSearchCompany(state, action: PayloadAction<string>) {
      state.searchCompany = action.payload;
    },
    setIsSearchLoading(state, action: PayloadAction<boolean>) {
      state.isSearchLoading = action.payload;
    },
    setSelectedCustomer(state, action: PayloadAction<TempCustomer>) {
      return {
        ...state,
        selectedCustomer: action.payload,
        searchCompany: "",
        searchCustomerNumber: "",
        isOpen: false,
      };
    },
    removeSelectedCustomer(state) {
      return {
        ...state,
        selectedCustomer: null,
        searchCompany: "",
        searchCustomerNumber: "",
      };
    },
    addFavouriteCustomer(state, action: PayloadAction<string>) {
      state.favouriteCustomers = [...state.favouriteCustomers, action.payload];
    },
    removeFavouriteCustomer(state, action: PayloadAction<string>) {
      state.favouriteCustomers = state.favouriteCustomers.filter(
        (p) => p !== action.payload
      );
    },
    emptyFavouriteCustomers(state) {
      state.favouriteCustomers = [];
    },
    setMultipleValue(state, action: PayloadAction<Partial<CustomerState>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const {
  setIsOpen,
  setSearchCountry,
  setSearchCompany,
  setSelectedCustomer,
  setIsSearchLoading,
  removeSelectedCustomer,
  addFavouriteCustomer,
  removeFavouriteCustomer,
  emptyFavouriteCustomers,
  setMultipleValue,
} = CustomerSlice.actions;
export default CustomerSlice.reducer;
