import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export interface NotificationItem {
  id: string;
  created_at: Date;
  message: string;
  title?: string;
  link?: string;
  type?: string;
}

interface NotificationState {
  loading: string;
  messages: any;
}

let initialState: NotificationState = {
  loading: "idle",
  messages: [],
};

export const getNews = createAsyncThunk(
  "notification/getNews",
  async () =>
    await axios.get(`https://api.events.eriksdigital.com/projectwiki_messages`)
);

const NotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: {
    [getNews.fulfilled as any]: (state, action) => {
      state.messages = action?.payload?.data ? action.payload.data : [];
    },
    [getNews.rejected as any]: (state, action) => {
      state.messages = [];
    },
  },
});

export default NotificationSlice.reducer;
