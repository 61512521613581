import { Image } from "@eriksdigital/atomic-ui/components";
import { colors, sizes } from "@eriksdigital/atomic-ui/styles";
import styled from "styled-components";
import { BoldSpan, StyledLink } from "../../utils/style";
import { CommonAssortmentIndicator } from "../ItemCard/style";

export const ItemCard = styled.div`
  width: ${sizes.sp104};
  min-height: 250px;
  background-color: #fff;
  padding: 1%;
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing.sp24};
  display: flex;
  flex-direction: column;
  border: solid 1px #efefef;
  &:hover {
    border: solid 1px rgba(0, 92, 169, 0.7);
  }
`;
export const ItemCardPip = styled(ItemCard)`
  color: ${colors.default.midGray};
  ${StyledLink}, ${BoldSpan} {
    color: ${colors.default.midGray};
  }
`;
export const CommonAssortmentIndicatorContainer = styled(
  CommonAssortmentIndicator
)`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  margin-top: ${({ theme }) => theme.spacing.sp8};
`;
export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ItemCardImage = styled(Image)`
  align-self: center;
  max-width: 150px;
`;

export const CheckboxContainer = styled.div<{ disabled: boolean }>`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  display: flex;
  align-items: center;
  margin-top: auto;
  padding-top: ${({ theme }) => theme.spacing.sp8};
  color: ${({ disabled }) =>
    disabled ? colors.default.gray : colors.default.darkGray};
`;

ItemCard.displayName = "ItemCard";
ItemCardImage.displayName = "ItemCardImage";
