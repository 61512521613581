import styled from "styled-components";

export const CustomerDetailPowerBiWrapper = styled.div<{
  expandReport: boolean;
}>`
  overflow: ${(props) => (props.expandReport ? "auto" : "none")};
  width: 100%;
`;

export const CustomerDetailPowerBIIframe = styled.iframe<{
  expandReport: boolean;
}>`
  width: ${(props) => (props.expandReport ? "1350px" : "100%")};
  height: 600px;
  border: 1px solid black;
`;

export const ReportShowMore = styled.div`
  display: flex;
  padding: 0.8582em ${({ theme }) => theme.spacing.sp0};
  justify-content: flex-end;
  position: relative;
  margintop: 30px;
  marginbottom: 30px;

  &::before {
    content: "";
    border-top: solid 1px ${({ theme }) => theme.colors.border.midGray};
    left: ${({ theme }) => theme.spacing.sp0};
    position: absolute;
    right: ${({ theme }) => theme.sizes.sz128};
    top: 50%;
  }
`;
