import React, { PropsWithChildren } from "react";
import { StyledComponent } from "styled-components";

export type ContainerType = React.FC | StyledComponent<any, any>;
export interface StyleContainerProps {
  Container?: ContainerType;
  ItemContainer?: ContainerType;
  LinkContainer?: ContainerType;
  TabsContainer?: ContainerType;
  TitleContainer?: ContainerType;
  IconContainer?: ContainerType;
}

export interface ContainerChildProps {
  onDataLoaded?: (isLoaded: boolean) => void;
}

export const EmptyContainer = ({ children }: PropsWithChildren<{}>) => (
  <>{children}</>
);
export const DivContainer = ({ children }: PropsWithChildren<{}>) => (
  <div>{children}</div>
);
export const SpanContainer = ({ children }: PropsWithChildren<{}>) => (
  <span>{children}</span>
);
