import React from "react";
import styled from "styled-components";
import ReactModal from "react-modal";
import {
  GridRow,
  GridColumn,
  Button,
} from "@eriksdigital/atomic-ui/components";

// @TODO
// - add cross icon option
// - add fade in modal option
// - add mobile styles for slideInFromBottom

interface Props extends ReactModal.Props {
  className?: string;
}

export const ReactModalAdapter: React.SFC<Props> = ({
  className,
  ...props
}: Props) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;
  return (
    <ReactModal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
    />
  );
};

export const StyledReactModal = styled(ReactModalAdapter)<{
  width?: string;
  closeTimeoutMS: number;
}>`
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 10;
    transition: opacity ${({ closeTimeoutMS }) => closeTimeoutMS / 1000}s;

    &.ReactModal__Overlay--after-open {
      opacity: 1;
    }
    &.ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }

  &__content {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: ${({ width }) => (width ? width : "400px")};
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    outline: none;
    padding: 20px;
    transform: translateX(400px);
    transition: transform ${({ closeTimeoutMS }) => closeTimeoutMS / 1000}s;

    &.ReactModal__Content--after-open {
      transform: translateX(0);
    }

    &.ReactModal__Content--before-close {
      transform: translateX(400px);
    }
  }
`;

export const WishlistActions = styled.div`
  button {
    margin-bottom: 1rem;
  }
`;

export const NotInERPWarning = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > svg {
    flex: 2;
  }
  > span {
    flex: 10;
    color: ${({ theme }) => theme.colors.signal.alert};
  }
`;

export const StyledColumn = styled(GridColumn)`
  margin: ${({ theme }) => theme.spacing.sp8} 0;
  padding: 0;
  align-self: flex-end;
`;

export const StyledRow = styled(GridRow)`
  padding: ${({ theme }) => theme.spacing.sp8};
`;

export const StyledButton = styled(Button)`
  margin-left: ${({ theme }) => theme.spacing.sp8};
`;

export const CustomersContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.sp24};
`;
export const FavouriteCustomersContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.sp48};
`;

export const CustomerCardContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.default.white};
  margin-bottom: ${({ theme }) => theme.spacing.sp8};
  cursor: pointer;
  position: relative;
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.default.blueA};
  }
`;

export const HeartToggleContainer = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing.sp16};
  right: ${({ theme }) => theme.spacing.sp16};
  z-index: 11;
`;
export const CustomerCardContainerActive = styled(CustomerCardContainer)`
  border: 2px solid ${({ theme }) => theme.colors.default.blueD};
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.default.blueD};
  }
`;
export const BusinessCardHeader = styled.span``;

export const BusinessCardCompanyName = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft20};
`;
export const BusinessCardCustomerNumber = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft16};
  font-weight: normal;
`;
