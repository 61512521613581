/* istanbul ignore file */

import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({
      hasError: true,
      error,
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>Something went wrong.</h1>
          <p>
            There has been an error for some reason. If you're seeing this
            screen, there's a fair chance that it is because you're using an
            incompatible browser - if you're using Internet Explorer or Edge,
            please consider switching to Google Chrome.
          </p>
          <p>
            The error that triggered this was:{" "}
            {JSON.stringify(this.state.error)}
          </p>
          <p>
            If you see this page while using the latest version of
            Chrome/FireFox/Safari, please send the above error to the WIKI team
            along with your current URL and what you were trying to do. This
            will help us fix whatever the error is sooner.
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
