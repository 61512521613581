import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledLink = styled(Link)`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #005ca9;
  &:hover {
    text-decoration: underline;
  }
`;

/**
 * NOTICE
 * This css overrides is a solution for modifying atomic UI
 * We have a very long breadcrumb link text
 * More sophisticated solution is to change atomic ui to receive more variables, or to export the styled components to outside, or to show full title on hover.
 */
export const BreadcrumbContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacing.sp8} 0 ${theme.spacing.sp8}`};
  li,
  li span,
  li span a {
    white-space: normal;
    display: inline;
  }
  li:first-child {
    padding-left: 0;
  }
  li::after {
    top: calc(50% - 0.1rem);
  }
`;
