import React, { useEffect } from "react";
import { H4, Dropdown, TextField } from "@eriksdigital/atomic-ui/components";
import { StyledRow } from "../style";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { contextSelector, customerSelector } from "state/selector";
import {
  setSearchCompany,
  setSearchCountry,
  setIsSearchLoading,
} from "state/customer";
import { getAvailableCountry } from "utils/CountryLanguageUtils";
import { CountryType } from "state/context";
import { Customers } from "components/Modal/CustomerModal";
import { useLazyQuery } from "@apollo/react-hooks";
import { SEARCH_CUSTOMER } from "./queries";
import useDebounce from "hooks/useDebounce";
import Notification, { WarningIconComponent } from "../../Notification";

export const CustomerFind = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { searchCountry, searchCompany } = useSelector(customerSelector);
  const { languageCode } = useSelector(contextSelector);
  const countries = getAvailableCountry();

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value =
      event.currentTarget.dataset.value || event.currentTarget.value;
    dispatch(setSearchCountry(value as CountryType));
  };

  const handleInputChange = (callback: Function) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value =
      event.currentTarget.dataset.value || event.currentTarget.value;

    dispatch(callback(value));
  };

  const getSearchCompany = (companyName: string) =>
    !!companyName ? `*${companyName.trim()}` : companyName;
  const debouncedSearchCompany = useDebounce<string>(
    getSearchCompany(searchCompany),
    400
  );

  const [searchCustomer, { loading, error, data }] = useLazyQuery(
    SEARCH_CUSTOMER,
    {
      variables: {
        searchIndex: `search_customer`,
        countryCode: searchCountry.toUpperCase(),
        query: debouncedSearchCompany,
        languageCode,
      },
    }
  );

  const handleSearchCustomer = () => searchCustomer();
  useEffect(() => {
    if (!!debouncedSearchCompany) handleSearchCustomer();
  }, [debouncedSearchCompany]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(setIsSearchLoading(loading));
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const customers = data?.SearchCustomers;

  return (
    <>
      <H4>
        <FormattedMessage id="customer.find-customer" />
      </H4>
      <StyledRow>
        {!!countries?.length && (
          <Dropdown
            label={<FormattedMessage id="country-language-selector.country" />}
            id="select01"
            list={countries}
            onClick={handleCountryChange}
            setWidth="100%"
            showNative={false}
            value={searchCountry}
          />
        )}
      </StyledRow>
      <StyledRow>
        <TextField
          name="company"
          id="company"
          data-testid="search-company"
          placeholder={intl.formatMessage({ id: "customer.find-organization" })}
          label={"Company Name / Customer Number"}
          value={searchCompany}
          onChange={handleInputChange(setSearchCompany)}
        />
      </StyledRow>
      {!!debouncedSearchCompany &&
        !loading &&
        !!customers &&
        !customers.length && (
          <Notification IconComponent={WarningIconComponent}>
            <div>No customer found</div>
          </Notification>
        )}
      {!!debouncedSearchCompany && (!!loading || !!error || !!customers) && (
        <Customers loading={loading} error={error} customers={customers} />
      )}
    </>
  );
};
