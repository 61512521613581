import { RootState } from "./rootReducer";

export const contextSelector = ({ context }: RootState) => context;
export const wishlistSelector = ({ wishlist }: RootState) => wishlist;
export const customerSelector = ({ customer }: RootState) => customer;
export const compareSelector = ({ compare }: RootState) => compare;
export const notificationSelector = ({ notification }: RootState) =>
  notification;
export const userSelector = ({ user }: RootState) => user;
export const itemSelector = ({ item }: RootState) => item;
export const apiErrorSelector = ({ apiError }: RootState) => apiError;
