import styled from "styled-components";
import { Icon, PopupHost, Button } from "@eriksdigital/atomic-ui/components";
import { colors, spacing } from "@eriksdigital/atomic-ui/styles";

const defaultPXSize = 16;
export const pxToRem = (px: number) => `${px / defaultPXSize}rem`;
export const remToPx = (rem: number) => defaultPXSize * rem;

export const StyledSelectorHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${spacing.sp16};
  cursor: pointer;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 25px;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-left: ${spacing.sp8};
  height: ${pxToRem(16)};
  width: ${pxToRem(16)};
`;

export const StyledLanguage = styled.p`
  color: ${colors.text.secondary};
`;

export const StyledFlag = styled.div`
  width: ${pxToRem(32)};
  margin-right: ${spacing.sp8};
`;

export const StyledPopupHost = styled(PopupHost)`
  left: ${pxToRem(-8)}; /* to compensate 1px border + 2px box-shadow */
  z-index: 10;

  div:first-child {
    max-height: none;
    overflow: visible; /* to avoid dropdown provoc scroll inside the popup frame */
  }

  ul {
    margin-left: 0;
  }
`;

export const StyledTitle = styled.p`
  color: ${colors.text.black};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft20};
  margin: 0 0 ${spacing.sp24};
`;

export const StyledDropdownWrapper = styled.div`
  margin-bottom: ${spacing.sp16};
`;

export const StyledSelectorContent = styled.div`
  margin: ${spacing.sp8} 0;
`;

export const Skeleton = styled.div`
  margin-top: ${pxToRem(8)};
  padding: 0 ${pxToRem(15)};
  display: flex;
`;

export const StyledGhost = styled(Button)`
  svg {
    width: ${pxToRem(30)};
    height: auto;
    margin: 0;
    fill: none;
    stroke: none;
    border-radius: ${pxToRem(3)};
  }
`;
