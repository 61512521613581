import styled from "styled-components";
import { Link } from "@eriksdigital/atomic-ui/components";

export const NotificationComponentContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.sp16} 0;
  border: 1px solid ${({ theme }) => theme.colors.default.gray};
  padding: ${({ theme }) => `${theme.spacing.sp4} ${theme.spacing.sp16}`};
  border-radius: ${({ theme }) => theme.spacing.sp16};
  border-left: 4px solid ${({ theme }) => theme.colors.default.yellow};
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const NotificationIconContainer = styled.div`
  margin-right: ${({ theme }) => theme.spacing.sp8};
  padding-top: ${({ theme }) => theme.spacing.sp8};
  svg {
    height: ${({ theme }) => theme.spacing.sp24};
    width: ${({ theme }) => theme.spacing.sp24};
  }
`;
export const NotificationLinkContainer = styled(Link)`
  margin-left: auto;
`;
