import React, { lazy, Suspense } from "react";
import { Loading } from "components/Loader";
import { Flag } from "./style";

const NL = lazy(() => import("./Flags/NL"));
const BE = lazy(() => import("./Flags/BE"));
const CH = lazy(() => import("./Flags/CH"));
const CZ = lazy(() => import("./Flags/CZ"));
const DE = lazy(() => import("./Flags/DE"));
const ES = lazy(() => import("./Flags/ES"));
const FR = lazy(() => import("./Flags/FR"));
const HU = lazy(() => import("./Flags/HU"));
const LU = lazy(() => import("./Flags/LU"));
const US = lazy(() => import("./Flags/US"));
const APAC = lazy(() => import("./Flags/APAC"));
const PL = lazy(() => import("./Flags/PL"));
const SK = lazy(() => import("./Flags/SK"));
const UK = lazy(() => import("./Flags/UK"));

interface Flags {
  [key: string]: React.FC;
}

export type FlagPaths =
  | "NL"
  | "BE"
  | "DE"
  | "CH"
  | "UK"
  | "FR"
  | "LU"
  | "HU"
  | "CZ"
  | "APAC"
  | "US"
  | "PL"
  | "ES"
  | "SK";

export const flagPaths = {
  NL: NL,
  BE: BE,
  DE: DE,
  CH: CH,
  UK: UK,
  FR: FR,
  LU: LU,
  HU: HU,
  CZ: CZ,
  APAC: APAC,
  US: US,
  PL: PL,
  ES: ES,
  SK: SK,
} as Flags;

export const CountryFlag = ({
  code,
  width = "40px",
}: {
  code: string;
  width?: string;
}) => {
  const FlagSVG = flagPaths[code];

  return (
    <Flag width={width} data-testid={`flag-${code}`}>
      <Suspense fallback={<Loading />}>
        <FlagSVG />
      </Suspense>
    </Flag>
  );
};

export default CountryFlag;
