import { BusinessCard, HeartToggle } from "@eriksdigital/atomic-ui/components";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  addFavouriteCustomer,
  removeFavouriteCustomer,
  TempCustomer,
} from "../../../state/customer";
import { customerSelector } from "../../../state/selector";
import CopyToClipboard from "../../CopyToClipboard";
import {
  BusinessCardCompanyName,
  BusinessCardCustomerNumber,
  BusinessCardHeader,
  CustomerCardContainer,
  CustomerCardContainerActive,
  HeartToggleContainer,
} from "../style";

interface CustomerCardProps {
  customer: any;
  onClick?: (customerNo: TempCustomer) => void;
  isSelected?: boolean;
}
export const CustomerCard = ({
  customer,
  onClick,
  isSelected = false,
}: CustomerCardProps) => {
  const dispatch = useDispatch();
  const {
    customerCountryCode,
    customerCity,
    customerName2,
    customerNo,
    customerName,
  } = customer;
  const { favouriteCustomers } = useSelector(customerSelector);
  const isFavourite = (favouriteCustomers || []).includes(customerNo);
  const Container = isSelected
    ? CustomerCardContainerActive
    : CustomerCardContainer;
  const handleClick = () => {
    if (onClick) onClick(customer);
  };
  const handleToggle = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    isFavourite
      ? dispatch(removeFavouriteCustomer(customerNo))
      : dispatch(addFavouriteCustomer(customerNo));
  };
  return (
    <Container>
      <HeartToggleContainer>
        <HeartToggle
          size="sz24"
          checked={isFavourite}
          id={customerNo}
          onChange={handleToggle}
        />
      </HeartToggleContainer>
      <div onClick={handleClick}>
        <BusinessCard
          contactName={
            <BusinessCardHeader>
              <BusinessCardCompanyName>
                {customerName ?? ""} {customerName2 ?? ""}
              </BusinessCardCompanyName>
              {!!customerNo && (
                <CopyToClipboard text={customerNo}>
                  <BusinessCardCustomerNumber>
                    <FormattedMessage id="customer.customer-number" />:{" "}
                    {customerNo}
                  </BusinessCardCustomerNumber>
                </CopyToClipboard>
              )}
            </BusinessCardHeader>
          }
          contactAddressLine1={customerCity}
          contactAddressLine2={customerCountryCode}
          status="loaded"
        />
      </div>
    </Container>
  );
};
