import styled, { css } from "styled-components";
import { Span } from "@eriksdigital/atomic-ui/components";
import { LinkWithPreload } from "./LazyPreload";

export const FullWidthBackground = styled.div<{
  height: string;
  backgroundColor: string;
}>`
  height: ${({ theme, height }) => height || theme.sizes.sz20};
  width: 100%;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.default.white};
  ${({ height, theme }) =>
    css`
      padding-top: calc(${height || theme.sizes.sz20} / 5);
    `};
`;

export const StyledLink = styled(LinkWithPreload)<{ underline?: boolean }>`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.heading.primary};

  ${(props) =>
    props.underline &&
    `
  &:hover {
    text-decoration: underline;
  }`}

  em {
    background-color: rgba(252, 198, 5, 0.4);
    font-style: normal;
  }
`;

export const StyledSpan = styled(Span)`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin: 0;
  line-height: ${({ theme }) => theme.sizes.sz20};
`;

export const BoldSpan = styled(StyledSpan)`
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
`;

export const ItemSizes = styled.div`
  margin: 0;

  table {
    border: solid 1px #ddd;
  }

  table td,
  tbody {
    padding: 8px;
  }

  table tr:nth-of-type(even) {
    background: #f9f9f9;
  }
`;

const directions = {
  row: "row",
  column: "column",
};

type Directions = "row" | "column";
type JustifyContent = "space-between" | "flex-end";
type AlignItems = "center";

export const FlexContainer = styled.div<{
  direction?: Directions;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
}>`
  display: flex;
  flex-direction: ${(props) => props.direction && directions[props.direction]};
  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}
    ${(props) =>
      props.alignItems &&
      css`
        align-items: ${props.alignItems};
      `}
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-size: ${({ theme }) => theme.fonts.fontSize.medium};
  line-height: ${({ theme }) => theme.fonts.lineHeight.heading};
  text-align: left;
  flex: 1;

  .row {
    display: flex;
  }
`;
