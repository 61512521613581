import { combineReducers } from "@reduxjs/toolkit";
import context from "./context";
import wishlist from "./wishlist";
import customer from "./customer";
import compare from "./compare";
import notification from "./notification";
import user from "./user";
import item from "./item";
import apiError from "./apiError";

const rootReducer = combineReducers({
  context,
  wishlist,
  customer,
  compare,
  notification,
  user,
  item,
  apiError,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
