import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
import { CrossIcon } from "@eriksdigital/atomic-ui/components/Icons";
import { H4, P } from "@eriksdigital/atomic-ui/components";

import { createImageUrl } from "utils/URLBuilder";
import { DEFAULT_IMAGE, DEFAULT_SOURCE } from "utils/imageUtils";
import { Image } from "components/Image";
import { StyledLink } from "utils/style";
import { searchHighlight } from "utils/searchHighlightHelper";

import { LoadingSearchResults } from "components/Loader";

import {
  setBrandName,
  setHeaderSearchBrandName,
  setSearchResult,
  setTurboModeFilters,
} from "state/context";

import {
  StyledSearchResultsModal,
  StyledSearchResultsWrapper,
  StyledSearchResultTitle,
  StyledSearchResultsHeading,
  StyledSearchResultProduct,
  StyledSearchResultProducts,
  StyledSearchResultTypeHeader,
  StyledSearchResults,
  StyledSearchResultProductsInnerWrap,
  StyledSearchResultItem,
  StyledSearchResultItemImage,
  StyledStarchResultsAutoSuggestBrand,
  StyledStarchResultsAutoSuggestBrandsWrapper,
  StyledCrossIcon,
  StyledMoreButton,
  StyledNoSearchResults,
} from "./styles";
import { getModifiedParams } from "utils/param";
import { apiErrorSelector } from "state/selector";

export const HeaderSearchResults = (data: any) => {
  const intl = useIntl();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const intlBrandName = intl.formatMessage({ id: "autocomplete.brand.brand" });

  const {
    handleShowModal,
    searchResult,
    callLoading,
    brandName,
    innerBrand,
    handleInnerBrand,
    handleInputValue,
  } = data || null;
  const { apiError } = useSelector(apiErrorSelector);

  const { items = [], productGroups = [], products = [], suggestionResponse } =
    searchResult || {};

  const handleFollowLink = () => {
    handleShowModal(false);
    dispatch(setBrandName(innerBrand));
    dispatch(setSearchResult(null));
    dispatch(setTurboModeFilters([]));

    if (innerBrand !== "") {
      dispatch(setHeaderSearchBrandName(innerBrand));

      dispatch(
        setTurboModeFilters([
          {
            name: intlBrandName,
            values: [innerBrand],
            range: null,
            valueType: "STRING",
            unitSymbol: null,
          },
        ])
      );
    }
  };

  const handleSelectBrand = (value: string) => {
    handleInnerBrand(value);
    handleInputValue("");
  };

  const handleCloseModal = () => {
    handleShowModal(false);
    dispatch(setSearchResult(null));
  };

  const showNoResults =
    !searchResult?.items.length &&
    !searchResult?.productGroups.length &&
    !searchResult?.products.length &&
    searchResult !== null;

  return (
    <StyledSearchResultsModal>
      <StyledSearchResultsWrapper>
        <StyledSearchResultTitle>
          <StyledSearchResultsHeading>
            <FormattedMessage id="header.search.what-are-you-looking-for" />
          </StyledSearchResultsHeading>

          <StyledCrossIcon
            data-testid="search-close-button"
            onClick={() => handleCloseModal()}
            as={CrossIcon}
          />
        </StyledSearchResultTitle>
        {apiError && <div>{parse(apiError)}</div>}
        {callLoading && !apiError && <LoadingSearchResults />}
        {!callLoading && (
          <>
            <StyledSearchResultProducts>
              {suggestionResponse?.brands.length > 0 && brandName !== "" && (
                <StyledStarchResultsAutoSuggestBrandsWrapper>
                  <FormattedMessage id="autocomplete.brand.home" />
                  {suggestionResponse?.brands.map(
                    (brand: string, index: number) => (
                      <StyledStarchResultsAutoSuggestBrand
                        key={index}
                        onClick={() => handleSelectBrand(brand)}
                      >
                        {brand}
                      </StyledStarchResultsAutoSuggestBrand>
                    )
                  )}
                </StyledStarchResultsAutoSuggestBrandsWrapper>
              )}
            </StyledSearchResultProducts>
            <StyledSearchResults>
              <div>
                {items.length > 0 && (
                  <StyledSearchResultProducts>
                    <div>
                      <StyledSearchResultTypeHeader>
                        <StyledSearchResultsHeading>
                          <FormattedMessage id="home.items" />
                        </StyledSearchResultsHeading>

                        <div onClick={() => handleShowModal(false)}>
                          <StyledLink
                            to={{
                              pathname: `/search/items/`,
                              search: getModifiedParams(search, {
                                page: "1",
                                source: "PIM",
                              }),
                            }}
                            underline={true}
                          >
                            <StyledMoreButton>
                              <FormattedMessage id="home.view-more" />
                            </StyledMoreButton>
                          </StyledLink>
                        </div>
                      </StyledSearchResultTypeHeader>
                      <>
                        {items.map(
                          (item: {
                            partNumber: string;
                            brand: string;
                            fullImage: string;
                            name: string;
                            highlights: string;
                            source: string;
                          }) => {
                            if (!item) return null;
                            const {
                              partNumber,
                              brand,
                              fullImage = DEFAULT_IMAGE,
                              name,
                              highlights,
                              source = DEFAULT_SOURCE,
                            } = item;
                            return (
                              <StyledSearchResultItem key={item.partNumber}>
                                <Image
                                  src={
                                    createImageUrl(
                                      fullImage,
                                      "detail",
                                      source
                                    ) || DEFAULT_IMAGE
                                  }
                                  Container={StyledSearchResultItemImage}
                                />
                                <div onClick={() => handleFollowLink()}>
                                  <StyledLink
                                    data-testid={`item-card-${partNumber}`}
                                    key={partNumber}
                                    to={{
                                      pathname: `/item/${encodeURIComponent(
                                        partNumber
                                      )}`,
                                      search,
                                    }}
                                    underline={true}
                                  >
                                    {searchHighlight(
                                      highlights,
                                      "brand",
                                      brand
                                    ) ?? undefined}{" "}
                                    {searchHighlight(
                                      highlights,
                                      "name",
                                      name
                                    ) ?? (
                                      <FormattedMessage id="home.item-erp-desc" />
                                    )}
                                  </StyledLink>
                                </div>
                              </StyledSearchResultItem>
                            );
                          }
                        )}
                      </>
                    </div>
                  </StyledSearchResultProducts>
                )}
              </div>
              <>
                {products.length > 0 && (
                  <StyledSearchResultProducts>
                    <StyledSearchResultTypeHeader>
                      <StyledSearchResultsHeading>
                        <FormattedMessage id="home.products" />
                      </StyledSearchResultsHeading>
                      <div onClick={() => handleShowModal(false)}>
                        <StyledLink
                          to={{
                            pathname: `/search/products/`,
                            search: getModifiedParams(search, {
                              page: "1",
                              source: "PIM",
                            }),
                          }}
                          underline={true}
                        >
                          <StyledMoreButton>
                            <FormattedMessage id="home.view-more" />
                          </StyledMoreButton>
                        </StyledLink>
                      </div>
                    </StyledSearchResultTypeHeader>
                    <StyledSearchResultProductsInnerWrap>
                      {products.map(
                        (product: {
                          partNumber: string;
                          name: string;
                          highlights: string;
                        }) => {
                          if (!product) return null;
                          const { partNumber, name, highlights } = product;

                          return (
                            <StyledSearchResultProduct
                              onClick={() => handleFollowLink()}
                            >
                              <StyledLink
                                data-testid={`list-card-product-${partNumber}`}
                                key={partNumber}
                                to={{
                                  pathname: `/product/${encodeURIComponent(
                                    partNumber
                                  )}`,
                                  search: search,
                                }}
                                underline={true}
                              >
                                {searchHighlight(
                                  highlights,
                                  "product.name",
                                  name
                                ) ?? (
                                  <FormattedMessage id="home.unknown-prod-descr" />
                                )}
                              </StyledLink>
                            </StyledSearchResultProduct>
                          );
                        }
                      )}
                    </StyledSearchResultProductsInnerWrap>
                  </StyledSearchResultProducts>
                )}

                {productGroups.length > 0 && (
                  <StyledSearchResultProducts>
                    <StyledSearchResultTypeHeader>
                      <StyledSearchResultsHeading>
                        <FormattedMessage id="home.product-groups" />
                      </StyledSearchResultsHeading>
                      <div onClick={() => handleShowModal(false)}>
                        <StyledLink
                          to={{
                            pathname: `/search/groups/`,
                            search: getModifiedParams(search, {
                              page: "1",
                              source: "PIM",
                            }),
                          }}
                          underline={true}
                        >
                          <StyledMoreButton>
                            <FormattedMessage id="home.view-more" />
                          </StyledMoreButton>
                        </StyledLink>
                      </div>
                    </StyledSearchResultTypeHeader>
                    <StyledSearchResultProductsInnerWrap>
                      {productGroups.map(
                        (productGroup: {
                          groupIdentifier: string;
                          name: string;
                          highlights: string;
                        }) => {
                          if (!productGroup) return null;
                          const {
                            groupIdentifier,
                            name,
                            highlights,
                          } = productGroup;

                          return (
                            <StyledSearchResultProduct
                              key={groupIdentifier}
                              onClick={() => handleFollowLink()}
                            >
                              <StyledLink
                                data-testid={`list-card-group-${groupIdentifier}`}
                                key={groupIdentifier}
                                to={{
                                  pathname: `/group/${encodeURIComponent(
                                    groupIdentifier
                                  )}`,
                                  search: search,
                                }}
                                underline={true}
                              >
                                {searchHighlight(
                                  highlights,
                                  "productGroup.name",
                                  name
                                ) ?? (
                                  <FormattedMessage id="home.name-unavailable" />
                                )}
                              </StyledLink>
                            </StyledSearchResultProduct>
                          );
                        }
                      )}
                    </StyledSearchResultProductsInnerWrap>
                  </StyledSearchResultProducts>
                )}
              </>
            </StyledSearchResults>
          </>
        )}
        {!callLoading && showNoResults && (
          <StyledNoSearchResults>
            <H4>
              <FormattedMessage id="turbo-mode.no-results" />
            </H4>
            <P>
              <FormattedMessage id="search.no-results-suggestion" />
            </P>
          </StyledNoSearchResults>
        )}
      </StyledSearchResultsWrapper>
    </StyledSearchResultsModal>
  );
};
