import styled from "styled-components";

export const StyledHeaderSearchInputWrapper = styled.div`
  position: relative;
  z-index: 30;
`;

export const HeaderSearchOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(177, 179, 184, 0.7);
  z-index: 10;
`;
