import styled from "styled-components";
import { PopupHost } from "@eriksdigital/atomic-ui/components";
import { Icon } from "@eriksdigital/atomic-ui/components";

const defaultPXSize = 16;
export const pxToRem = (px: number) => `${px / defaultPXSize}rem`;

export const UserDetalsWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${pxToRem(10)};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft16};

  &:hover {
    cursor: pointer;
  }
`;

export const StyledMyAccountIcon = styled(Icon)`
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  fill: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledPopupHost = styled(PopupHost)`
  left: ${pxToRem(-2)}; /* to compensate 1px border + 2px box-shadow */
  z-index: 10;

  div:first-child {
    min-width: ${pxToRem(180)};
    overflow: visible; /* to avoid dropdown provoc scroll inside the popup frame */
  }

  ul {
    margin-left: 0;
  }
`;
