import React, { useEffect } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpen } from "../../../state/customer";
import { customerSelector } from "../../../state/selector";
import { StyledReactModal } from "../style";
import {
  CustomerFind,
  CustomerSelected,
  FavouriteCustomers,
} from "components/Modal/CustomerModal";

export const CustomerModal = () => {
  const dispatch = useDispatch();
  const { isOpen, selectedCustomer } = useSelector(customerSelector);

  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      ReactModal.setAppElement(root);
    }
  }, []);

  useEffect(() => {
    const bodyElement = document.getElementsByTagName("body");
    if (bodyElement) {
      bodyElement[0].style.overflow = isOpen ? "hidden" : "auto";
    }
  }, [isOpen]);

  return (
    <StyledReactModal
      isOpen={!!isOpen}
      onRequestClose={() => dispatch(setIsOpen(false))}
      closeTimeoutMS={200}
    >
      {!selectedCustomer && <CustomerFind />}
      {!!selectedCustomer && <CustomerSelected customer={selectedCustomer} />}
      <FavouriteCustomers />
    </StyledReactModal>
  );
};
