import React from "react";

import { PlaceholderItemRow } from "./styled";

export const LoadingItemsRow = (props: { items: number }) => {
  const { items } = props;

  const squares = Array.from(Array(items).keys());

  return (
    <div data-testid="loading-filters">
      {[...squares].map((i) => (
        <PlaceholderItemRow
          key={i}
          className="loader-item-row"
          height="2.5rem"
          type="custom"
          width="100%"
        />
      ))}
    </div>
  );
};
