export const getObjectFromString = (str: string) => {
  try {
    var o = JSON.parse(str);
    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {}
  return false;
};

export const getBooleanFromString = (
  str: string,
  defaultBoolean: boolean = false
): boolean => {
  try {
    var o = JSON.parse(str);
    if (o !== null && typeof o === "boolean") {
      return o;
    }
  } catch (e) {}
  return defaultBoolean;
};
