import React, { PropsWithChildren } from "react";
import { Icon } from "@eriksdigital/atomic-ui/components";
import { WarningIcon } from "@eriksdigital/atomic-ui/components/Icons";
import { colors } from "@eriksdigital/atomic-ui/styles";
import { StyleContainerProps } from "interfaces/container";

import {
  NotificationComponentContainer,
  NotificationIconContainer,
  NotificationLinkContainer,
} from "./style";

interface NotificationProps extends StyleContainerProps {
  title?: string;
  IconComponent?: React.ReactNode;
  link?: string;
}

export const Notification = ({
  children,
  title,
  link,
  IconComponent,
  IconContainer = NotificationIconContainer,
  Container = NotificationComponentContainer,
  LinkContainer = NotificationLinkContainer,
}: NotificationProps & PropsWithChildren<React.ReactNode>) => {
  return (
    <>
      <Container>
        {!!IconComponent && <IconContainer>{IconComponent}</IconContainer>}
        <div>
          {!!title && (
            <>
              <strong>{title}</strong>
              <br />
            </>
          )}
          {children}
        </div>
        {!!link && (
          <LinkContainer href={link} target="_blank">
            Read More
          </LinkContainer>
        )}
      </Container>
    </>
  );
};

export const WarningIconComponent = (
  <Icon as={WarningIcon} fill={colors.default.yellow} viewBox="0 0 35 35" />
);

export default Notification;
