import React from "react";
import { Icon } from "@eriksdigital/atomic-ui/components";
import { HeartIcon } from "@eriksdigital/atomic-ui/components/Icons";
import { colors } from "@eriksdigital/atomic-ui/styles";
import {
  IconWrapper,
  ItemCountCircle,
  IconLabel,
  HeaderIconWrapper,
} from "./../style";
import { useDispatch, useSelector } from "react-redux";
import { wishlistSelector } from "state/selector";
import { setIsOpen } from "state/wishlist";
import { FormattedMessage } from "react-intl";

const AddToWishlistIcon = () => {
  const dispatch = useDispatch();
  const { wishlist } = useSelector(wishlistSelector);

  const wishlistLength = (wishlist && wishlist.length) ?? 0;

  return (
    <HeaderIconWrapper>
      <IconWrapper
        data-testid="wishlist-toggle"
        onClick={() => dispatch(setIsOpen(true))}
      >
        <ItemCountCircle isLargeAmount={wishlistLength > 99}>
          {wishlistLength}
        </ItemCountCircle>
        <Icon
          as={HeartIcon}
          fill={colors.heading.primary}
          height={30}
          width={30}
          viewBox="0 0 35 35"
        />
      </IconWrapper>
      <IconLabel>
        <FormattedMessage id="wishlist" />
      </IconLabel>
    </HeaderIconWrapper>
  );
};

export default AddToWishlistIcon;
