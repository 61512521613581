import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CompareState {
  showDifferenceOnly: boolean;
  items: CompareItem[];
}
export interface CompareItem {
  partNumber: string;
  image: string;
  source: string;
}

export const isMaxItems = (items: CompareItem[] = []) => items.length >= 4;

export const getPartNumbers = (items: CompareItem[] = []) =>
  items.map(({ partNumber }) => partNumber);

export const isPartNumberSelected = (
  items: CompareItem[] = [],
  partNumber = ""
) => getPartNumbers(items).includes(partNumber);

export const getCompareItem = <T extends CompareItem>({
  partNumber,
  image,
}: T) => ({ partNumber, image });

const initialState: CompareState = {
  showDifferenceOnly: false,
  items: [],
};

const CompareSlice = createSlice({
  name: "compare",
  initialState,
  reducers: {
    setShowDifferenceOnly(state, action: PayloadAction<boolean>) {
      state.showDifferenceOnly = action.payload;
    },
    addItem(state, action: PayloadAction<CompareItem>) {
      const partNumbers = getPartNumbers(state.items);
      if (!partNumbers.includes(action.payload.partNumber)) {
        state.items = [...state.items, action.payload];
      }
    },
    removeItem(state, action: PayloadAction<string>) {
      const partNumbers = getPartNumbers(state.items);
      if (partNumbers.includes(action.payload)) {
        state.items = state.items.filter(
          ({ partNumber }) => partNumber !== action.payload
        );
      }
    },
    setItems(state, action: PayloadAction<CompareItem[]>) {
      state.items = action.payload;
    },
    emptyItems(state) {
      state.items = [];
    },
    setMultipleValue(state, action: PayloadAction<Partial<CompareState>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const {
  setShowDifferenceOnly,
  addItem,
  removeItem,
  setItems,
  emptyItems,
  setMultipleValue,
} = CompareSlice.actions;
export default CompareSlice.reducer;
