import gql from "graphql-tag";

export const ITEM_BREADCRUMB_QUERY = gql`
  query ItemBreadCrumb($partNumber: String!) {
    Item(partNumber: $partNumber) {
      Descriptions: has_description_i {
        shortDescription
        languageCode
      }
      Product: products {
        Descriptions: has_description_p {
          shortDescription
          languageCode
        }
        partNumber
        ProductGroup: productgroups {
          groupIdentifier
          Names: has_description_pg {
            name
            languageCode
          }
        }
      }
    }
  }
`;

export const PRODUCT_BREADCRUMB_QUERY = gql`
  query ProductBreadCrumb($partNumber: String!) {
    Product(partNumber: $partNumber) {
      Descriptions: has_description_p {
        shortDescription
        languageCode
      }
      partNumber
      ProductGroup: productgroups {
        groupIdentifier
        Names: has_description_pg {
          name
          languageCode
        }
      }
    }
  }
`;

export const PRODUCT_GROUP_BREADCRUMB_QUERY = gql`
  query ProductGroupBreadCrumb($groupIdentifier: String!) {
    ProductGroup(groupIdentifier: $groupIdentifier) {
      Names: has_description_pg {
        name
        languageCode
      }
      groupIdentifier
    }
  }
`;
