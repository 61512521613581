import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  CrossIcon,
  SearchIcon,
} from "@eriksdigital/atomic-ui/components/Icons";

import {
  SearchFormContainer,
  SearchFormWrapper,
  StyledSearchForm,
  SearchFormSelectedBrand,
  SearchFormSelectedBrandLabel,
  SearchFormClearInputButton,
  AutoSuggestBrandsWrapper,
  AutoSuggestBrandsLabel,
  AutoSuggestBrand,
  StyledSearchIcon,
  StyledBrandCrossIcon,
  StyledCrossIcon,
} from "./style";

import { useDispatch, useSelector } from "react-redux";
import { contextSelector } from "state/selector";

import { searchData } from "services/ElasticSearchAPICalls";
import { useHistory, useLocation } from "react-router-dom";
import {
  setSearchQuery,
  setCallLoading,
  setBrandName,
  setHeaderSearchBrandName,
  setSearchResult,
  setTurboModeFilters,
} from "state/context";

interface SearchProps {
  searchRealm: string;
  placeholder: string;
  brandName?: string;
  source?: string;
  inputValue?: string;
  innerBrand?: string;
  handleInnerBrand?: (value: string) => void;
  isSearchModalActive?: boolean;
  isSearchStick?: boolean;
  handleSourceChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  removeSelectedBrand?: (value: string) => void;
  handleClearInput?: () => void;
  handleInputValue?: (value: string) => void;
  handleShowModal?: (value: boolean) => void;
}

const Search = (props: SearchProps) => {
  const {
    searchRealm,
    placeholder,
    isSearchStick,
    handleShowModal,
    inputValue,
    innerBrand,
    handleInnerBrand,
    handleInputValue,
  } = props;
  const intl = useIntl();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [itemLoadCount] = useState<number>(24);
  const [productLoadCount] = useState<number>(5);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const searchRealmHome = searchRealm === "home-search";
  const searchRealmHeader = searchRealm === "header-search";

  const {
    languageCode,
    erpSystemCode,
    searchQuery,
    allAssortments,
    brandName,
    searchResult,
    isSearchModalActive,
  } = useSelector(contextSelector);

  const name = intl.formatMessage({ id: "autocomplete.brand.brand" });
  const selectedBrand = searchRealmHome ? brandName : innerBrand;

  useEffect(() => {
    if (searchRealmHome && !searchQuery) {
      searchInputRef.current?.focus();
      handleInputValue && handleInputValue("");
    }

    if (brandName !== "") {
      handleInnerBrand && handleInnerBrand(brandName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchItems = async () => {
    if (!inputValue && !selectedBrand) return;
    dispatch(setCallLoading(true));
    const payload = await searchData({
      searchTerm: {
        inputValue: inputValue,
        brandName: selectedBrand,
      },
      languageCode,
      erpSystemCode,
      allAssortments,
      limit: searchRealmHome ? Math.max(itemLoadCount, productLoadCount) : 5,
      source: "PIM",
    }).catch((error) => {
      console.error(error);
    });

    if (payload) {
      dispatch(setSearchResult({ ...payload, selectedBrand: selectedBrand }));
      setTimeout(() => {
        dispatch(setCallLoading(false));
      }, 1000);
    }
  };

  // This functions should stay commented for "maybe" feature use
  // Search component with markup and dependencies for changing source can be found here
  // for example: https://github.com/eriksdigital/projectwiki-frontend/tree/PW-3 in src/Search component
  // const handleSourceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const source =
  //     event.currentTarget.dataset.value || event.currentTarget.value;
  //   setSource(source as SourceType);
  // };

  const handleSelectBrand = (value: string) => {
    if (searchRealmHome) {
      handleInnerBrand && handleInnerBrand(value);
      dispatch(setBrandName(value));
      handleInputValue && handleInputValue("");
    }
  };

  const removeSelectedBrand = (value: string) => {
    const newValue = `${selectedBrand} ${inputValue}`;

    handleInputValue && handleInputValue(newValue);
    dispatch(setBrandName(""));
    dispatch(setHeaderSearchBrandName(""));
    dispatch(setTurboModeFilters([]));
    handleInnerBrand && handleInnerBrand("");
    searchInputRef.current?.focus();

    if (searchRealmHome) {
      dispatch(setSearchQuery(newValue));
    }

    const params = new URLSearchParams(location.search);
    params.delete(`${name}`);

    history.push({
      pathname: location.pathname,
      search: "?" + params.toString(),
    });
  };

  const handleClearInput = () => {
    if (searchRealmHome) {
      dispatch(setSearchQuery(""));
      handleInputValue && handleInputValue("");
      searchInputRef.current?.focus();

      const params = new URLSearchParams(location.search);
      params.delete(`${name}`);

      history.push({
        pathname: location.pathname,
        search: "?" + params.toString(),
      });
    }
    if (searchRealmHeader) {
      handleInputValue && handleInputValue("");
      searchInputRef.current?.focus();

      if (brandName === "") {
        dispatch(setSearchResult(null));
      }
    }
  };

  useEffect(() => {
    if (searchRealmHome) {
      if (inputValue !== "" || selectedBrand !== "") {
        fetchItems();
        searchInputRef.current?.focus();
      }
    }

    if (searchRealmHeader) {
      if ((inputValue !== "" || selectedBrand !== "") && isSearchModalActive) {
        fetchItems();
        searchInputRef.current?.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedBrand,
    inputValue,
    searchRealm,
    isSearchModalActive,
    allAssortments,
    languageCode,
  ]);

  const { suggestionResponse = { brands: [] } } = searchResult || {};

  const suggestedBrands =
    inputValue !== "" && suggestionResponse.brands.length ? (
      <AutoSuggestBrandsWrapper searchRealm={searchRealm}>
        <AutoSuggestBrandsLabel>
          <FormattedMessage id="autocomplete.brand.home" />
        </AutoSuggestBrandsLabel>
        {suggestionResponse.brands.map((brand: string, index: number) => (
          <AutoSuggestBrand
            key={index}
            onClick={() => handleSelectBrand(brand)}
          >
            {brand}
          </AutoSuggestBrand>
        ))}
      </AutoSuggestBrandsWrapper>
    ) : null;

  return (
    <>
      <SearchFormContainer sticky={isSearchStick || false}>
        <StyledSearchIcon as={SearchIcon} />
        <SearchFormWrapper>
          {selectedBrand && (
            <SearchFormSelectedBrand>
              <SearchFormSelectedBrandLabel>
                <FormattedMessage id="autocomplete.brand.brand" />{" "}
                <span className={"brand"}>{` ${selectedBrand} `}</span>
              </SearchFormSelectedBrandLabel>
              <StyledBrandCrossIcon
                as={CrossIcon}
                onClick={() => removeSelectedBrand(selectedBrand)}
              />
            </SearchFormSelectedBrand>
          )}
          <StyledSearchForm
            data-testid="search-form"
            searchRealm={searchRealm}
            ref={searchInputRef}
            placeholder={placeholder}
            onChange={(e: any) =>
              handleInputValue && handleInputValue(e.target.value)
            }
            value={inputValue}
            data-hj-whitelist
            onFocus={(e: any) => {
              e.stopPropagation();
              handleShowModal && handleShowModal(true);
            }}
          />
          {searchRealmHome && (
            <>
              {searchInputRef?.current?.value !== "" && (
                <SearchFormClearInputButton
                  id="search-form-clear-input"
                  variant="ghost"
                  onClick={() => handleClearInput()}
                >
                  <FormattedMessage id="delete.button.name" />
                </SearchFormClearInputButton>
              )}
            </>
          )}
          {searchRealmHeader && isSearchModalActive && (
            <StyledCrossIcon
              data-testid="search-close-button"
              onClick={() => {
                handleClearInput();
              }}
              as={CrossIcon}
            />
          )}
        </SearchFormWrapper>
      </SearchFormContainer>
      {searchRealmHome && suggestedBrands}
    </>
  );
};

export default Search;
