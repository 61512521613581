import { H4 } from "@eriksdigital/atomic-ui/components";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { removeSelectedCustomer, TempCustomer } from "../../../state/customer";
import { CustomersContainer, StyledButton } from "../style";
import { CustomerCard } from "./CustomerCard";

// TODO: Activate when tou get new PW BI report from data department
// import CustomerDetailPowerBI from "./CustomerDetailPowerBI";

interface CustomerSelectedProps {
  customer: TempCustomer;
}

export const CustomerSelected = ({ customer }: CustomerSelectedProps) => {
  const dispatch = useDispatch();
  return (
    <CustomersContainer>
      <H4>
        <FormattedMessage
          id="customer.heading-existed"
          values={{
            customerName: `${customer.customerName ?? ""} ${
              customer.customerName2 ?? ""
            }`,
          }}
        />
      </H4>
      {/* Activate when tou get new PW BI report from data department */}
      {/* <CustomerDetailPowerBI customer={customer} /> */}
      <CustomersContainer>
        <CustomerCard customer={customer} />
        <StyledButton
          id={customer.customerNo}
          onClick={() => dispatch(removeSelectedCustomer())}
          text={<FormattedMessage id="customer.remove-customer-number" />}
        />
      </CustomersContainer>
    </CustomersContainer>
  );
};
