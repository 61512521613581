/* istanbul ignore file */

import { css } from "styled-components";

export const eriksFont = "geomanist";

const cdnUrl = "https://eriksdigitalcdn.azureedge.net/erd/fonts/";

export default css`
  @font-face {
    font-family: ${eriksFont};
    src: url('${`${cdnUrl}geomanist-regular-webfont.eot`}');
    src: url('${`${cdnUrl}geomanist-regular-webfont.eot?#iefix`}')
        format('embedded-opentype'),
      url('${`${cdnUrl}geomanist-regular-webfont.woff2`}') format('woff2'),
      url('${`${cdnUrl}geomanist-regular-webfont.woff`}') format('woff'),
      url('${`${cdnUrl}geomanist-regular-webfont.ttf`}') format('truetype'),
      url('${`${cdnUrl}geomanist-regular-webfont.svg#geomanist`}') format('svg');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: ${eriksFont};
    src: url('${`${cdnUrl}geomanist-medium-webfont.eot`}');
    src: url('${`${cdnUrl}geomanist-medium-webfont.eot?#iefix`}')
        format('embedded-opentype'),
      url('${`${cdnUrl}geomanist-medium-webfont.woff2`}') format('woff2'),
      url('${`${cdnUrl}geomanist-medium-webfont.woff`}') format('woff'),
      url('${`${cdnUrl}geomanist-medium-webfont.ttf`}') format('truetype'),
      url('${`${cdnUrl}geomanist-medium-webfont.svg#geomanist`}') format('svg');
    font-weight: 600;
    font-style: normal;
  }
`;
