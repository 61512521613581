import React, { useState } from "react";
import { createImageUrl } from "../../../utils/URLBuilder";
import { DEFAULT_NO_IMAGE } from "../../../utils/imageUtils";
import { CompareItem } from "../../../state/compare";
import { ItemCardImage } from "../../Card/style";

interface CompareBarThumbnailProps {
  item: CompareItem;
}
export const CompareBarThumbnail = ({ item }: CompareBarThumbnailProps) => {
  const { partNumber, source, image } = item;
  const [isError, setIsError] = useState<boolean>(false);
  // Sometimes image is not loaded, load defaul image here
  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    setIsError(true);
  };

  const imageUrl = item.image
    ? createImageUrl(image, "thumb60", source)
    : DEFAULT_NO_IMAGE;
  if (!!isError) {
    return (
      <ItemCardImage
        alt="Default"
        data-testid={`card-image-${partNumber}-error`}
        src={DEFAULT_NO_IMAGE}
      />
    );
  }
  return (
    <ItemCardImage
      alt={partNumber}
      data-testid={`card-image-${partNumber}`}
      src={imageUrl}
      onError={handleImageError}
    />
  );
};
export default CompareBarThumbnail;
