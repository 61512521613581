import React from "react";
import { BusinessCard } from "@eriksdigital/atomic-ui/components";
import { CustomerCardContainer } from "../style";

export const CustomerCardLoading = () => {
  return (
    <CustomerCardContainer>
      <BusinessCard
        contactAddressLine1="Address"
        contactAddressLine2="City/Town"
        contactName="Company Name"
        contactPhone="Phone Number"
        status="loading"
      />
    </CustomerCardContainer>
  );
};
