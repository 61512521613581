import styled from "styled-components";
import { MainLayout } from "@eriksdigital/atomic-ui/components";

export const CompareBarContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  height: 5rem;
  width: 100%;
  background-color: white;
  border-top: 1px solid ${({ theme }) => theme.colors.default.gray};
  padding: ${({ theme }) => theme.spacing.sp4} 0;
  align-items: center;
  ${MainLayout} {
    width: 100%;
  }
`;
export const CompareBarThumbnailContainer = styled.div`
  display: flex;
  margin: 0 ${({ theme }) => theme.spacing.sp8};
  picture {
    margin: 0 ${({ theme }) => theme.spacing.sp8};
  }
`;

export const CompareBarContentContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ClearContainer = styled.div`
  margin-right: auto;
`;
