import React, { useState } from "react";
import { Image as ImageAtomicUI } from "@eriksdigital/atomic-ui/components";
import { DEFAULT_NO_IMAGE } from "../../utils/imageUtils";
import {
  EmptyContainer,
  StyleContainerProps,
} from "../../interfaces/container";

interface ImageProps extends StyleContainerProps {
  src: string;
  alt?: string;
  testId?: string;
}
export const Image = ({
  src,
  alt = "",
  testId = "",
  Container = EmptyContainer,
}: ImageProps) => {
  const [isError, setIsError] = useState<boolean>(false);
  // Sometimes image is not loaded, load default image here
  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    setIsError(true);
  };
  return (
    <Container>
      {!isError && (
        <ImageAtomicUI
          alt={alt}
          data-testid={testId}
          src={src}
          onError={handleImageError}
        />
      )}
      {!!isError && (
        <ImageAtomicUI
          alt="Default"
          data-testid={`${testId}-error`}
          src={DEFAULT_NO_IMAGE}
        />
      )}
    </Container>
  );
};
export default Image;
