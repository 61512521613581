/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum _ERPItemOrdering {
  _id_asc = "_id_asc",
  _id_desc = "_id_desc",
  active_asc = "active_asc",
  active_desc = "active_desc",
  coreassort_asc = "coreassort_asc",
  coreassort_desc = "coreassort_desc",
  ean_asc = "ean_asc",
  ean_desc = "ean_desc",
  erpItemId_asc = "erpItemId_asc",
  erpItemId_desc = "erpItemId_desc",
  erpPartNumberSearch_asc = "erpPartNumberSearch_asc",
  erpPartNumberSearch_desc = "erpPartNumberSearch_desc",
  erpPartNumber_asc = "erpPartNumber_asc",
  erpPartNumber_desc = "erpPartNumber_desc",
  erpSystem_asc = "erpSystem_asc",
  erpSystem_desc = "erpSystem_desc",
  hsCode_asc = "hsCode_asc",
  hsCode_desc = "hsCode_desc",
  nominalQuantity_asc = "nominalQuantity_asc",
  nominalQuantity_desc = "nominalQuantity_desc",
  old_ERPItemNumber_Econ_asc = "old_ERPItemNumber_Econ_asc",
  old_ERPItemNumber_Econ_desc = "old_ERPItemNumber_Econ_desc",
  openListPrice_asc = "openListPrice_asc",
  openListPrice_desc = "openListPrice_desc",
  packagingQuantity_asc = "packagingQuantity_asc",
  packagingQuantity_desc = "packagingQuantity_desc",
  partNumber_asc = "partNumber_asc",
  partNumber_desc = "partNumber_desc",
  priceCurrency_asc = "priceCurrency_asc",
  priceCurrency_desc = "priceCurrency_desc",
  quantityMeasure_asc = "quantityMeasure_asc",
  quantityMeasure_desc = "quantityMeasure_desc",
  quantityMultiple_asc = "quantityMultiple_asc",
  quantityMultiple_desc = "quantityMultiple_desc",
  sellable_asc = "sellable_asc",
  sellable_desc = "sellable_desc",
  shopId_asc = "shopId_asc",
  shopId_desc = "shopId_desc",
  source_asc = "source_asc",
  source_desc = "source_desc",
  stock_asc = "stock_asc",
  stock_desc = "stock_desc",
}

export interface _ACCESSORYDirectionsFilter {
  from?: _ACCESSORYFilter | null;
  to?: _ACCESSORYFilter | null;
}

export interface _ACCESSORYFilter {
  AND?: _ACCESSORYFilter[] | null;
  OR?: _ACCESSORYFilter[] | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  Item?: _ItemFilter | null;
}

export interface _BrandFilter {
  AND?: _BrandFilter[] | null;
  OR?: _BrandFilter[] | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_regexp?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  is_brand_for?: _ProductFilter | null;
  is_brand_for_not?: _ProductFilter | null;
  is_brand_for_in?: _ProductFilter[] | null;
  is_brand_for_not_in?: _ProductFilter[] | null;
  is_brand_for_some?: _ProductFilter | null;
  is_brand_for_none?: _ProductFilter | null;
  is_brand_for_single?: _ProductFilter | null;
  is_brand_for_every?: _ProductFilter | null;
}

export interface _CROSS_SELLDirectionsFilter {
  from?: _CROSS_SELLFilter | null;
  to?: _CROSS_SELLFilter | null;
}

export interface _CROSS_SELLFilter {
  AND?: _CROSS_SELLFilter[] | null;
  OR?: _CROSS_SELLFilter[] | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  Item?: _ItemFilter | null;
}

export interface _ContactFilter {
  AND?: _ContactFilter[] | null;
  OR?: _ContactFilter[] | null;
  contactEmail?: string | null;
  contactEmail_not?: string | null;
  contactEmail_in?: string[] | null;
  contactEmail_not_in?: string[] | null;
  contactEmail_regexp?: string | null;
  contactEmail_contains?: string | null;
  contactEmail_not_contains?: string | null;
  contactEmail_starts_with?: string | null;
  contactEmail_not_starts_with?: string | null;
  contactEmail_ends_with?: string | null;
  contactEmail_not_ends_with?: string | null;
  contactId?: string | null;
  contactId_not?: string | null;
  contactId_in?: string[] | null;
  contactId_not_in?: string[] | null;
  contactId_regexp?: string | null;
  contactId_contains?: string | null;
  contactId_not_contains?: string | null;
  contactId_starts_with?: string | null;
  contactId_not_starts_with?: string | null;
  contactId_ends_with?: string | null;
  contactId_not_ends_with?: string | null;
  contactTel?: string | null;
  contactTel_not?: string | null;
  contactTel_in?: string[] | null;
  contactTel_not_in?: string[] | null;
  contactTel_regexp?: string | null;
  contactTel_contains?: string | null;
  contactTel_not_contains?: string | null;
  contactTel_starts_with?: string | null;
  contactTel_not_starts_with?: string | null;
  contactTel_ends_with?: string | null;
  contactTel_not_ends_with?: string | null;
  erpSystem?: string | null;
  erpSystem_not?: string | null;
  erpSystem_in?: string[] | null;
  erpSystem_not_in?: string[] | null;
  erpSystem_regexp?: string | null;
  erpSystem_contains?: string | null;
  erpSystem_not_contains?: string | null;
  erpSystem_starts_with?: string | null;
  erpSystem_not_starts_with?: string | null;
  erpSystem_ends_with?: string | null;
  erpSystem_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_regexp?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  shopId?: number | null;
  shopId_not?: number | null;
  shopId_in?: number[] | null;
  shopId_not_in?: number[] | null;
  shopId_lt?: number | null;
  shopId_lte?: number | null;
  shopId_gt?: number | null;
  shopId_gte?: number | null;
  sip?: string | null;
  sip_not?: string | null;
  sip_in?: string[] | null;
  sip_not_in?: string[] | null;
  sip_regexp?: string | null;
  sip_contains?: string | null;
  sip_not_contains?: string | null;
  sip_starts_with?: string | null;
  sip_not_starts_with?: string | null;
  sip_ends_with?: string | null;
  sip_not_ends_with?: string | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  structureGroupIdentifier?: string | null;
  structureGroupIdentifier_not?: string | null;
  structureGroupIdentifier_in?: string[] | null;
  structureGroupIdentifier_not_in?: string[] | null;
  structureGroupIdentifier_regexp?: string | null;
  structureGroupIdentifier_contains?: string | null;
  structureGroupIdentifier_not_contains?: string | null;
  structureGroupIdentifier_starts_with?: string | null;
  structureGroupIdentifier_not_starts_with?: string | null;
  structureGroupIdentifier_ends_with?: string | null;
  structureGroupIdentifier_not_ends_with?: string | null;
  items?: _ItemFilter | null;
  items_not?: _ItemFilter | null;
  items_in?: _ItemFilter[] | null;
  items_not_in?: _ItemFilter[] | null;
  items_some?: _ItemFilter | null;
  items_none?: _ItemFilter | null;
  items_single?: _ItemFilter | null;
  items_every?: _ItemFilter | null;
}

export interface _CustomerFilter {
  AND?: _CustomerFilter[] | null;
  OR?: _CustomerFilter[] | null;
  companyCountryCode?: string | null;
  companyCountryCode_not?: string | null;
  companyCountryCode_in?: string[] | null;
  companyCountryCode_not_in?: string[] | null;
  companyCountryCode_regexp?: string | null;
  companyCountryCode_contains?: string | null;
  companyCountryCode_not_contains?: string | null;
  companyCountryCode_starts_with?: string | null;
  companyCountryCode_not_starts_with?: string | null;
  companyCountryCode_ends_with?: string | null;
  companyCountryCode_not_ends_with?: string | null;
  customerCity?: string | null;
  customerCity_not?: string | null;
  customerCity_in?: string[] | null;
  customerCity_not_in?: string[] | null;
  customerCity_regexp?: string | null;
  customerCity_contains?: string | null;
  customerCity_not_contains?: string | null;
  customerCity_starts_with?: string | null;
  customerCity_not_starts_with?: string | null;
  customerCity_ends_with?: string | null;
  customerCity_not_ends_with?: string | null;
  customerCountryCode?: string | null;
  customerCountryCode_not?: string | null;
  customerCountryCode_in?: string[] | null;
  customerCountryCode_not_in?: string[] | null;
  customerCountryCode_regexp?: string | null;
  customerCountryCode_contains?: string | null;
  customerCountryCode_not_contains?: string | null;
  customerCountryCode_starts_with?: string | null;
  customerCountryCode_not_starts_with?: string | null;
  customerCountryCode_ends_with?: string | null;
  customerCountryCode_not_ends_with?: string | null;
  customerId?: string | null;
  customerId_not?: string | null;
  customerId_in?: string[] | null;
  customerId_not_in?: string[] | null;
  customerId_regexp?: string | null;
  customerId_contains?: string | null;
  customerId_not_contains?: string | null;
  customerId_starts_with?: string | null;
  customerId_not_starts_with?: string | null;
  customerId_ends_with?: string | null;
  customerId_not_ends_with?: string | null;
  customerName?: string | null;
  customerName_not?: string | null;
  customerName_in?: string[] | null;
  customerName_not_in?: string[] | null;
  customerName_regexp?: string | null;
  customerName_contains?: string | null;
  customerName_not_contains?: string | null;
  customerName_starts_with?: string | null;
  customerName_not_starts_with?: string | null;
  customerName_ends_with?: string | null;
  customerName_not_ends_with?: string | null;
  customerName2?: string | null;
  customerName2_not?: string | null;
  customerName2_in?: string[] | null;
  customerName2_not_in?: string[] | null;
  customerName2_regexp?: string | null;
  customerName2_contains?: string | null;
  customerName2_not_contains?: string | null;
  customerName2_starts_with?: string | null;
  customerName2_not_starts_with?: string | null;
  customerName2_ends_with?: string | null;
  customerName2_not_ends_with?: string | null;
  customerNo?: string | null;
  customerNo_not?: string | null;
  customerNo_in?: string[] | null;
  customerNo_not_in?: string[] | null;
  customerNo_regexp?: string | null;
  customerNo_contains?: string | null;
  customerNo_not_contains?: string | null;
  customerNo_starts_with?: string | null;
  customerNo_not_starts_with?: string | null;
  customerNo_ends_with?: string | null;
  customerNo_not_ends_with?: string | null;
  econostoCode?: string | null;
  econostoCode_not?: string | null;
  econostoCode_in?: string[] | null;
  econostoCode_not_in?: string[] | null;
  econostoCode_regexp?: string | null;
  econostoCode_contains?: string | null;
  econostoCode_not_contains?: string | null;
  econostoCode_starts_with?: string | null;
  econostoCode_not_starts_with?: string | null;
  econostoCode_ends_with?: string | null;
  econostoCode_not_ends_with?: string | null;
  has_customermaterial_c?: _CustomerMaterialFilter | null;
  has_customermaterial_c_not?: _CustomerMaterialFilter | null;
  has_customermaterial_c_in?: _CustomerMaterialFilter[] | null;
  has_customermaterial_c_not_in?: _CustomerMaterialFilter[] | null;
  has_customermaterial_c_some?: _CustomerMaterialFilter | null;
  has_customermaterial_c_none?: _CustomerMaterialFilter | null;
  has_customermaterial_c_single?: _CustomerMaterialFilter | null;
  has_customermaterial_c_every?: _CustomerMaterialFilter | null;
}

export interface _CustomerMaterialFilter {
  AND?: _CustomerMaterialFilter[] | null;
  OR?: _CustomerMaterialFilter[] | null;
  customerMaterialId?: string | null;
  customerMaterialId_not?: string | null;
  customerMaterialId_in?: string[] | null;
  customerMaterialId_not_in?: string[] | null;
  customerMaterialId_regexp?: string | null;
  customerMaterialId_contains?: string | null;
  customerMaterialId_not_contains?: string | null;
  customerMaterialId_starts_with?: string | null;
  customerMaterialId_not_starts_with?: string | null;
  customerMaterialId_ends_with?: string | null;
  customerMaterialId_not_ends_with?: string | null;
  customerMaterialNo?: string | null;
  customerMaterialNo_not?: string | null;
  customerMaterialNo_in?: string[] | null;
  customerMaterialNo_not_in?: string[] | null;
  customerMaterialNo_regexp?: string | null;
  customerMaterialNo_contains?: string | null;
  customerMaterialNo_not_contains?: string | null;
  customerMaterialNo_starts_with?: string | null;
  customerMaterialNo_not_starts_with?: string | null;
  customerMaterialNo_ends_with?: string | null;
  customerMaterialNo_not_ends_with?: string | null;
  customers?: _CustomerFilter | null;
  customers_not?: _CustomerFilter | null;
  customers_in?: _CustomerFilter[] | null;
  customers_not_in?: _CustomerFilter[] | null;
  customers_some?: _CustomerFilter | null;
  customers_none?: _CustomerFilter | null;
  customers_single?: _CustomerFilter | null;
  customers_every?: _CustomerFilter | null;
  erpitems?: _ERPItemFilter | null;
  erpitems_not?: _ERPItemFilter | null;
  erpitems_in?: _ERPItemFilter[] | null;
  erpitems_not_in?: _ERPItemFilter[] | null;
  erpitems_some?: _ERPItemFilter | null;
  erpitems_none?: _ERPItemFilter | null;
  erpitems_single?: _ERPItemFilter | null;
  erpitems_every?: _ERPItemFilter | null;
}

export interface _DescriptionFilter {
  AND?: _DescriptionFilter[] | null;
  OR?: _DescriptionFilter[] | null;
  Application?: string | null;
  Application_not?: string | null;
  Application_in?: string[] | null;
  Application_not_in?: string[] | null;
  Application_regexp?: string | null;
  Application_contains?: string | null;
  Application_not_contains?: string | null;
  Application_starts_with?: string | null;
  Application_not_starts_with?: string | null;
  Application_ends_with?: string | null;
  Application_not_ends_with?: string | null;
  Approval?: string | null;
  Approval_not?: string | null;
  Approval_in?: string[] | null;
  Approval_not_in?: string[] | null;
  Approval_regexp?: string | null;
  Approval_contains?: string | null;
  Approval_not_contains?: string | null;
  Approval_starts_with?: string | null;
  Approval_not_starts_with?: string | null;
  Approval_ends_with?: string | null;
  Approval_not_ends_with?: string | null;
  Construction?: string | null;
  Construction_not?: string | null;
  Construction_in?: string[] | null;
  Construction_not_in?: string[] | null;
  Construction_regexp?: string | null;
  Construction_contains?: string | null;
  Construction_not_contains?: string | null;
  Construction_starts_with?: string | null;
  Construction_not_starts_with?: string | null;
  Construction_ends_with?: string | null;
  Construction_not_ends_with?: string | null;
  Execution?: string | null;
  Execution_not?: string | null;
  Execution_in?: string[] | null;
  Execution_not_in?: string[] | null;
  Execution_regexp?: string | null;
  Execution_contains?: string | null;
  Execution_not_contains?: string | null;
  Execution_starts_with?: string | null;
  Execution_not_starts_with?: string | null;
  Execution_ends_with?: string | null;
  Execution_not_ends_with?: string | null;
  OperatingPrinciple?: string | null;
  OperatingPrinciple_not?: string | null;
  OperatingPrinciple_in?: string[] | null;
  OperatingPrinciple_not_in?: string[] | null;
  OperatingPrinciple_regexp?: string | null;
  OperatingPrinciple_contains?: string | null;
  OperatingPrinciple_not_contains?: string | null;
  OperatingPrinciple_starts_with?: string | null;
  OperatingPrinciple_not_starts_with?: string | null;
  OperatingPrinciple_ends_with?: string | null;
  OperatingPrinciple_not_ends_with?: string | null;
  Options?: string | null;
  Options_not?: string | null;
  Options_in?: string[] | null;
  Options_not_in?: string[] | null;
  Options_regexp?: string | null;
  Options_contains?: string | null;
  Options_not_contains?: string | null;
  Options_starts_with?: string | null;
  Options_not_starts_with?: string | null;
  Options_ends_with?: string | null;
  Options_not_ends_with?: string | null;
  OtherRemarks?: string | null;
  OtherRemarks_not?: string | null;
  OtherRemarks_in?: string[] | null;
  OtherRemarks_not_in?: string[] | null;
  OtherRemarks_regexp?: string | null;
  OtherRemarks_contains?: string | null;
  OtherRemarks_not_contains?: string | null;
  OtherRemarks_starts_with?: string | null;
  OtherRemarks_not_starts_with?: string | null;
  OtherRemarks_ends_with?: string | null;
  OtherRemarks_not_ends_with?: string | null;
  SelectionGuideline?: string | null;
  SelectionGuideline_not?: string | null;
  SelectionGuideline_in?: string[] | null;
  SelectionGuideline_not_in?: string[] | null;
  SelectionGuideline_regexp?: string | null;
  SelectionGuideline_contains?: string | null;
  SelectionGuideline_not_contains?: string | null;
  SelectionGuideline_starts_with?: string | null;
  SelectionGuideline_not_starts_with?: string | null;
  SelectionGuideline_ends_with?: string | null;
  SelectionGuideline_not_ends_with?: string | null;
  Tables?: string | null;
  Tables_not?: string | null;
  Tables_in?: string[] | null;
  Tables_not_in?: string[] | null;
  Tables_regexp?: string | null;
  Tables_contains?: string | null;
  Tables_not_contains?: string | null;
  Tables_starts_with?: string | null;
  Tables_not_starts_with?: string | null;
  Tables_ends_with?: string | null;
  Tables_not_ends_with?: string | null;
  TechnicalInformation?: string | null;
  TechnicalInformation_not?: string | null;
  TechnicalInformation_in?: string[] | null;
  TechnicalInformation_not_in?: string[] | null;
  TechnicalInformation_regexp?: string | null;
  TechnicalInformation_contains?: string | null;
  TechnicalInformation_not_contains?: string | null;
  TechnicalInformation_starts_with?: string | null;
  TechnicalInformation_not_starts_with?: string | null;
  TechnicalInformation_ends_with?: string | null;
  TechnicalInformation_not_ends_with?: string | null;
  ancestors?: string | null;
  ancestors_not?: string | null;
  ancestors_in?: string[] | null;
  ancestors_not_in?: string[] | null;
  ancestors_regexp?: string | null;
  ancestors_contains?: string | null;
  ancestors_not_contains?: string | null;
  ancestors_starts_with?: string | null;
  ancestors_not_starts_with?: string | null;
  ancestors_ends_with?: string | null;
  ancestors_not_ends_with?: string | null;
  available?: string | null;
  available_not?: string | null;
  available_in?: string[] | null;
  available_not_in?: string[] | null;
  available_regexp?: string | null;
  available_contains?: string | null;
  available_not_contains?: string | null;
  available_starts_with?: string | null;
  available_not_starts_with?: string | null;
  available_ends_with?: string | null;
  available_not_ends_with?: string | null;
  fullImage?: string | null;
  fullImage_not?: string | null;
  fullImage_in?: string[] | null;
  fullImage_not_in?: string[] | null;
  fullImage_regexp?: string | null;
  fullImage_contains?: string | null;
  fullImage_not_contains?: string | null;
  fullImage_starts_with?: string | null;
  fullImage_not_starts_with?: string | null;
  fullImage_ends_with?: string | null;
  fullImage_not_ends_with?: string | null;
  identifier?: string | null;
  identifier_not?: string | null;
  identifier_in?: string[] | null;
  identifier_not_in?: string[] | null;
  identifier_regexp?: string | null;
  identifier_contains?: string | null;
  identifier_not_contains?: string | null;
  identifier_starts_with?: string | null;
  identifier_not_starts_with?: string | null;
  identifier_ends_with?: string | null;
  identifier_not_ends_with?: string | null;
  keyword?: string | null;
  keyword_not?: string | null;
  keyword_in?: string[] | null;
  keyword_not_in?: string[] | null;
  keyword_regexp?: string | null;
  keyword_contains?: string | null;
  keyword_not_contains?: string | null;
  keyword_starts_with?: string | null;
  keyword_not_starts_with?: string | null;
  keyword_ends_with?: string | null;
  keyword_not_ends_with?: string | null;
  languageCode?: string | null;
  languageCode_not?: string | null;
  languageCode_in?: string[] | null;
  languageCode_not_in?: string[] | null;
  languageCode_regexp?: string | null;
  languageCode_contains?: string | null;
  languageCode_not_contains?: string | null;
  languageCode_starts_with?: string | null;
  languageCode_not_starts_with?: string | null;
  languageCode_ends_with?: string | null;
  languageCode_not_ends_with?: string | null;
  longDescription?: string | null;
  longDescription_not?: string | null;
  longDescription_in?: string[] | null;
  longDescription_not_in?: string[] | null;
  longDescription_regexp?: string | null;
  longDescription_contains?: string | null;
  longDescription_not_contains?: string | null;
  longDescription_starts_with?: string | null;
  longDescription_not_starts_with?: string | null;
  longDescription_ends_with?: string | null;
  longDescription_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_regexp?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  published?: string | null;
  published_not?: string | null;
  published_in?: string[] | null;
  published_not_in?: string[] | null;
  published_regexp?: string | null;
  published_contains?: string | null;
  published_not_contains?: string | null;
  published_starts_with?: string | null;
  published_not_starts_with?: string | null;
  published_ends_with?: string | null;
  published_not_ends_with?: string | null;
  richContentLongDescription?: string | null;
  richContentLongDescription_not?: string | null;
  richContentLongDescription_in?: string[] | null;
  richContentLongDescription_not_in?: string[] | null;
  richContentLongDescription_regexp?: string | null;
  richContentLongDescription_contains?: string | null;
  richContentLongDescription_not_contains?: string | null;
  richContentLongDescription_starts_with?: string | null;
  richContentLongDescription_not_starts_with?: string | null;
  richContentLongDescription_ends_with?: string | null;
  richContentLongDescription_not_ends_with?: string | null;
  shortDescription?: string | null;
  shortDescription_not?: string | null;
  shortDescription_in?: string[] | null;
  shortDescription_not_in?: string[] | null;
  shortDescription_regexp?: string | null;
  shortDescription_contains?: string | null;
  shortDescription_not_contains?: string | null;
  shortDescription_starts_with?: string | null;
  shortDescription_not_starts_with?: string | null;
  shortDescription_ends_with?: string | null;
  shortDescription_not_ends_with?: string | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  featurecategorys?: _FeatureCategoryFilter | null;
  featurecategorys_not?: _FeatureCategoryFilter | null;
  featurecategorys_in?: _FeatureCategoryFilter[] | null;
  featurecategorys_not_in?: _FeatureCategoryFilter[] | null;
  featurecategorys_some?: _FeatureCategoryFilter | null;
  featurecategorys_none?: _FeatureCategoryFilter | null;
  featurecategorys_single?: _FeatureCategoryFilter | null;
  featurecategorys_every?: _FeatureCategoryFilter | null;
  productcategorys?: _ProductCategoryFilter | null;
  productcategorys_not?: _ProductCategoryFilter | null;
  productcategorys_in?: _ProductCategoryFilter[] | null;
  productcategorys_not_in?: _ProductCategoryFilter[] | null;
  productcategorys_some?: _ProductCategoryFilter | null;
  productcategorys_none?: _ProductCategoryFilter | null;
  productcategorys_single?: _ProductCategoryFilter | null;
  productcategorys_every?: _ProductCategoryFilter | null;
  productgroups?: _ProductGroupFilter | null;
  productgroups_not?: _ProductGroupFilter | null;
  productgroups_in?: _ProductGroupFilter[] | null;
  productgroups_not_in?: _ProductGroupFilter[] | null;
  productgroups_some?: _ProductGroupFilter | null;
  productgroups_none?: _ProductGroupFilter | null;
  productgroups_single?: _ProductGroupFilter | null;
  productgroups_every?: _ProductGroupFilter | null;
  products?: _ProductFilter | null;
  products_not?: _ProductFilter | null;
  products_in?: _ProductFilter[] | null;
  products_not_in?: _ProductFilter[] | null;
  products_some?: _ProductFilter | null;
  products_none?: _ProductFilter | null;
  products_single?: _ProductFilter | null;
  products_every?: _ProductFilter | null;
  items?: _ItemFilter | null;
  items_not?: _ItemFilter | null;
  items_in?: _ItemFilter[] | null;
  items_not_in?: _ItemFilter[] | null;
  items_some?: _ItemFilter | null;
  items_none?: _ItemFilter | null;
  items_single?: _ItemFilter | null;
  items_every?: _ItemFilter | null;
}

export interface _DocumentFilter {
  AND?: _DocumentFilter[] | null;
  OR?: _DocumentFilter[] | null;
  atchastPath?: string | null;
  atchastPath_not?: string | null;
  atchastPath_in?: string[] | null;
  atchastPath_not_in?: string[] | null;
  atchastPath_regexp?: string | null;
  atchastPath_contains?: string | null;
  atchastPath_not_contains?: string | null;
  atchastPath_starts_with?: string | null;
  atchastPath_not_starts_with?: string | null;
  atchastPath_ends_with?: string | null;
  atchastPath_not_ends_with?: string | null;
  attachusg_id?: string | null;
  attachusg_id_not?: string | null;
  attachusg_id_in?: string[] | null;
  attachusg_id_not_in?: string[] | null;
  attachusg_id_regexp?: string | null;
  attachusg_id_contains?: string | null;
  attachusg_id_not_contains?: string | null;
  attachusg_id_starts_with?: string | null;
  attachusg_id_not_starts_with?: string | null;
  attachusg_id_ends_with?: string | null;
  attachusg_id_not_ends_with?: string | null;
  directoryPath?: string | null;
  directoryPath_not?: string | null;
  directoryPath_in?: string[] | null;
  directoryPath_not_in?: string[] | null;
  directoryPath_regexp?: string | null;
  directoryPath_contains?: string | null;
  directoryPath_not_contains?: string | null;
  directoryPath_starts_with?: string | null;
  directoryPath_not_starts_with?: string | null;
  directoryPath_ends_with?: string | null;
  directoryPath_not_ends_with?: string | null;
  docID?: string | null;
  docID_not?: string | null;
  docID_in?: string[] | null;
  docID_not_in?: string[] | null;
  docID_regexp?: string | null;
  docID_contains?: string | null;
  docID_not_contains?: string | null;
  docID_starts_with?: string | null;
  docID_not_starts_with?: string | null;
  docID_ends_with?: string | null;
  docID_not_ends_with?: string | null;
  erpSystem?: string | null;
  erpSystem_not?: string | null;
  erpSystem_in?: string[] | null;
  erpSystem_not_in?: string[] | null;
  erpSystem_regexp?: string | null;
  erpSystem_contains?: string | null;
  erpSystem_not_contains?: string | null;
  erpSystem_starts_with?: string | null;
  erpSystem_not_starts_with?: string | null;
  erpSystem_ends_with?: string | null;
  erpSystem_not_ends_with?: string | null;
  languageCode?: string | null;
  languageCode_not?: string | null;
  languageCode_in?: string[] | null;
  languageCode_not_in?: string[] | null;
  languageCode_regexp?: string | null;
  languageCode_contains?: string | null;
  languageCode_not_contains?: string | null;
  languageCode_starts_with?: string | null;
  languageCode_not_starts_with?: string | null;
  languageCode_ends_with?: string | null;
  languageCode_not_ends_with?: string | null;
  mediaType?: string | null;
  mediaType_not?: string | null;
  mediaType_in?: string[] | null;
  mediaType_not_in?: string[] | null;
  mediaType_regexp?: string | null;
  mediaType_contains?: string | null;
  mediaType_not_contains?: string | null;
  mediaType_starts_with?: string | null;
  mediaType_not_starts_with?: string | null;
  mediaType_ends_with?: string | null;
  mediaType_not_ends_with?: string | null;
  mimeType?: string | null;
  mimeType_not?: string | null;
  mimeType_in?: string[] | null;
  mimeType_not_in?: string[] | null;
  mimeType_regexp?: string | null;
  mimeType_contains?: string | null;
  mimeType_not_contains?: string | null;
  mimeType_starts_with?: string | null;
  mimeType_not_starts_with?: string | null;
  mimeType_ends_with?: string | null;
  mimeType_not_ends_with?: string | null;
  shortDescription?: string | null;
  shortDescription_not?: string | null;
  shortDescription_in?: string[] | null;
  shortDescription_not_in?: string[] | null;
  shortDescription_regexp?: string | null;
  shortDescription_contains?: string | null;
  shortDescription_not_contains?: string | null;
  shortDescription_starts_with?: string | null;
  shortDescription_not_starts_with?: string | null;
  shortDescription_ends_with?: string | null;
  shortDescription_not_ends_with?: string | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  items?: _ItemFilter | null;
  items_not?: _ItemFilter | null;
  items_in?: _ItemFilter[] | null;
  items_not_in?: _ItemFilter[] | null;
  items_some?: _ItemFilter | null;
  items_none?: _ItemFilter | null;
  items_single?: _ItemFilter | null;
  items_every?: _ItemFilter | null;
  products?: _ProductFilter | null;
  products_not?: _ProductFilter | null;
  products_in?: _ProductFilter[] | null;
  products_not_in?: _ProductFilter[] | null;
  products_some?: _ProductFilter | null;
  products_none?: _ProductFilter | null;
  products_single?: _ProductFilter | null;
  products_every?: _ProductFilter | null;
}

export interface _ERPItemFilter {
  AND?: _ERPItemFilter[] | null;
  OR?: _ERPItemFilter[] | null;
  active?: number | null;
  active_not?: number | null;
  active_in?: number[] | null;
  active_not_in?: number[] | null;
  active_lt?: number | null;
  active_lte?: number | null;
  active_gt?: number | null;
  active_gte?: number | null;
  coreassort?: number | null;
  coreassort_not?: number | null;
  coreassort_in?: number[] | null;
  coreassort_not_in?: number[] | null;
  coreassort_lt?: number | null;
  coreassort_lte?: number | null;
  coreassort_gt?: number | null;
  coreassort_gte?: number | null;
  ean?: string | null;
  ean_not?: string | null;
  ean_in?: string[] | null;
  ean_not_in?: string[] | null;
  ean_regexp?: string | null;
  ean_contains?: string | null;
  ean_not_contains?: string | null;
  ean_starts_with?: string | null;
  ean_not_starts_with?: string | null;
  ean_ends_with?: string | null;
  ean_not_ends_with?: string | null;
  erpItemId?: string | null;
  erpItemId_not?: string | null;
  erpItemId_in?: string[] | null;
  erpItemId_not_in?: string[] | null;
  erpItemId_regexp?: string | null;
  erpItemId_contains?: string | null;
  erpItemId_not_contains?: string | null;
  erpItemId_starts_with?: string | null;
  erpItemId_not_starts_with?: string | null;
  erpItemId_ends_with?: string | null;
  erpItemId_not_ends_with?: string | null;
  erpPartNumber?: string | null;
  erpPartNumber_not?: string | null;
  erpPartNumber_in?: string[] | null;
  erpPartNumber_not_in?: string[] | null;
  erpPartNumber_regexp?: string | null;
  erpPartNumber_contains?: string | null;
  erpPartNumber_not_contains?: string | null;
  erpPartNumber_starts_with?: string | null;
  erpPartNumber_not_starts_with?: string | null;
  erpPartNumber_ends_with?: string | null;
  erpPartNumber_not_ends_with?: string | null;
  erpPartNumberSearch?: string | null;
  erpPartNumberSearch_not?: string | null;
  erpPartNumberSearch_in?: string[] | null;
  erpPartNumberSearch_not_in?: string[] | null;
  erpPartNumberSearch_regexp?: string | null;
  erpPartNumberSearch_contains?: string | null;
  erpPartNumberSearch_not_contains?: string | null;
  erpPartNumberSearch_starts_with?: string | null;
  erpPartNumberSearch_not_starts_with?: string | null;
  erpPartNumberSearch_ends_with?: string | null;
  erpPartNumberSearch_not_ends_with?: string | null;
  erpSystem?: string | null;
  erpSystem_not?: string | null;
  erpSystem_in?: string[] | null;
  erpSystem_not_in?: string[] | null;
  erpSystem_regexp?: string | null;
  erpSystem_contains?: string | null;
  erpSystem_not_contains?: string | null;
  erpSystem_starts_with?: string | null;
  erpSystem_not_starts_with?: string | null;
  erpSystem_ends_with?: string | null;
  erpSystem_not_ends_with?: string | null;
  hsCode?: string | null;
  hsCode_not?: string | null;
  hsCode_in?: string[] | null;
  hsCode_not_in?: string[] | null;
  hsCode_regexp?: string | null;
  hsCode_contains?: string | null;
  hsCode_not_contains?: string | null;
  hsCode_starts_with?: string | null;
  hsCode_not_starts_with?: string | null;
  hsCode_ends_with?: string | null;
  hsCode_not_ends_with?: string | null;
  nominalQuantity?: number | null;
  nominalQuantity_not?: number | null;
  nominalQuantity_in?: number[] | null;
  nominalQuantity_not_in?: number[] | null;
  nominalQuantity_lt?: number | null;
  nominalQuantity_lte?: number | null;
  nominalQuantity_gt?: number | null;
  nominalQuantity_gte?: number | null;
  old_ERPItemNumber_Econ?: string | null;
  old_ERPItemNumber_Econ_not?: string | null;
  old_ERPItemNumber_Econ_in?: string[] | null;
  old_ERPItemNumber_Econ_not_in?: string[] | null;
  old_ERPItemNumber_Econ_regexp?: string | null;
  old_ERPItemNumber_Econ_contains?: string | null;
  old_ERPItemNumber_Econ_not_contains?: string | null;
  old_ERPItemNumber_Econ_starts_with?: string | null;
  old_ERPItemNumber_Econ_not_starts_with?: string | null;
  old_ERPItemNumber_Econ_ends_with?: string | null;
  old_ERPItemNumber_Econ_not_ends_with?: string | null;
  openListPrice?: string | null;
  openListPrice_not?: string | null;
  openListPrice_in?: string[] | null;
  openListPrice_not_in?: string[] | null;
  openListPrice_regexp?: string | null;
  openListPrice_contains?: string | null;
  openListPrice_not_contains?: string | null;
  openListPrice_starts_with?: string | null;
  openListPrice_not_starts_with?: string | null;
  openListPrice_ends_with?: string | null;
  openListPrice_not_ends_with?: string | null;
  packagingQuantity?: number | null;
  packagingQuantity_not?: number | null;
  packagingQuantity_in?: number[] | null;
  packagingQuantity_not_in?: number[] | null;
  packagingQuantity_lt?: number | null;
  packagingQuantity_lte?: number | null;
  packagingQuantity_gt?: number | null;
  packagingQuantity_gte?: number | null;
  partNumber?: string | null;
  partNumber_not?: string | null;
  partNumber_in?: string[] | null;
  partNumber_not_in?: string[] | null;
  partNumber_regexp?: string | null;
  partNumber_contains?: string | null;
  partNumber_not_contains?: string | null;
  partNumber_starts_with?: string | null;
  partNumber_not_starts_with?: string | null;
  partNumber_ends_with?: string | null;
  partNumber_not_ends_with?: string | null;
  priceCurrency?: string | null;
  priceCurrency_not?: string | null;
  priceCurrency_in?: string[] | null;
  priceCurrency_not_in?: string[] | null;
  priceCurrency_regexp?: string | null;
  priceCurrency_contains?: string | null;
  priceCurrency_not_contains?: string | null;
  priceCurrency_starts_with?: string | null;
  priceCurrency_not_starts_with?: string | null;
  priceCurrency_ends_with?: string | null;
  priceCurrency_not_ends_with?: string | null;
  quantityMeasure?: string | null;
  quantityMeasure_not?: string | null;
  quantityMeasure_in?: string[] | null;
  quantityMeasure_not_in?: string[] | null;
  quantityMeasure_regexp?: string | null;
  quantityMeasure_contains?: string | null;
  quantityMeasure_not_contains?: string | null;
  quantityMeasure_starts_with?: string | null;
  quantityMeasure_not_starts_with?: string | null;
  quantityMeasure_ends_with?: string | null;
  quantityMeasure_not_ends_with?: string | null;
  quantityMultiple?: number | null;
  quantityMultiple_not?: number | null;
  quantityMultiple_in?: number[] | null;
  quantityMultiple_not_in?: number[] | null;
  quantityMultiple_lt?: number | null;
  quantityMultiple_lte?: number | null;
  quantityMultiple_gt?: number | null;
  quantityMultiple_gte?: number | null;
  sellable?: number | null;
  sellable_not?: number | null;
  sellable_in?: number[] | null;
  sellable_not_in?: number[] | null;
  sellable_lt?: number | null;
  sellable_lte?: number | null;
  sellable_gt?: number | null;
  sellable_gte?: number | null;
  shopId?: number | null;
  shopId_not?: number | null;
  shopId_in?: number[] | null;
  shopId_not_in?: number[] | null;
  shopId_lt?: number | null;
  shopId_lte?: number | null;
  shopId_gt?: number | null;
  shopId_gte?: number | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  stock?: string | null;
  stock_not?: string | null;
  stock_in?: string[] | null;
  stock_not_in?: string[] | null;
  stock_regexp?: string | null;
  stock_contains?: string | null;
  stock_not_contains?: string | null;
  stock_starts_with?: string | null;
  stock_not_starts_with?: string | null;
  stock_ends_with?: string | null;
  stock_not_ends_with?: string | null;
  has_customermaterial_erp?: _CustomerMaterialFilter | null;
  has_customermaterial_erp_not?: _CustomerMaterialFilter | null;
  has_customermaterial_erp_in?: _CustomerMaterialFilter[] | null;
  has_customermaterial_erp_not_in?: _CustomerMaterialFilter[] | null;
  has_customermaterial_erp_some?: _CustomerMaterialFilter | null;
  has_customermaterial_erp_none?: _CustomerMaterialFilter | null;
  has_customermaterial_erp_single?: _CustomerMaterialFilter | null;
  has_customermaterial_erp_every?: _CustomerMaterialFilter | null;
  items?: _ItemFilter | null;
  items_not?: _ItemFilter | null;
  items_in?: _ItemFilter[] | null;
  items_not_in?: _ItemFilter[] | null;
  items_some?: _ItemFilter | null;
  items_none?: _ItemFilter | null;
  items_single?: _ItemFilter | null;
  items_every?: _ItemFilter | null;
}

export interface _FeatureCategoryFilter {
  AND?: _FeatureCategoryFilter[] | null;
  OR?: _FeatureCategoryFilter[] | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_regexp?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  sequence?: number | null;
  sequence_not?: number | null;
  sequence_in?: number[] | null;
  sequence_not_in?: number[] | null;
  sequence_lt?: number | null;
  sequence_lte?: number | null;
  sequence_gt?: number | null;
  sequence_gte?: number | null;
  has_description_fc?: _DescriptionFilter | null;
  has_description_fc_not?: _DescriptionFilter | null;
  has_description_fc_in?: _DescriptionFilter[] | null;
  has_description_fc_not_in?: _DescriptionFilter[] | null;
  has_description_fc_some?: _DescriptionFilter | null;
  has_description_fc_none?: _DescriptionFilter | null;
  has_description_fc_single?: _DescriptionFilter | null;
  has_description_fc_every?: _DescriptionFilter | null;
  features?: _FeatureFilter | null;
  features_not?: _FeatureFilter | null;
  features_in?: _FeatureFilter[] | null;
  features_not_in?: _FeatureFilter[] | null;
  features_some?: _FeatureFilter | null;
  features_none?: _FeatureFilter | null;
  features_single?: _FeatureFilter | null;
  features_every?: _FeatureFilter | null;
}

export interface _FeatureFEATURE_CATEGORYFilter {
  AND?: _FeatureFEATURE_CATEGORYFilter[] | null;
  OR?: _FeatureFEATURE_CATEGORYFilter[] | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  FeatureCategory?: _FeatureCategoryFilter | null;
}

export interface _FeatureFilter {
  AND?: _FeatureFilter[] | null;
  OR?: _FeatureFilter[] | null;
  attributeIdentifier?: string | null;
  attributeIdentifier_not?: string | null;
  attributeIdentifier_in?: string[] | null;
  attributeIdentifier_not_in?: string[] | null;
  attributeIdentifier_regexp?: string | null;
  attributeIdentifier_contains?: string | null;
  attributeIdentifier_not_contains?: string | null;
  attributeIdentifier_starts_with?: string | null;
  attributeIdentifier_not_starts_with?: string | null;
  attributeIdentifier_ends_with?: string | null;
  attributeIdentifier_not_ends_with?: string | null;
  attributeIdentifier_Value?: string | null;
  attributeIdentifier_Value_not?: string | null;
  attributeIdentifier_Value_in?: string[] | null;
  attributeIdentifier_Value_not_in?: string[] | null;
  attributeIdentifier_Value_regexp?: string | null;
  attributeIdentifier_Value_contains?: string | null;
  attributeIdentifier_Value_not_contains?: string | null;
  attributeIdentifier_Value_starts_with?: string | null;
  attributeIdentifier_Value_not_starts_with?: string | null;
  attributeIdentifier_Value_ends_with?: string | null;
  attributeIdentifier_Value_not_ends_with?: string | null;
  erpSystems?: string[] | null;
  erpSystems_not?: string[] | null;
  erpSystems_regexp?: string | null;
  erpSystems_contains?: string[] | null;
  erpSystems_not_contains?: string[] | null;
  erpSystems_starts_with?: string[] | null;
  erpSystems_not_starts_with?: string[] | null;
  erpSystems_ends_with?: string[] | null;
  erpSystems_not_ends_with?: string[] | null;
  featureKey?: string | null;
  featureKey_not?: string | null;
  featureKey_in?: string[] | null;
  featureKey_not_in?: string[] | null;
  featureKey_regexp?: string | null;
  featureKey_contains?: string | null;
  featureKey_not_contains?: string | null;
  featureKey_starts_with?: string | null;
  featureKey_not_starts_with?: string | null;
  featureKey_ends_with?: string | null;
  featureKey_not_ends_with?: string | null;
  isFacet?: number | null;
  isFacet_not?: number | null;
  isFacet_in?: number[] | null;
  isFacet_not_in?: number[] | null;
  isFacet_lt?: number | null;
  isFacet_lte?: number | null;
  isFacet_gt?: number | null;
  isFacet_gte?: number | null;
  languageCode?: string | null;
  languageCode_not?: string | null;
  languageCode_in?: string[] | null;
  languageCode_not_in?: string[] | null;
  languageCode_regexp?: string | null;
  languageCode_contains?: string | null;
  languageCode_not_contains?: string | null;
  languageCode_starts_with?: string | null;
  languageCode_not_starts_with?: string | null;
  languageCode_ends_with?: string | null;
  languageCode_not_ends_with?: string | null;
  maxValue?: number | null;
  maxValue_not?: number | null;
  maxValue_in?: number[] | null;
  maxValue_not_in?: number[] | null;
  maxValue_lt?: number | null;
  maxValue_lte?: number | null;
  maxValue_gt?: number | null;
  maxValue_gte?: number | null;
  minValue?: number | null;
  minValue_not?: number | null;
  minValue_in?: number[] | null;
  minValue_not_in?: number[] | null;
  minValue_lt?: number | null;
  minValue_lte?: number | null;
  minValue_gt?: number | null;
  minValue_gte?: number | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_regexp?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  selectorOnly?: number | null;
  selectorOnly_not?: number | null;
  selectorOnly_in?: number[] | null;
  selectorOnly_not_in?: number[] | null;
  selectorOnly_lt?: number | null;
  selectorOnly_lte?: number | null;
  selectorOnly_gt?: number | null;
  selectorOnly_gte?: number | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  type?: string | null;
  type_not?: string | null;
  type_in?: string[] | null;
  type_not_in?: string[] | null;
  type_regexp?: string | null;
  type_contains?: string | null;
  type_not_contains?: string | null;
  type_starts_with?: string | null;
  type_not_starts_with?: string | null;
  type_ends_with?: string | null;
  type_not_ends_with?: string | null;
  unitDescription?: string | null;
  unitDescription_not?: string | null;
  unitDescription_in?: string[] | null;
  unitDescription_not_in?: string[] | null;
  unitDescription_regexp?: string | null;
  unitDescription_contains?: string | null;
  unitDescription_not_contains?: string | null;
  unitDescription_starts_with?: string | null;
  unitDescription_not_starts_with?: string | null;
  unitDescription_ends_with?: string | null;
  unitDescription_not_ends_with?: string | null;
  unitSymbol?: string | null;
  unitSymbol_not?: string | null;
  unitSymbol_in?: string[] | null;
  unitSymbol_not_in?: string[] | null;
  unitSymbol_regexp?: string | null;
  unitSymbol_contains?: string | null;
  unitSymbol_not_contains?: string | null;
  unitSymbol_starts_with?: string | null;
  unitSymbol_not_starts_with?: string | null;
  unitSymbol_ends_with?: string | null;
  unitSymbol_not_ends_with?: string | null;
  value?: string | null;
  value_not?: string | null;
  value_in?: string[] | null;
  value_not_in?: string[] | null;
  value_regexp?: string | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  valueFloat?: number | null;
  valueFloat_not?: number | null;
  valueFloat_in?: number[] | null;
  valueFloat_not_in?: number[] | null;
  valueFloat_lt?: number | null;
  valueFloat_lte?: number | null;
  valueFloat_gt?: number | null;
  valueFloat_gte?: number | null;
  valueInt?: number | null;
  valueInt_not?: number | null;
  valueInt_in?: number[] | null;
  valueInt_not_in?: number[] | null;
  valueInt_lt?: number | null;
  valueInt_lte?: number | null;
  valueInt_gt?: number | null;
  valueInt_gte?: number | null;
  valueString?: string | null;
  valueString_not?: string | null;
  valueString_in?: string[] | null;
  valueString_not_in?: string[] | null;
  valueString_regexp?: string | null;
  valueString_contains?: string | null;
  valueString_not_contains?: string | null;
  valueString_starts_with?: string | null;
  valueString_not_starts_with?: string | null;
  valueString_ends_with?: string | null;
  valueString_not_ends_with?: string | null;
  has_purpose?: _PurposeFilter | null;
  has_purpose_not?: _PurposeFilter | null;
  has_purpose_in?: _PurposeFilter[] | null;
  has_purpose_not_in?: _PurposeFilter[] | null;
  has_purpose_some?: _PurposeFilter | null;
  has_purpose_none?: _PurposeFilter | null;
  has_purpose_single?: _PurposeFilter | null;
  has_purpose_every?: _PurposeFilter | null;
  feature_category?: _FeatureCategoryFilter | null;
  feature_category_not?: _FeatureCategoryFilter | null;
  feature_category_in?: _FeatureCategoryFilter[] | null;
  feature_category_not_in?: _FeatureCategoryFilter[] | null;
  feature_category_some?: _FeatureCategoryFilter | null;
  feature_category_none?: _FeatureCategoryFilter | null;
  feature_category_single?: _FeatureCategoryFilter | null;
  feature_category_every?: _FeatureCategoryFilter | null;
  FEATURE_CATEGORY_rel?: _FeatureFEATURE_CATEGORYFilter | null;
  FEATURE_CATEGORY_rel_not?: _FeatureFEATURE_CATEGORYFilter | null;
  FEATURE_CATEGORY_rel_in?: _FeatureFEATURE_CATEGORYFilter[] | null;
  FEATURE_CATEGORY_rel_not_in?: _FeatureFEATURE_CATEGORYFilter[] | null;
  FEATURE_CATEGORY_rel_some?: _FeatureFEATURE_CATEGORYFilter | null;
  FEATURE_CATEGORY_rel_none?: _FeatureFEATURE_CATEGORYFilter | null;
  FEATURE_CATEGORY_rel_single?: _FeatureFEATURE_CATEGORYFilter | null;
  FEATURE_CATEGORY_rel_every?: _FeatureFEATURE_CATEGORYFilter | null;
  allows_medium?: _MediumFilter | null;
  allows_medium_not?: _MediumFilter | null;
  allows_medium_in?: _MediumFilter[] | null;
  allows_medium_not_in?: _MediumFilter[] | null;
  allows_medium_some?: _MediumFilter | null;
  allows_medium_none?: _MediumFilter | null;
  allows_medium_single?: _MediumFilter | null;
  allows_medium_every?: _MediumFilter | null;
  items?: _ItemFilter | null;
  items_not?: _ItemFilter | null;
  items_in?: _ItemFilter[] | null;
  items_not_in?: _ItemFilter[] | null;
  items_some?: _ItemFilter | null;
  items_none?: _ItemFilter | null;
  items_single?: _ItemFilter | null;
  items_every?: _ItemFilter | null;
}

export interface _HAS_BOMDirectionsFilter {
  from?: _HAS_BOMFilter | null;
  to?: _HAS_BOMFilter | null;
}

export interface _HAS_BOMFilter {
  AND?: _HAS_BOMFilter[] | null;
  OR?: _HAS_BOMFilter[] | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  Item?: _ItemFilter | null;
}

export interface _HAS_SPARE_PARTDirectionsFilter {
  from?: _HAS_SPARE_PARTFilter | null;
  to?: _HAS_SPARE_PARTFilter | null;
}

export interface _HAS_SPARE_PARTFilter {
  AND?: _HAS_SPARE_PARTFilter[] | null;
  OR?: _HAS_SPARE_PARTFilter[] | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  Item?: _ItemFilter | null;
}

export interface _ITEM_FITSDirectionsFilter {
  from?: _ITEM_FITSFilter | null;
  to?: _ITEM_FITSFilter | null;
}

export interface _ITEM_FITSFilter {
  AND?: _ITEM_FITSFilter[] | null;
  OR?: _ITEM_FITSFilter[] | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  Item?: _ItemFilter | null;
}

export interface _ItemDOCUMENT_FOR_ITEMFilter {
  AND?: _ItemDOCUMENT_FOR_ITEMFilter[] | null;
  OR?: _ItemDOCUMENT_FOR_ITEMFilter[] | null;
  attachmentIdentifier?: string | null;
  attachmentIdentifier_not?: string | null;
  attachmentIdentifier_in?: string[] | null;
  attachmentIdentifier_not_in?: string[] | null;
  attachmentIdentifier_regexp?: string | null;
  attachmentIdentifier_contains?: string | null;
  attachmentIdentifier_not_contains?: string | null;
  attachmentIdentifier_starts_with?: string | null;
  attachmentIdentifier_not_starts_with?: string | null;
  attachmentIdentifier_ends_with?: string | null;
  attachmentIdentifier_not_ends_with?: string | null;
  sequence?: number | null;
  sequence_not?: number | null;
  sequence_in?: number[] | null;
  sequence_not_in?: number[] | null;
  sequence_lt?: number | null;
  sequence_lte?: number | null;
  sequence_gt?: number | null;
  sequence_gte?: number | null;
  Document?: _DocumentFilter | null;
}

export interface _ItemFilter {
  AND?: _ItemFilter[] | null;
  OR?: _ItemFilter[] | null;
  UNSPSC?: string | null;
  UNSPSC_not?: string | null;
  UNSPSC_in?: string[] | null;
  UNSPSC_not_in?: string[] | null;
  UNSPSC_regexp?: string | null;
  UNSPSC_contains?: string | null;
  UNSPSC_not_contains?: string | null;
  UNSPSC_starts_with?: string | null;
  UNSPSC_not_starts_with?: string | null;
  UNSPSC_ends_with?: string | null;
  UNSPSC_not_ends_with?: string | null;
  brand?: string | null;
  brand_not?: string | null;
  brand_in?: string[] | null;
  brand_not_in?: string[] | null;
  brand_regexp?: string | null;
  brand_contains?: string | null;
  brand_not_contains?: string | null;
  brand_starts_with?: string | null;
  brand_not_starts_with?: string | null;
  brand_ends_with?: string | null;
  brand_not_ends_with?: string | null;
  commonAssortment?: string | null;
  commonAssortment_not?: string | null;
  commonAssortment_in?: string[] | null;
  commonAssortment_not_in?: string[] | null;
  commonAssortment_regexp?: string | null;
  commonAssortment_contains?: string | null;
  commonAssortment_not_contains?: string | null;
  commonAssortment_starts_with?: string | null;
  commonAssortment_not_starts_with?: string | null;
  commonAssortment_ends_with?: string | null;
  commonAssortment_not_ends_with?: string | null;
  eclass?: string | null;
  eclass_not?: string | null;
  eclass_in?: string[] | null;
  eclass_not_in?: string[] | null;
  eclass_regexp?: string | null;
  eclass_contains?: string | null;
  eclass_not_contains?: string | null;
  eclass_starts_with?: string | null;
  eclass_not_starts_with?: string | null;
  eclass_ends_with?: string | null;
  eclass_not_ends_with?: string | null;
  erpSystems?: string[] | null;
  erpSystems_not?: string[] | null;
  erpSystems_regexp?: string | null;
  erpSystems_contains?: string[] | null;
  erpSystems_not_contains?: string[] | null;
  erpSystems_starts_with?: string[] | null;
  erpSystems_not_starts_with?: string[] | null;
  erpSystems_ends_with?: string[] | null;
  erpSystems_not_ends_with?: string[] | null;
  greenLabel?: number | null;
  greenLabel_not?: number | null;
  greenLabel_in?: number[] | null;
  greenLabel_not_in?: number[] | null;
  greenLabel_lt?: number | null;
  greenLabel_lte?: number | null;
  greenLabel_gt?: number | null;
  greenLabel_gte?: number | null;
  groupIdentifier?: string | null;
  groupIdentifier_not?: string | null;
  groupIdentifier_in?: string[] | null;
  groupIdentifier_not_in?: string[] | null;
  groupIdentifier_regexp?: string | null;
  groupIdentifier_contains?: string | null;
  groupIdentifier_not_contains?: string | null;
  groupIdentifier_starts_with?: string | null;
  groupIdentifier_not_starts_with?: string | null;
  groupIdentifier_ends_with?: string | null;
  groupIdentifier_not_ends_with?: string | null;
  itemAttributes?: string | null;
  itemAttributes_not?: string | null;
  itemAttributes_in?: string[] | null;
  itemAttributes_not_in?: string[] | null;
  itemAttributes_regexp?: string | null;
  itemAttributes_contains?: string | null;
  itemAttributes_not_contains?: string | null;
  itemAttributes_starts_with?: string | null;
  itemAttributes_not_starts_with?: string | null;
  itemAttributes_ends_with?: string | null;
  itemAttributes_not_ends_with?: string | null;
  manufacturerPartNumber?: string | null;
  manufacturerPartNumber_not?: string | null;
  manufacturerPartNumber_in?: string[] | null;
  manufacturerPartNumber_not_in?: string[] | null;
  manufacturerPartNumber_regexp?: string | null;
  manufacturerPartNumber_contains?: string | null;
  manufacturerPartNumber_not_contains?: string | null;
  manufacturerPartNumber_starts_with?: string | null;
  manufacturerPartNumber_not_starts_with?: string | null;
  manufacturerPartNumber_ends_with?: string | null;
  manufacturerPartNumber_not_ends_with?: string | null;
  partNumber?: string | null;
  partNumber_not?: string | null;
  partNumber_in?: string[] | null;
  partNumber_not_in?: string[] | null;
  partNumber_regexp?: string | null;
  partNumber_contains?: string | null;
  partNumber_not_contains?: string | null;
  partNumber_starts_with?: string | null;
  partNumber_not_starts_with?: string | null;
  partNumber_ends_with?: string | null;
  partNumber_not_ends_with?: string | null;
  sequence?: number | null;
  sequence_not?: number | null;
  sequence_in?: number[] | null;
  sequence_not_in?: number[] | null;
  sequence_lt?: number | null;
  sequence_lte?: number | null;
  sequence_gt?: number | null;
  sequence_gte?: number | null;
  series?: string | null;
  series_not?: string | null;
  series_in?: string[] | null;
  series_not_in?: string[] | null;
  series_regexp?: string | null;
  series_contains?: string | null;
  series_not_contains?: string | null;
  series_starts_with?: string | null;
  series_not_starts_with?: string | null;
  series_ends_with?: string | null;
  series_not_ends_with?: string | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  type?: string | null;
  type_not?: string | null;
  type_in?: string[] | null;
  type_not_in?: string[] | null;
  type_regexp?: string | null;
  type_contains?: string | null;
  type_not_contains?: string | null;
  type_starts_with?: string | null;
  type_not_starts_with?: string | null;
  type_ends_with?: string | null;
  type_not_ends_with?: string | null;
  has_description_i?: _DescriptionFilter | null;
  has_description_i_not?: _DescriptionFilter | null;
  has_description_i_in?: _DescriptionFilter[] | null;
  has_description_i_not_in?: _DescriptionFilter[] | null;
  has_description_i_some?: _DescriptionFilter | null;
  has_description_i_none?: _DescriptionFilter | null;
  has_description_i_single?: _DescriptionFilter | null;
  has_description_i_every?: _DescriptionFilter | null;
  accessory?: _ItemFilter | null;
  accessory_not?: _ItemFilter | null;
  accessory_in?: _ItemFilter[] | null;
  accessory_not_in?: _ItemFilter[] | null;
  accessory_some?: _ItemFilter | null;
  accessory_none?: _ItemFilter | null;
  accessory_single?: _ItemFilter | null;
  accessory_every?: _ItemFilter | null;
  ACCESSORY_rel?: _ACCESSORYDirectionsFilter | null;
  ACCESSORY_rel_not?: _ACCESSORYDirectionsFilter | null;
  ACCESSORY_rel_in?: _ACCESSORYDirectionsFilter[] | null;
  ACCESSORY_rel_not_in?: _ACCESSORYDirectionsFilter[] | null;
  ACCESSORY_rel_some?: _ACCESSORYDirectionsFilter | null;
  ACCESSORY_rel_none?: _ACCESSORYDirectionsFilter | null;
  ACCESSORY_rel_single?: _ACCESSORYDirectionsFilter | null;
  ACCESSORY_rel_every?: _ACCESSORYDirectionsFilter | null;
  replacement?: _ItemFilter | null;
  replacement_not?: _ItemFilter | null;
  replacement_in?: _ItemFilter[] | null;
  replacement_not_in?: _ItemFilter[] | null;
  replacement_some?: _ItemFilter | null;
  replacement_none?: _ItemFilter | null;
  replacement_single?: _ItemFilter | null;
  replacement_every?: _ItemFilter | null;
  REPLACEMENT_rel?: _REPLACEMENTDirectionsFilter | null;
  REPLACEMENT_rel_not?: _REPLACEMENTDirectionsFilter | null;
  REPLACEMENT_rel_in?: _REPLACEMENTDirectionsFilter[] | null;
  REPLACEMENT_rel_not_in?: _REPLACEMENTDirectionsFilter[] | null;
  REPLACEMENT_rel_some?: _REPLACEMENTDirectionsFilter | null;
  REPLACEMENT_rel_none?: _REPLACEMENTDirectionsFilter | null;
  REPLACEMENT_rel_single?: _REPLACEMENTDirectionsFilter | null;
  REPLACEMENT_rel_every?: _REPLACEMENTDirectionsFilter | null;
  has_spare_part?: _ItemFilter | null;
  has_spare_part_not?: _ItemFilter | null;
  has_spare_part_in?: _ItemFilter[] | null;
  has_spare_part_not_in?: _ItemFilter[] | null;
  has_spare_part_some?: _ItemFilter | null;
  has_spare_part_none?: _ItemFilter | null;
  has_spare_part_single?: _ItemFilter | null;
  has_spare_part_every?: _ItemFilter | null;
  HAS_SPARE_PART_rel?: _HAS_SPARE_PARTDirectionsFilter | null;
  HAS_SPARE_PART_rel_not?: _HAS_SPARE_PARTDirectionsFilter | null;
  HAS_SPARE_PART_rel_in?: _HAS_SPARE_PARTDirectionsFilter[] | null;
  HAS_SPARE_PART_rel_not_in?: _HAS_SPARE_PARTDirectionsFilter[] | null;
  HAS_SPARE_PART_rel_some?: _HAS_SPARE_PARTDirectionsFilter | null;
  HAS_SPARE_PART_rel_none?: _HAS_SPARE_PARTDirectionsFilter | null;
  HAS_SPARE_PART_rel_single?: _HAS_SPARE_PARTDirectionsFilter | null;
  HAS_SPARE_PART_rel_every?: _HAS_SPARE_PARTDirectionsFilter | null;
  upsell?: _ItemFilter | null;
  upsell_not?: _ItemFilter | null;
  upsell_in?: _ItemFilter[] | null;
  upsell_not_in?: _ItemFilter[] | null;
  upsell_some?: _ItemFilter | null;
  upsell_none?: _ItemFilter | null;
  upsell_single?: _ItemFilter | null;
  upsell_every?: _ItemFilter | null;
  UPSELL_rel?: _UPSELLDirectionsFilter | null;
  UPSELL_rel_not?: _UPSELLDirectionsFilter | null;
  UPSELL_rel_in?: _UPSELLDirectionsFilter[] | null;
  UPSELL_rel_not_in?: _UPSELLDirectionsFilter[] | null;
  UPSELL_rel_some?: _UPSELLDirectionsFilter | null;
  UPSELL_rel_none?: _UPSELLDirectionsFilter | null;
  UPSELL_rel_single?: _UPSELLDirectionsFilter | null;
  UPSELL_rel_every?: _UPSELLDirectionsFilter | null;
  cross_sell?: _ItemFilter | null;
  cross_sell_not?: _ItemFilter | null;
  cross_sell_in?: _ItemFilter[] | null;
  cross_sell_not_in?: _ItemFilter[] | null;
  cross_sell_some?: _ItemFilter | null;
  cross_sell_none?: _ItemFilter | null;
  cross_sell_single?: _ItemFilter | null;
  cross_sell_every?: _ItemFilter | null;
  CROSS_SELL_rel?: _CROSS_SELLDirectionsFilter | null;
  CROSS_SELL_rel_not?: _CROSS_SELLDirectionsFilter | null;
  CROSS_SELL_rel_in?: _CROSS_SELLDirectionsFilter[] | null;
  CROSS_SELL_rel_not_in?: _CROSS_SELLDirectionsFilter[] | null;
  CROSS_SELL_rel_some?: _CROSS_SELLDirectionsFilter | null;
  CROSS_SELL_rel_none?: _CROSS_SELLDirectionsFilter | null;
  CROSS_SELL_rel_single?: _CROSS_SELLDirectionsFilter | null;
  CROSS_SELL_rel_every?: _CROSS_SELLDirectionsFilter | null;
  has_bom?: _ItemFilter | null;
  has_bom_not?: _ItemFilter | null;
  has_bom_in?: _ItemFilter[] | null;
  has_bom_not_in?: _ItemFilter[] | null;
  has_bom_some?: _ItemFilter | null;
  has_bom_none?: _ItemFilter | null;
  has_bom_single?: _ItemFilter | null;
  has_bom_every?: _ItemFilter | null;
  HAS_BOM_rel?: _HAS_BOMDirectionsFilter | null;
  HAS_BOM_rel_not?: _HAS_BOMDirectionsFilter | null;
  HAS_BOM_rel_in?: _HAS_BOMDirectionsFilter[] | null;
  HAS_BOM_rel_not_in?: _HAS_BOMDirectionsFilter[] | null;
  HAS_BOM_rel_some?: _HAS_BOMDirectionsFilter | null;
  HAS_BOM_rel_none?: _HAS_BOMDirectionsFilter | null;
  HAS_BOM_rel_single?: _HAS_BOMDirectionsFilter | null;
  HAS_BOM_rel_every?: _HAS_BOMDirectionsFilter | null;
  has_feature?: _FeatureFilter | null;
  has_feature_not?: _FeatureFilter | null;
  has_feature_in?: _FeatureFilter[] | null;
  has_feature_not_in?: _FeatureFilter[] | null;
  has_feature_some?: _FeatureFilter | null;
  has_feature_none?: _FeatureFilter | null;
  has_feature_single?: _FeatureFilter | null;
  has_feature_every?: _FeatureFilter | null;
  HAS_FEATURE_rel?: _ItemHAS_FEATUREFilter | null;
  HAS_FEATURE_rel_not?: _ItemHAS_FEATUREFilter | null;
  HAS_FEATURE_rel_in?: _ItemHAS_FEATUREFilter[] | null;
  HAS_FEATURE_rel_not_in?: _ItemHAS_FEATUREFilter[] | null;
  HAS_FEATURE_rel_some?: _ItemHAS_FEATUREFilter | null;
  HAS_FEATURE_rel_none?: _ItemHAS_FEATUREFilter | null;
  HAS_FEATURE_rel_single?: _ItemHAS_FEATUREFilter | null;
  HAS_FEATURE_rel_every?: _ItemHAS_FEATUREFilter | null;
  has_erp_item?: _ERPItemFilter | null;
  has_erp_item_not?: _ERPItemFilter | null;
  has_erp_item_in?: _ERPItemFilter[] | null;
  has_erp_item_not_in?: _ERPItemFilter[] | null;
  has_erp_item_some?: _ERPItemFilter | null;
  has_erp_item_none?: _ERPItemFilter | null;
  has_erp_item_single?: _ERPItemFilter | null;
  has_erp_item_every?: _ERPItemFilter | null;
  HAS_ERP_ITEM_rel?: _ItemHAS_ERP_ITEMFilter | null;
  HAS_ERP_ITEM_rel_not?: _ItemHAS_ERP_ITEMFilter | null;
  HAS_ERP_ITEM_rel_in?: _ItemHAS_ERP_ITEMFilter[] | null;
  HAS_ERP_ITEM_rel_not_in?: _ItemHAS_ERP_ITEMFilter[] | null;
  HAS_ERP_ITEM_rel_some?: _ItemHAS_ERP_ITEMFilter | null;
  HAS_ERP_ITEM_rel_none?: _ItemHAS_ERP_ITEMFilter | null;
  HAS_ERP_ITEM_rel_single?: _ItemHAS_ERP_ITEMFilter | null;
  HAS_ERP_ITEM_rel_every?: _ItemHAS_ERP_ITEMFilter | null;
  document_for_item?: _DocumentFilter | null;
  document_for_item_not?: _DocumentFilter | null;
  document_for_item_in?: _DocumentFilter[] | null;
  document_for_item_not_in?: _DocumentFilter[] | null;
  document_for_item_some?: _DocumentFilter | null;
  document_for_item_none?: _DocumentFilter | null;
  document_for_item_single?: _DocumentFilter | null;
  document_for_item_every?: _DocumentFilter | null;
  DOCUMENT_FOR_ITEM_rel?: _ItemDOCUMENT_FOR_ITEMFilter | null;
  DOCUMENT_FOR_ITEM_rel_not?: _ItemDOCUMENT_FOR_ITEMFilter | null;
  DOCUMENT_FOR_ITEM_rel_in?: _ItemDOCUMENT_FOR_ITEMFilter[] | null;
  DOCUMENT_FOR_ITEM_rel_not_in?: _ItemDOCUMENT_FOR_ITEMFilter[] | null;
  DOCUMENT_FOR_ITEM_rel_some?: _ItemDOCUMENT_FOR_ITEMFilter | null;
  DOCUMENT_FOR_ITEM_rel_none?: _ItemDOCUMENT_FOR_ITEMFilter | null;
  DOCUMENT_FOR_ITEM_rel_single?: _ItemDOCUMENT_FOR_ITEMFilter | null;
  DOCUMENT_FOR_ITEM_rel_every?: _ItemDOCUMENT_FOR_ITEMFilter | null;
  has_contact?: _ContactFilter | null;
  has_contact_not?: _ContactFilter | null;
  has_contact_in?: _ContactFilter[] | null;
  has_contact_not_in?: _ContactFilter[] | null;
  has_contact_some?: _ContactFilter | null;
  has_contact_none?: _ContactFilter | null;
  has_contact_single?: _ContactFilter | null;
  has_contact_every?: _ContactFilter | null;
  HAS_CONTACT_rel?: _ItemHAS_CONTACTFilter | null;
  HAS_CONTACT_rel_not?: _ItemHAS_CONTACTFilter | null;
  HAS_CONTACT_rel_in?: _ItemHAS_CONTACTFilter[] | null;
  HAS_CONTACT_rel_not_in?: _ItemHAS_CONTACTFilter[] | null;
  HAS_CONTACT_rel_some?: _ItemHAS_CONTACTFilter | null;
  HAS_CONTACT_rel_none?: _ItemHAS_CONTACTFilter | null;
  HAS_CONTACT_rel_single?: _ItemHAS_CONTACTFilter | null;
  HAS_CONTACT_rel_every?: _ItemHAS_CONTACTFilter | null;
  has_replacement?: _ItemFilter | null;
  has_replacement_not?: _ItemFilter | null;
  has_replacement_in?: _ItemFilter[] | null;
  has_replacement_not_in?: _ItemFilter[] | null;
  has_replacement_some?: _ItemFilter | null;
  has_replacement_none?: _ItemFilter | null;
  has_replacement_single?: _ItemFilter | null;
  has_replacement_every?: _ItemFilter | null;
  item_fits?: _ItemFilter | null;
  item_fits_not?: _ItemFilter | null;
  item_fits_in?: _ItemFilter[] | null;
  item_fits_not_in?: _ItemFilter[] | null;
  item_fits_some?: _ItemFilter | null;
  item_fits_none?: _ItemFilter | null;
  item_fits_single?: _ItemFilter | null;
  item_fits_every?: _ItemFilter | null;
  ITEM_FITS_rel?: _ITEM_FITSDirectionsFilter | null;
  ITEM_FITS_rel_not?: _ITEM_FITSDirectionsFilter | null;
  ITEM_FITS_rel_in?: _ITEM_FITSDirectionsFilter[] | null;
  ITEM_FITS_rel_not_in?: _ITEM_FITSDirectionsFilter[] | null;
  ITEM_FITS_rel_some?: _ITEM_FITSDirectionsFilter | null;
  ITEM_FITS_rel_none?: _ITEM_FITSDirectionsFilter | null;
  ITEM_FITS_rel_single?: _ITEM_FITSDirectionsFilter | null;
  ITEM_FITS_rel_every?: _ITEM_FITSDirectionsFilter | null;
  products?: _ProductFilter | null;
  products_not?: _ProductFilter | null;
  products_in?: _ProductFilter[] | null;
  products_not_in?: _ProductFilter[] | null;
  products_some?: _ProductFilter | null;
  products_none?: _ProductFilter | null;
  products_single?: _ProductFilter | null;
  products_every?: _ProductFilter | null;
}

export interface _ItemHAS_CONTACTFilter {
  AND?: _ItemHAS_CONTACTFilter[] | null;
  OR?: _ItemHAS_CONTACTFilter[] | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  Contact?: _ContactFilter | null;
}

export interface _ItemHAS_ERP_ITEMFilter {
  AND?: _ItemHAS_ERP_ITEMFilter[] | null;
  OR?: _ItemHAS_ERP_ITEMFilter[] | null;
  ERPSystem?: string | null;
  ERPSystem_not?: string | null;
  ERPSystem_in?: string[] | null;
  ERPSystem_not_in?: string[] | null;
  ERPSystem_regexp?: string | null;
  ERPSystem_contains?: string | null;
  ERPSystem_not_contains?: string | null;
  ERPSystem_starts_with?: string | null;
  ERPSystem_not_starts_with?: string | null;
  ERPSystem_ends_with?: string | null;
  ERPSystem_not_ends_with?: string | null;
  ERPItem?: _ERPItemFilter | null;
}

export interface _ItemHAS_FEATUREFilter {
  AND?: _ItemHAS_FEATUREFilter[] | null;
  OR?: _ItemHAS_FEATUREFilter[] | null;
  sequence?: number | null;
  sequence_not?: number | null;
  sequence_in?: number[] | null;
  sequence_not_in?: number[] | null;
  sequence_lt?: number | null;
  sequence_lte?: number | null;
  sequence_gt?: number | null;
  sequence_gte?: number | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  Feature?: _FeatureFilter | null;
}

export interface _MediumFilter {
  AND?: _MediumFilter[] | null;
  OR?: _MediumFilter[] | null;
  chemicalCAS?: string | null;
  chemicalCAS_not?: string | null;
  chemicalCAS_in?: string[] | null;
  chemicalCAS_not_in?: string[] | null;
  chemicalCAS_regexp?: string | null;
  chemicalCAS_contains?: string | null;
  chemicalCAS_not_contains?: string | null;
  chemicalCAS_starts_with?: string | null;
  chemicalCAS_not_starts_with?: string | null;
  chemicalCAS_ends_with?: string | null;
  chemicalCAS_not_ends_with?: string | null;
  chemicalFormula?: string | null;
  chemicalFormula_not?: string | null;
  chemicalFormula_in?: string[] | null;
  chemicalFormula_not_in?: string[] | null;
  chemicalFormula_regexp?: string | null;
  chemicalFormula_contains?: string | null;
  chemicalFormula_not_contains?: string | null;
  chemicalFormula_starts_with?: string | null;
  chemicalFormula_not_starts_with?: string | null;
  chemicalFormula_ends_with?: string | null;
  chemicalFormula_not_ends_with?: string | null;
  chemicalName?: string | null;
  chemicalName_not?: string | null;
  chemicalName_in?: string[] | null;
  chemicalName_not_in?: string[] | null;
  chemicalName_regexp?: string | null;
  chemicalName_contains?: string | null;
  chemicalName_not_contains?: string | null;
  chemicalName_starts_with?: string | null;
  chemicalName_not_starts_with?: string | null;
  chemicalName_ends_with?: string | null;
  chemicalName_not_ends_with?: string | null;
  material?: string | null;
  material_not?: string | null;
  material_in?: string[] | null;
  material_not_in?: string[] | null;
  material_regexp?: string | null;
  material_contains?: string | null;
  material_not_contains?: string | null;
  material_starts_with?: string | null;
  material_not_starts_with?: string | null;
  material_ends_with?: string | null;
  material_not_ends_with?: string | null;
  mediumId?: number | null;
  mediumId_not?: number | null;
  mediumId_in?: number[] | null;
  mediumId_not_in?: number[] | null;
  mediumId_lt?: number | null;
  mediumId_lte?: number | null;
  mediumId_gt?: number | null;
  mediumId_gte?: number | null;
  score?: number | null;
  score_not?: number | null;
  score_in?: number[] | null;
  score_not_in?: number[] | null;
  score_lt?: number | null;
  score_lte?: number | null;
  score_gt?: number | null;
  score_gte?: number | null;
  scoreDescription?: string | null;
  scoreDescription_not?: string | null;
  scoreDescription_in?: string[] | null;
  scoreDescription_not_in?: string[] | null;
  scoreDescription_regexp?: string | null;
  scoreDescription_contains?: string | null;
  scoreDescription_not_contains?: string | null;
  scoreDescription_starts_with?: string | null;
  scoreDescription_not_starts_with?: string | null;
  scoreDescription_ends_with?: string | null;
  scoreDescription_not_ends_with?: string | null;
  features?: _FeatureFilter | null;
  features_not?: _FeatureFilter | null;
  features_in?: _FeatureFilter[] | null;
  features_not_in?: _FeatureFilter[] | null;
  features_some?: _FeatureFilter | null;
  features_none?: _FeatureFilter | null;
  features_single?: _FeatureFilter | null;
  features_every?: _FeatureFilter | null;
}

export interface _ProductCategoryFilter {
  AND?: _ProductCategoryFilter[] | null;
  OR?: _ProductCategoryFilter[] | null;
  groupIdentifier?: string | null;
  groupIdentifier_not?: string | null;
  groupIdentifier_in?: string[] | null;
  groupIdentifier_not_in?: string[] | null;
  groupIdentifier_regexp?: string | null;
  groupIdentifier_contains?: string | null;
  groupIdentifier_not_contains?: string | null;
  groupIdentifier_starts_with?: string | null;
  groupIdentifier_not_starts_with?: string | null;
  groupIdentifier_ends_with?: string | null;
  groupIdentifier_not_ends_with?: string | null;
  sequence?: number | null;
  sequence_not?: number | null;
  sequence_in?: number[] | null;
  sequence_not_in?: number[] | null;
  sequence_lt?: number | null;
  sequence_lte?: number | null;
  sequence_gt?: number | null;
  sequence_gte?: number | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  topGroup?: number | null;
  topGroup_not?: number | null;
  topGroup_in?: number[] | null;
  topGroup_not_in?: number[] | null;
  topGroup_lt?: number | null;
  topGroup_lte?: number | null;
  topGroup_gt?: number | null;
  topGroup_gte?: number | null;
  has_product_subcategory?: _ProductCategoryFilter | null;
  has_product_subcategory_not?: _ProductCategoryFilter | null;
  has_product_subcategory_in?: _ProductCategoryFilter[] | null;
  has_product_subcategory_not_in?: _ProductCategoryFilter[] | null;
  has_product_subcategory_some?: _ProductCategoryFilter | null;
  has_product_subcategory_none?: _ProductCategoryFilter | null;
  has_product_subcategory_single?: _ProductCategoryFilter | null;
  has_product_subcategory_every?: _ProductCategoryFilter | null;
  has_description_pc?: _DescriptionFilter | null;
  has_description_pc_not?: _DescriptionFilter | null;
  has_description_pc_in?: _DescriptionFilter[] | null;
  has_description_pc_not_in?: _DescriptionFilter[] | null;
  has_description_pc_some?: _DescriptionFilter | null;
  has_description_pc_none?: _DescriptionFilter | null;
  has_description_pc_single?: _DescriptionFilter | null;
  has_description_pc_every?: _DescriptionFilter | null;
  has_product_group?: _ProductGroupFilter | null;
  has_product_group_not?: _ProductGroupFilter | null;
  has_product_group_in?: _ProductGroupFilter[] | null;
  has_product_group_not_in?: _ProductGroupFilter[] | null;
  has_product_group_some?: _ProductGroupFilter | null;
  has_product_group_none?: _ProductGroupFilter | null;
  has_product_group_single?: _ProductGroupFilter | null;
  has_product_group_every?: _ProductGroupFilter | null;
}

export interface _ProductDOCUMENT_FOR_PRODUCTFilter {
  AND?: _ProductDOCUMENT_FOR_PRODUCTFilter[] | null;
  OR?: _ProductDOCUMENT_FOR_PRODUCTFilter[] | null;
  attachmentIdentifier?: string | null;
  attachmentIdentifier_not?: string | null;
  attachmentIdentifier_in?: string[] | null;
  attachmentIdentifier_not_in?: string[] | null;
  attachmentIdentifier_regexp?: string | null;
  attachmentIdentifier_contains?: string | null;
  attachmentIdentifier_not_contains?: string | null;
  attachmentIdentifier_starts_with?: string | null;
  attachmentIdentifier_not_starts_with?: string | null;
  attachmentIdentifier_ends_with?: string | null;
  attachmentIdentifier_not_ends_with?: string | null;
  sequence?: number | null;
  sequence_not?: number | null;
  sequence_in?: number[] | null;
  sequence_not_in?: number[] | null;
  sequence_lt?: number | null;
  sequence_lte?: number | null;
  sequence_gt?: number | null;
  sequence_gte?: number | null;
  Document?: _DocumentFilter | null;
}

export interface _ProductFilter {
  AND?: _ProductFilter[] | null;
  OR?: _ProductFilter[] | null;
  UNSPSC?: number | null;
  UNSPSC_not?: number | null;
  UNSPSC_in?: number[] | null;
  UNSPSC_not_in?: number[] | null;
  UNSPSC_lt?: number | null;
  UNSPSC_lte?: number | null;
  UNSPSC_gt?: number | null;
  UNSPSC_gte?: number | null;
  brand?: string | null;
  brand_not?: string | null;
  brand_in?: string[] | null;
  brand_not_in?: string[] | null;
  brand_regexp?: string | null;
  brand_contains?: string | null;
  brand_not_contains?: string | null;
  brand_starts_with?: string | null;
  brand_not_starts_with?: string | null;
  brand_ends_with?: string | null;
  brand_not_ends_with?: string | null;
  eClass?: number | null;
  eClass_not?: number | null;
  eClass_in?: number[] | null;
  eClass_not_in?: number[] | null;
  eClass_lt?: number | null;
  eClass_lte?: number | null;
  eClass_gt?: number | null;
  eClass_gte?: number | null;
  erpSystems?: string[] | null;
  erpSystems_not?: string[] | null;
  erpSystems_regexp?: string | null;
  erpSystems_contains?: string[] | null;
  erpSystems_not_contains?: string[] | null;
  erpSystems_starts_with?: string[] | null;
  erpSystems_not_starts_with?: string[] | null;
  erpSystems_ends_with?: string[] | null;
  erpSystems_not_ends_with?: string[] | null;
  manufacturerPartNumber?: string | null;
  manufacturerPartNumber_not?: string | null;
  manufacturerPartNumber_in?: string[] | null;
  manufacturerPartNumber_not_in?: string[] | null;
  manufacturerPartNumber_regexp?: string | null;
  manufacturerPartNumber_contains?: string | null;
  manufacturerPartNumber_not_contains?: string | null;
  manufacturerPartNumber_starts_with?: string | null;
  manufacturerPartNumber_not_starts_with?: string | null;
  manufacturerPartNumber_ends_with?: string | null;
  manufacturerPartNumber_not_ends_with?: string | null;
  partNumber?: string | null;
  partNumber_not?: string | null;
  partNumber_in?: string[] | null;
  partNumber_not_in?: string[] | null;
  partNumber_regexp?: string | null;
  partNumber_contains?: string | null;
  partNumber_not_contains?: string | null;
  partNumber_starts_with?: string | null;
  partNumber_not_starts_with?: string | null;
  partNumber_ends_with?: string | null;
  partNumber_not_ends_with?: string | null;
  sequence?: number | null;
  sequence_not?: number | null;
  sequence_in?: number[] | null;
  sequence_not_in?: number[] | null;
  sequence_lt?: number | null;
  sequence_lte?: number | null;
  sequence_gt?: number | null;
  sequence_gte?: number | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  has_description_p?: _DescriptionFilter | null;
  has_description_p_not?: _DescriptionFilter | null;
  has_description_p_in?: _DescriptionFilter[] | null;
  has_description_p_not_in?: _DescriptionFilter[] | null;
  has_description_p_some?: _DescriptionFilter | null;
  has_description_p_none?: _DescriptionFilter | null;
  has_description_p_single?: _DescriptionFilter | null;
  has_description_p_every?: _DescriptionFilter | null;
  has_item?: _ItemFilter | null;
  has_item_not?: _ItemFilter | null;
  has_item_in?: _ItemFilter[] | null;
  has_item_not_in?: _ItemFilter[] | null;
  has_item_some?: _ItemFilter | null;
  has_item_none?: _ItemFilter | null;
  has_item_single?: _ItemFilter | null;
  has_item_every?: _ItemFilter | null;
  document_for_product?: _DocumentFilter | null;
  document_for_product_not?: _DocumentFilter | null;
  document_for_product_in?: _DocumentFilter[] | null;
  document_for_product_not_in?: _DocumentFilter[] | null;
  document_for_product_some?: _DocumentFilter | null;
  document_for_product_none?: _DocumentFilter | null;
  document_for_product_single?: _DocumentFilter | null;
  document_for_product_every?: _DocumentFilter | null;
  DOCUMENT_FOR_PRODUCT_rel?: _ProductDOCUMENT_FOR_PRODUCTFilter | null;
  DOCUMENT_FOR_PRODUCT_rel_not?: _ProductDOCUMENT_FOR_PRODUCTFilter | null;
  DOCUMENT_FOR_PRODUCT_rel_in?: _ProductDOCUMENT_FOR_PRODUCTFilter[] | null;
  DOCUMENT_FOR_PRODUCT_rel_not_in?: _ProductDOCUMENT_FOR_PRODUCTFilter[] | null;
  DOCUMENT_FOR_PRODUCT_rel_some?: _ProductDOCUMENT_FOR_PRODUCTFilter | null;
  DOCUMENT_FOR_PRODUCT_rel_none?: _ProductDOCUMENT_FOR_PRODUCTFilter | null;
  DOCUMENT_FOR_PRODUCT_rel_single?: _ProductDOCUMENT_FOR_PRODUCTFilter | null;
  DOCUMENT_FOR_PRODUCT_rel_every?: _ProductDOCUMENT_FOR_PRODUCTFilter | null;
  productgroups?: _ProductGroupFilter | null;
  productgroups_not?: _ProductGroupFilter | null;
  productgroups_in?: _ProductGroupFilter[] | null;
  productgroups_not_in?: _ProductGroupFilter[] | null;
  productgroups_some?: _ProductGroupFilter | null;
  productgroups_none?: _ProductGroupFilter | null;
  productgroups_single?: _ProductGroupFilter | null;
  productgroups_every?: _ProductGroupFilter | null;
  brands?: _BrandFilter | null;
  brands_not?: _BrandFilter | null;
  brands_in?: _BrandFilter[] | null;
  brands_not_in?: _BrandFilter[] | null;
  brands_some?: _BrandFilter | null;
  brands_none?: _BrandFilter | null;
  brands_single?: _BrandFilter | null;
  brands_every?: _BrandFilter | null;
}

export interface _ProductGroupFilter {
  AND?: _ProductGroupFilter[] | null;
  OR?: _ProductGroupFilter[] | null;
  erpSystems?: string[] | null;
  erpSystems_not?: string[] | null;
  erpSystems_regexp?: string | null;
  erpSystems_contains?: string[] | null;
  erpSystems_not_contains?: string[] | null;
  erpSystems_starts_with?: string[] | null;
  erpSystems_not_starts_with?: string[] | null;
  erpSystems_ends_with?: string[] | null;
  erpSystems_not_ends_with?: string[] | null;
  groupIdentifier?: string | null;
  groupIdentifier_not?: string | null;
  groupIdentifier_in?: string[] | null;
  groupIdentifier_not_in?: string[] | null;
  groupIdentifier_regexp?: string | null;
  groupIdentifier_contains?: string | null;
  groupIdentifier_not_contains?: string | null;
  groupIdentifier_starts_with?: string | null;
  groupIdentifier_not_starts_with?: string | null;
  groupIdentifier_ends_with?: string | null;
  groupIdentifier_not_ends_with?: string | null;
  sequence?: number | null;
  sequence_not?: number | null;
  sequence_in?: number[] | null;
  sequence_not_in?: number[] | null;
  sequence_lt?: number | null;
  sequence_lte?: number | null;
  sequence_gt?: number | null;
  sequence_gte?: number | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  has_product?: _ProductFilter | null;
  has_product_not?: _ProductFilter | null;
  has_product_in?: _ProductFilter[] | null;
  has_product_not_in?: _ProductFilter[] | null;
  has_product_some?: _ProductFilter | null;
  has_product_none?: _ProductFilter | null;
  has_product_single?: _ProductFilter | null;
  has_product_every?: _ProductFilter | null;
  has_description_pg?: _DescriptionFilter | null;
  has_description_pg_not?: _DescriptionFilter | null;
  has_description_pg_in?: _DescriptionFilter[] | null;
  has_description_pg_not_in?: _DescriptionFilter[] | null;
  has_description_pg_some?: _DescriptionFilter | null;
  has_description_pg_none?: _DescriptionFilter | null;
  has_description_pg_single?: _DescriptionFilter | null;
  has_description_pg_every?: _DescriptionFilter | null;
  productcategorys?: _ProductCategoryFilter | null;
  productcategorys_not?: _ProductCategoryFilter | null;
  productcategorys_in?: _ProductCategoryFilter[] | null;
  productcategorys_not_in?: _ProductCategoryFilter[] | null;
  productcategorys_some?: _ProductCategoryFilter | null;
  productcategorys_none?: _ProductCategoryFilter | null;
  productcategorys_single?: _ProductCategoryFilter | null;
  productcategorys_every?: _ProductCategoryFilter | null;
}

export interface _PurposeFilter {
  AND?: _PurposeFilter[] | null;
  OR?: _PurposeFilter[] | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_regexp?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  features?: _FeatureFilter | null;
  features_not?: _FeatureFilter | null;
  features_in?: _FeatureFilter[] | null;
  features_not_in?: _FeatureFilter[] | null;
  features_some?: _FeatureFilter | null;
  features_none?: _FeatureFilter | null;
  features_single?: _FeatureFilter | null;
  features_every?: _FeatureFilter | null;
}

export interface _REPLACEMENTDirectionsFilter {
  from?: _REPLACEMENTFilter | null;
  to?: _REPLACEMENTFilter | null;
}

export interface _REPLACEMENTFilter {
  AND?: _REPLACEMENTFilter[] | null;
  OR?: _REPLACEMENTFilter[] | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  Item?: _ItemFilter | null;
}

export interface _UPSELLDirectionsFilter {
  from?: _UPSELLFilter | null;
  to?: _UPSELLFilter | null;
}

export interface _UPSELLFilter {
  AND?: _UPSELLFilter[] | null;
  OR?: _UPSELLFilter[] | null;
  source?: string | null;
  source_not?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_regexp?: string | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  Item?: _ItemFilter | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
