// THIS HOC MANAGES PARAM CHANGE WHEN STORE IS UPDATED
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { getModifiedParams, getObjectFromParams } from "utils/param";
import { ParamAttributes } from "utils/param_variables";
import {
  contextSelector,
  customerSelector,
  wishlistSelector,
} from "state/selector";
import {
  CountryType,
  isCountryType,
  isLanguageType,
  LanguageType,
  setBrandLabel,
  setCountry,
  setERPSystem,
  setLanguage,
  setSearchQuery,
} from "state/context";
import { useIntl } from "react-intl";

interface SelectedCustomer {
  country: string;
  customerId: string;
}

export const withParam = <P extends object>(
  WrappedComponent: React.ComponentType<P>
): React.FC<P> => (componentProps: P): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    countryCode,
    erpSystemCode,
    languageCode,
    searchQuery,
    allAssortments,
    brandName,
    brandLabel,
  } = useSelector(contextSelector);
  const { wishlist } = useSelector(wishlistSelector);
  const { selectedCustomer, favouriteCustomers } = useSelector(
    customerSelector
  );

  const intl = useIntl();
  const name = intl.formatMessage({ id: "autocomplete.brand.brand" });

  useEffect(() => {
    const params = getObjectFromParams(location.search);

    const countryCodeFromParams = params[
      ParamAttributes.country
    ]?.toLowerCase();
    const languageCodeFromParams = params[
      ParamAttributes.language
    ]?.toLowerCase();
    const searchQueryFromParams = params[ParamAttributes.searchQuery];

    if (
      countryCodeFromParams &&
      countryCodeFromParams !== countryCode &&
      isCountryType(countryCodeFromParams.toUpperCase())
    ) {
      dispatch(setCountry(countryCodeFromParams.toUpperCase() as CountryType));
      dispatch(
        setERPSystem(countryCodeFromParams?.toUpperCase() as CountryType)
      );
    }

    if (
      languageCodeFromParams &&
      languageCodeFromParams !== languageCode &&
      isLanguageType(languageCodeFromParams.toUpperCase())
    )
      dispatch(
        setLanguage(languageCodeFromParams.toUpperCase() as LanguageType)
      );
    if (searchQueryFromParams && searchQueryFromParams !== searchQuery) {
      dispatch(setSearchQuery(searchQueryFromParams));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(setBrandLabel(name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, languageCode]);

  useEffect(() => {
    const newParams = getModifiedParams(location.search, {
      [ParamAttributes.country]: countryCode?.toLowerCase() ?? null,
      [ParamAttributes.language]: languageCode?.toLowerCase() ?? null,
      [ParamAttributes.searchQuery]: searchQuery ?? null,
    });

    const decoded = decodeURIComponent(newParams.toString());

    history.push({
      pathname: location.pathname,
      search: "?" + decoded,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    brandLabel,
    brandName,
    countryCode,
    erpSystemCode,
    searchQuery,
    selectedCustomer,
  ]);

  useEffect(() => {
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
  }, [wishlist]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    localStorage.setItem("countryCode", countryCode);
  }, [countryCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    localStorage.setItem("languageCode", languageCode);
  }, [languageCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    localStorage.setItem("allAssortments", JSON.stringify(allAssortments));
  }, [allAssortments]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    localStorage.setItem("selectedCustomer", JSON.stringify(selectedCustomer));
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    localStorage.setItem(
      "favouriteCustomers",
      JSON.stringify(favouriteCustomers)
    );
  }, [favouriteCustomers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    localStorage.setItem("showInitialSearchResult", JSON.stringify(false));
  }, []);
  // DO NOT SAVE SEARCH QUERY TO LOCAL STORAGE

  return <WrappedComponent {...(componentProps as P)} />;
};
