export const ParamAttributes = {
  language: "lang",
  country: "country",
  searchQuery: "q",
};

// This params will not be processed through the filter query
// When user goes back to the search page, only this params will be intact
export const CoreParams = [
  ParamAttributes.language,
  ParamAttributes.country,
  ParamAttributes.searchQuery,
];
