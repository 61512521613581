import styled from "styled-components";

export const HeaderIconWrapper = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.heading.primary};
`;

export const IconWrapper = styled.span`
  display: inline-block;
  height: 2.25rem;
  line-height: 2.25rem;
  margin-top: 4px;
  position: relative;
  text-align: center;
  width: 2.25rem;
  &:hover {
    cursor: pointer;
  }
`;

export const ItemCountCircle = styled.span<{
  isLargeAmount: boolean;
  background?: string;
  color?: string;
}>`
  background-color: ${({ background, theme }) =>
    background ? background : theme.colors.default.yellow};
  border-radius: 50%;
  color: ${({ color, theme }) =>
    color ? color : theme.colors.heading.primary};
  font-size: 0.6875rem;
  height: ${({ isLargeAmount }) => (isLargeAmount ? "1.125rem" : "0.875rem")};
  line-height: ${({ isLargeAmount }) => (isLargeAmount ? "18px" : "14px")};
  position: absolute;
  right: ${({ isLargeAmount }) => (isLargeAmount ? "0.1875rem" : 0)};
  text-align: center;
  top: 0;
  width: ${({ isLargeAmount }) => (isLargeAmount ? "1.375rem" : "0.875rem")};
`;

export const IconLabel = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
`;
