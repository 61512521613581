/**
 *
 * LanguageSelector
 *
 */

import React from "react";
import { DownArrowIcon } from "@eriksdigital/atomic-ui/components/Icons";
import { colors } from "@eriksdigital/atomic-ui/styles";
import {
  StyledIcon,
  StyledFlag,
  StyledSelectorHeader,
  StyledLanguage,
  StyledGhost,
  StyledPopupHost,
} from "./style";
import CountryFlag from "../CountryFlag";
import { useDispatch, useSelector } from "react-redux";
import { contextSelector } from "state/selector";
import { countryLanguageCombos } from "utils/CountryLanguageUtils";
import { setIsSelectorOpen } from "state/context";
import CountryLanguageSelectorPopup from "./CountryLanguageSelectorPopup";

const CountryLanguageSelector = () => {
  const dispatch = useDispatch();
  const { languageCode, countryCode, isSelectorOpen } = useSelector(
    contextSelector
  );

  const selectedCountry = countryLanguageCombos.filter(
    (selectedCountry) => selectedCountry.countryCode === countryCode
  );

  return (
    <React.Fragment>
      <StyledSelectorHeader id="PopupHostBottomRight">
        <StyledGhost
          onClick={() => dispatch(setIsSelectorOpen(!isSelectorOpen))}
          variant="ghost"
          id="country-language-selector-button"
          data-testid="country-language-selector-button"
        >
          <StyledFlag data-testid="current-country">
            <CountryFlag code={countryCode} />
          </StyledFlag>
        </StyledGhost>
        <StyledLanguage data-testid="current-language">
          {selectedCountry[0]["countryName"]} | {languageCode}
        </StyledLanguage>
        <StyledIcon as={DownArrowIcon} color={colors.heading.primary} />
      </StyledSelectorHeader>
      <StyledPopupHost parent="PopupHostBottomRight" side="bottomleft">
        <CountryLanguageSelectorPopup />
      </StyledPopupHost>
    </React.Fragment>
  );
};

export default CountryLanguageSelector;
