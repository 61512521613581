import styled, { createGlobalStyle, css } from "styled-components";
import { Link, MainLayout } from "@eriksdigital/atomic-ui/components";
import { breakpoints } from "@eriksdigital/atomic-ui/styles";

const defaultPXSize = 16;
export const pxToRem = (px: number) => `${px / defaultPXSize}rem`;

export const PageWrapper = styled.div`
  background: transparent;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.gray};
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const HeaderUpper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderUpperElement = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderUpperLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.heading.primary};
  margin-right: ${({ theme }) => theme.spacing.sp8};
  em {
    background-color: rgba(252, 198, 5, 0.4);
    font-style: normal;
  }
`;

export const HeaderWrapper = styled.div`
  padding-top: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderLeft = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: row;
  flex: 0 0 20%;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  svg {
    color: ${({ theme }) => theme.colors.default.blueD};
    max-height: 30px;
    width: auto;
  }
`;

export const HeaderCenter = styled.div`
  text-align: center;
  flex: 1 0 0%;
  margin: 0px 0.5rem;
`;

export const HeaderRight = styled.div`
  text-align: right;
  flex: 0 0 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  div,
  a {
    margin-right: ${({ theme }) => theme.spacing.sp16};
    &:last-child {
      margin-right: 0;
    }
  }
`;
export const HeaderRightIcons = styled(HeaderRight)`
  align-items: center;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  ${breakpoints.tabletIpadPortrait`
    flex-direction: row;
  `};
  h1 {
    margin: 1rem auto 1rem 0;
  }
`;

export const GlobalHome = createGlobalStyle`
  body {
    background-color: #f8f8f8;
  }
`;

export const SearchBarWrapper = styled.div<{ isSearchStick: boolean }>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.default.lightGray};
  padding: ${({ theme }) => theme.spacing.sp16} 0;
  ${(props) =>
    props.isSearchStick
      ? "position: relative;"
      : `position: fixed; top: ${pxToRem(145)}; left: 0; z-index: 10;`}

  ${(props) =>
    !props.isSearchStick
      ? css`
          border-bottom: 1px solid
            ${({ theme }) => theme.colors.default.midGray};
          margin: 0 auto;
        `
      : null}
`;

export const StyledMainLayout = styled(MainLayout)`
  display: flex;
  justify-content: space-between;
`;
