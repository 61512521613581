import React from "react";
import axios from "axios";
import { useMsal, useAccount } from "@azure/msal-react";

import { loginRequest } from "../authConfig";
import { setApiError } from "state/apiError";
import { useDispatch } from "react-redux";

interface RequestInterceptorProps {
  children: JSX.Element;
}

const RequestInterceptor = ({ children }: RequestInterceptorProps) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const dispatch = useDispatch();

  /* eslint-disable no-param-reassign */
  axios.interceptors.request.use(
    async (request) => {
      dispatch(setApiError(null));
      if (!account) {
        throw Error("No active account! Verify a user has been signed in.");
      }
      if (account && request.url?.includes("projectwiki-search")) {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account,
        });

        const bearer = `Bearer ${response.idToken}`;
        request.headers.Authorization = bearer;
      }

      return request;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (err) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger

      const error = { ...err };

      if (!error.response) {
        dispatch(setApiError("4XX"));
      }

      if (
        error?.errorCode === "no_tokens_found" ||
        error?.response?.status === 401
      ) {
        instance.logoutRedirect();
      }

      if (error?.response?.status > 401) {
        dispatch(setApiError("4XX"));
      }

      if (error?.response?.status >= 500) {
        dispatch(setApiError("5XX"));
      }
    }
  );

  return <>{children}</>;
};

export default RequestInterceptor;
