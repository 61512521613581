import React from "react";
import { P } from "@eriksdigital/atomic-ui/components";
import { useQuery } from "@apollo/react-hooks";
import { LAST_UPDATED_QUERY } from "./queries";
import { LastUpdate } from "./schema/LastUpdate";
import { FormattedMessage } from "react-intl";

const LastUpdated = () => {
  const { loading, error, data } = useQuery<LastUpdate>(LAST_UPDATED_QUERY);

  const { day, month, year } = data?.LastUpdate?.[0]?.pim ?? {
    day: undefined,
    month: undefined,
    year: undefined,
  };

  const lastUpdatedDefined = day && month && year;

  const LastUpdateDate = () =>
    lastUpdatedDefined ? (
      <>{`${day}-${month}-${year}`}</>
    ) : (
      <FormattedMessage id="release.home.notification" />
    );

  if (loading) return null;
  if (error) return <P>Error getting last updated</P>;

  return (
    <P data-testid="last-updated">
      <FormattedMessage id="last.updated" /> <LastUpdateDate />
    </P>
  );
};

export default LastUpdated;
