import React from "react";

import {
  LoadingFiltersWrapper,
  LoadingFlexWrapper,
  LoadingFiltersOptionPlaceholder,
  LoadingFiltersLabelPlaceholder,
  LoadingDot,
} from "./style";

export const LoadingFilters = () => {
  return (
    <div data-testid="loading-filters">
      {[1, 2, 3, 4, 5, 6, 7, 8].map((e) => (
        <div key={e}>
          <LoadingFiltersWrapper>
            <LoadingFiltersLabelPlaceholder
              className="filter-label"
              height="1rem"
              type="custom"
              width="60px"
            />
            <LoadingFiltersOptionPlaceholder
              className="filter-options"
              height="2.5rem"
              type="custom"
              width="95%"
            />
          </LoadingFiltersWrapper>
        </div>
      ))}
      <LoadingFlexWrapper>
        {[1, 2, 3].map((e) => (
          <LoadingDot key={e} height="1rem" width="1rem" type="custom" />
        ))}
      </LoadingFlexWrapper>
    </div>
  );
};
