import styled from "styled-components";

export const LoadingItemsWrapp = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: auto;

  grid-gap: 1rem;
  border: none;

  padding: 0;
  margin: 0;
  justify-content: flex-start;
  width: 100%;
`;
