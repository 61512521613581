import React from "react";
import WishlistIcon from "./WishlistIcon";
import CustomerIcon from "./CustomerIcon";

const HeaderIcons = () => {
  return (
    <>
      <CustomerIcon />
      <WishlistIcon />
    </>
  );
};

export default HeaderIcons;
