import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { isOpen: boolean } = {
  isOpen: false,
};

const ItemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    setIsOpen(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
  },
});

export const { setIsOpen } = ItemSlice.actions;
export default ItemSlice.reducer;
