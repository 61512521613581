import styled from "styled-components";

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.sp16} 0;
`;

export const ListItemContent = styled.div`
  flex: 1 1 auto;
`;
export const ListItemActions = styled.div`
  flex: 0 0 ${({ theme }) => theme.sizes.sz48};
  display: flex;
  justify-content: center;
`;

export const ERPCode = styled.div`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  margin-top: ${({ theme }) => theme.spacing.sp4};

  svg {
    transform: translate(0, 3px);
    margin-right: ${({ theme }) => theme.spacing.sp4};
  }
`;

export const ListItemImage = styled.div`
  max-width: ${({ theme }) => theme.sizes.sz48};
  margin-right: ${({ theme }) => theme.spacing.sp12};
`;

export const CommonAssortmentIndicator = styled.div`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  margin-top: ${({ theme }) => theme.spacing.sp4};
  color: ${({ theme }) => theme.colors.signal.success};

  svg {
    transform: translate(0, 3px);
    margin-right: ${({ theme }) => theme.spacing.sp4};
  }
`;
export const PIPItemIndicator = styled(CommonAssortmentIndicator)`
  color: ${({ theme }) => theme.colors.signal.alert};
`;

export const HiddenInput = styled.input`
  border: none;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  margin-left: ${({ theme }) => theme.spacing.sp4};
  &:focus {
    outline: none;
    background: ${({ theme }) => theme.colors.background.white};
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

export const TooltipText = styled.span`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
`;
export const CheckboxContainer = styled.span`
  margin-right: ${({ theme }) => theme.spacing.sp8};
`;
