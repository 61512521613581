/* istanbul ignore file */

import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { BatchHttpLink } from "apollo-link-batch-http";
import { onError } from "apollo-link-error";

export interface env {
  API_URL: string;
  GS_API_URL: string;
  ELASTIC_API_URL: string;
}

export interface CustomWindow extends Window {
  env?: env;
}
declare let window: CustomWindow;

const defaultUri =
  window?.env?.API_URL ?? "https://api.test.eriksdigital.com/projectwiki-bff/";
const gbUri = window?.env?.GS_API_URL;

const defaultLink = new BatchHttpLink({ uri: defaultUri });
const guidedBuyingLink = new BatchHttpLink({ uri: gbUri });

export const createApolloClient = () => {
  return new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }),
      ApolloLink.split(
        (operation) => operation.getContext().clientName === "guided",
        guidedBuyingLink,
        defaultLink
      ),
    ]),
    cache: new InMemoryCache(),
  });
};
