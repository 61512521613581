import gql from "graphql-tag";

export const FullItemFields = gql`
  fragment FullItemFields on Item {
    Descriptions: has_description_i(filter: { languageCode: $languageCode }) {
      shortDescription
      longDescription
      fullImage
      published
      keyword
      source
    }
    has_erp_item(filter: { erpSystem: $languageCode }) {
      hsCode
    }
    partNumber
    manufacturerPartNumber
    commonAssortment
    productLogo
    Documents: document_for_item(
      filter: {
        OR: [{ mediaType_contains: "Image" }, { mediaType_contains: "image" }]
      }
    ) {
      mediaType
      atchastPath
      attachusg_id
    }
    products {
      partNumber
      brand
      Descriptions: has_description_p(filter: { languageCode: $languageCode }) {
        longDescription
        richContentLongDescription
        Application
        Approval
        Construction
        Execution
        OperatingPrinciple
        Options
        OtherRemarks
        SelectionGuideline
        Tables
        TechnicalInformation
      }
      Documents: document_for_product(
        filter: { OR: [{ mediaType_contains: "Product Icon" }] }
      ) {
        mediaType
        atchastPath
        attachusg_id
      }
    }
    has_contact {
      erpSystem
      contactEmail
      contactId
      name
      source
      shopId
      contactTel
      sip
    }
    features: sequencedFeature(languageCode: $languageCode) {
      value
      type
      unitDescription
      attributeIdentifier
      featureKey
      name
      selectorOnly
      featureCategory: feature_category {
        Descriptions: has_description_fc(
          filter: { languageCode: $languageCode }
        ) {
          name
        }
      }
    }
    mediums(languageCode: $languageCode) {
      chemicalCAS
      chemicalFormula
      chemicalId
      chemicalName
      score
      scoreDescription
    }
    has_replacement {
      Descriptions: has_description_i(filter: { languageCode: $languageCode }) {
        shortDescription
        longDescription
        fullImage
        published
        keyword
        source
      }
      has_erp_item(filter: { erpSystem: $countryCode }) {
        hsCode
      }
      partNumber
      manufacturerPartNumber
      commonAssortment
      productLogo
      has_contact {
        erpSystem
        contactEmail
        contactId
        name
        source
        shopId
        contactTel
        sip
      }
      products {
        Descriptions: has_description_p(
          filter: { languageCode: $languageCode }
        ) {
          longDescription
          richContentLongDescription
        }
        partNumber
        brand
      }
    }
  }
`;
