import React, { useContext, SyntheticEvent } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { Toast } from "@eriksdigital/atomic-ui/components";
import {
  NotificationContext,
  NotificationContextInterface,
} from "../../utils/NotificationContext";

export const NotificationCenter = () => {
  const { notifications, dismissNotification } = useContext(
    NotificationContext
  );

  return ReactDOM.createPortal(
    <ToastWrapper>
      <Notifications
        notifications={notifications}
        dismissNotification={dismissNotification}
      />
    </ToastWrapper>,
    document.querySelector("#notifications") || document.createElement("div")
  );
};

export const Notifications = ({
  notifications,
  dismissNotification,
}: NotificationContextInterface) => {
  const handleDismissNotification = (e: SyntheticEvent) => {
    e.stopPropagation();
    const target = e.currentTarget as HTMLElement;

    dismissNotification && dismissNotification(target.id.split("_")[1]);
  };
  return (
    <>
      {notifications &&
        notifications.map((notification) => {
          return (
            <Toast
              id={notification.id}
              key={notification.id}
              alert={notification.variant}
              onClick={handleDismissNotification}
            >
              {notification.message}
            </Toast>
          );
        })}
    </>
  );
};

const ToastWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  min-height: 200px;
  position: fixed;
  top: 32px;
  right: 32px;
  z-index: 15;

  &:empty {
    display: none;
  }
`;
