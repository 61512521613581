import copy from "copy-to-clipboard";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { NotificationContext } from "../utils/NotificationContext";

export default function useCopy() {
  const { createNotification } = useContext(NotificationContext);

  const handleCopy = (text: string) => (e: React.SyntheticEvent) => {
    copy(text);
    createNotification &&
      createNotification({
        id: `${Date.now()}-${text}`,
        variant: "success",
        message: (
          <span>
            <strong>{text}</strong>{" "}
            <FormattedMessage id="copied-to-clipboard" />
          </span>
        ),
        autoDismiss: true,
      });

    e.preventDefault();
    e.stopPropagation();
  };

  return handleCopy;
}
