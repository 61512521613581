import React, { useRef } from "react";
import { ComponentType, createElement, lazy } from "react";
import { matchPath, Link, RouteProps } from "react-router-dom";
import { routes } from "../App";

interface ToProps {
  pathname: string;
  search?: string;
  hash?: string;
}

export type PreloadableComponent<T extends ComponentType<any>> = T & {
  preload: () => Promise<void>;
};

export default function lazyWithPreload<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>
): PreloadableComponent<T> {
  const LazyComponent = lazy(factory);
  let factoryPromise: Promise<void> | undefined;
  let LoadedComponent: T | undefined;

  const Component = ((props) =>
    createElement(
      LoadedComponent ?? LazyComponent,
      props
    )) as PreloadableComponent<T>;

  Component.preload = () => {
    if (!factoryPromise) {
      factoryPromise = factory().then((module) => {
        LoadedComponent = module.default;
      });
    }

    return factoryPromise;
  };
  return Component;
}

export const findComponentForRoute = (path: ToProps) => {
  const matchingRoute: RouteProps | undefined = routes.find(
    (route: RouteProps) => {
      return matchPath(path.pathname, {
        path: route.path,
        exact: route.exact,
      });
    }
  );
  return matchingRoute ? matchingRoute.component : null;
};

const preloadRouteComponent = (path: ToProps, delay: number) => {
  let timerId;
  const component: PreloadableComponent<any> = findComponentForRoute(path);
  if (component && component.preload) {
    timerId = setTimeout(() => {
      component.preload();
      timerId = undefined;
    }, delay);
  }
  return timerId;
};

export const LinkWithPreload = ({
  to,
  onClick,
  children,
  ...rest
}: {
  to: ToProps;
  onClick?: () => void;
  children: any;
}) => {
  const timerId = useRef<number | undefined>(0);

  const handleMouseEnter = () => {
    timerId.current = preloadRouteComponent(to, 100);
  };

  const handleMouseLeave = () => {
    timerId.current && clearTimeout(timerId.current);
  };

  return (
    <Link
      to={to}
      onClick={() => onClick && onClick()}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
    >
      {children}
    </Link>
  );
};
