import styled from "styled-components";
export const SpanContainer = styled.span`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;
export const SpanCenterContainer = styled(SpanContainer)`
  margin: auto;
`;
export const TitleContainer = styled.span`
  margin-right: ${({ theme }) => theme.spacing.sp4};
`;
