import { Param } from "../interfaces/param";
import { CoreParams } from "./param_variables";

export const getFilteredParam = (skippedAttributes: string[] = []) => (
  querystring: string
) => {
  const params = getObjectFromParams(querystring);
  const obj: any = {};
  const keys = Object.keys(params).filter(
    (key: string) => !skippedAttributes.includes(key)
  );
  for (const key of keys) {
    obj[key] = params[key];
  }
  return obj;
};

export const getFilterParams = getFilteredParam(CoreParams);

export const getIncludedOnlyParams = (includeOnly: string[] = []) => (
  querystring: string
) => {
  const params = getObjectFromParams(querystring);
  const obj: any = {};
  const keys = Object.keys(params);
  for (const key of keys) {
    if (includeOnly.includes(key)) {
      obj[key] = params[key];
    } else {
      obj[key] = null;
    }
  }
  return obj;
};
export const getCoreParams = getIncludedOnlyParams(CoreParams);

export const getParamString = (param: Param) => {
  if (!param || !!isObjectEmpty(param)) return "";
  return Object.entries(param)
    .filter(([key, value]) => !!key && !!value)
    .reduce((query, current, currentIndex) => {
      const amp = currentIndex !== 0 ? "&" : "";
      const [key, value] = current;
      if (!key || !value) return query;
      return `${query}${amp}${key}=${decodeURIComponent(value)}`;
    }, "?");
};

export const getModifiedParams = (location: string, newParams: Param) => {
  const params = new URLSearchParams(location);

  Object.entries(newParams).map(([key, value]) => {
    if (!params.get(key) && !!value) {
      return params.append(key, value);
    }
    // If value is null or undefined, remove from params.
    // Don't touch 0
    return value !== null && value !== undefined && value !== ""
      ? params.set(key, value)
      : params.delete(key);
  });
  return params.toString();
};

export const getObjectFromParams = (q: string): Param => {
  if (!q) return {};
  const querystring = q.charAt(0) === "?" ? q.substring(1) : q;
  const params = querystring.replace(/\+/g, " ").split("&");
  const obj: Param = {};
  params.forEach((param: string) => {
    const [key, value] = param.split("=");
    if (!!key && !!value)
      obj[decodeURIComponent(key)] = decodeURIComponent(value);
  });
  return obj;
};

export const isObjectEmpty = (obj: object): boolean =>
  Object.keys(obj).length === 0 && obj.constructor === Object;
