import React, { Suspense, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  AuthenticatedTemplate,
  useAccount,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import { MainLayout } from "@eriksdigital/atomic-ui/components";

import lazyWithPreload from "utils/LazyPreload";
import { setLoggedUser } from "state/user";

import { MainContent } from "containers/Home/style";
import CompareBar from "components/CompareBar";
import { HeaderMain } from "components/Header";
import { Loading } from "components/Loader/Loading";
import { CustomerModal } from "components/Modal/CustomerModal";
import { NavSubHeader } from "components/NavSubHeader";
import { NotificationCenter } from "components/NotificationCenter";
import ErrorBoundary from "ErrorBoundary";
import Wrapper from "./Wrapper";

import { callMsGraph } from "./graph";
import { loginRequest } from "./authConfig";

import "./App.css";
import { InteractionType } from "@azure/msal-browser";
import RequestInterceptor from "interceptor";

const Product = lazyWithPreload(() => import("./containers/Product"));
const ProductGroup = lazyWithPreload(() => import("./containers/ProductGroup"));
const ElasticSearchResults = lazyWithPreload(() =>
  import("./containers/ElasticHome/SearchResults")
);
const Item = lazyWithPreload(() => import("./containers/Item"));
const WishlistModal = lazyWithPreload(() =>
  import("./components/Modal/WishlistModal")
);
const Compare = lazyWithPreload(() => import("./containers/CompareItems"));
const ElasticHome = lazyWithPreload(() => import("./containers/ElasticHome"));

const navSubHeaderRoutes = [
  {
    path: "/product/:partNumber",
    exact: false,
    children: <NavSubHeader pageType="product" />,
  },
  {
    path: "/item/:partNumber",
    exact: false,
    children: <NavSubHeader pageType="item" />,
  },
  {
    path: "/group/:groupIdentifier",
    exact: false,
    children: <NavSubHeader pageType="productGroup" />,
  },
];

export const routes = [
  {
    path: "/search/products",
    exact: false,
    children: <ElasticSearchResults pageName={"products"} />,
    component: ElasticSearchResults,
  },
  {
    path: "/search/items",
    exact: false,
    children: <ElasticSearchResults pageName={"items"} />,
    component: ElasticSearchResults,
  },
  {
    path: "/search/groups",
    exact: false,
    children: <ElasticSearchResults pageName={"productgroups"} />,
    component: ElasticSearchResults,
  },
  {
    path: "/product/:partNumber",
    exact: false,
    children: <Product />,
    component: Product,
  },
  {
    path: "/group/:groupIdentifier",
    exact: false,
    children: <ProductGroup />,
    component: ProductGroup,
  },
  {
    path: "/item/:partNumber",
    exact: false,
    children: <Item />,
    component: Item,
  },
  {
    path: "/compare/:partNumber",
    exact: false,
    children: <Compare />,
    component: Compare,
  },
  {
    path: "/compare",
    exact: false,
    children: <Compare />,
    component: Compare,
  },
  {
    path: "/",
    exact: true,
    children: <ElasticHome />,
    component: ElasticHome,
    name: "Home",
  },
];

const App = () => {
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || null);
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useMsalAuthentication(InteractionType.Redirect);

  useEffect(() => {
    if (account !== null) {
      const acquireToken = instance.acquireTokenSilent({
        ...loginRequest,
        account,
      });

      acquireToken.then((response) => {
        setAccessToken(response.accessToken);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    if (accessToken) {
      const call = callMsGraph(accessToken);
      call.then((response) => {
        dispatch(setLoggedUser(response));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return (
    <>
      <AuthenticatedTemplate>
        <Wrapper>
          <div className="App">
            <HeaderMain />
            <ErrorBoundary>
              <RequestInterceptor>
                <>
                  <MainLayout>
                    <Switch>
                      {navSubHeaderRoutes.map((route) => {
                        return (
                          <Route
                            key={route.path}
                            path={route.path}
                            exact={route.exact}
                          >
                            {route.children}
                          </Route>
                        );
                      })}
                    </Switch>
                    <MainContent>
                      <Suspense fallback={<Loading />}>
                        <Switch>
                          {routes.map((route) => {
                            return (
                              <Route
                                key={route.path}
                                path={route.path}
                                exact={route.exact}
                              >
                                {route.children}
                              </Route>
                            );
                          })}
                        </Switch>
                      </Suspense>
                    </MainContent>
                  </MainLayout>
                  <Suspense fallback={<Loading />}>
                    <CompareBar />
                    <WishlistModal />
                    <CustomerModal />
                  </Suspense>
                  <NotificationCenter />
                </>
              </RequestInterceptor>
            </ErrorBoundary>
          </div>
        </Wrapper>
      </AuthenticatedTemplate>
    </>
  );
};

export default App;
