/**
 *
 * LanguageSelectorContent (Dropdowns, displayed for mobile)
 *
 */

import React, { useEffect } from "react";
import { Dropdown } from "@eriksdigital/atomic-ui/components";
import {
  StyledTitle,
  StyledSelectorContent,
  StyledDropdownWrapper,
  pxToRem,
} from "../style";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { contextSelector } from "state/selector";
import {
  CountryType,
  LanguageType,
  setCountry,
  setERPSystem,
  setLanguage,
} from "state/context";
import { setSearchCountry } from "state/customer";
import {
  DropdownValue,
  getAvailableCountry,
  getAvailableLanguage,
} from "utils/CountryLanguageUtils";
import AssortmentFilter from "../../AssortmentFilter";

const isSelectedLanguageIncluded = (
  languages: DropdownValue[],
  code: LanguageType
) => languages.map(({ value }) => value).includes(code);

const CountryLanguageSelectorPopup = () => {
  const dispatch = useDispatch();
  const { languageCode, countryCode } = useSelector(contextSelector);
  const countries = getAvailableCountry();
  const languages = getAvailableLanguage(countryCode);

  useEffect(() => {
    if (!isSelectedLanguageIncluded(languages, languageCode)) {
      if (languages[0])
        dispatch(setLanguage(languages[0].value as LanguageType));
    }
  }, [languageCode, languages]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCountryCodeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value =
      event.currentTarget.dataset.value || event.currentTarget.value;

    dispatch(setCountry(value as CountryType));
    dispatch(setERPSystem(value as CountryType));
    dispatch(setSearchCountry(value as CountryType));
  };

  const handleLanguageCodeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value =
      event.currentTarget.dataset.value || event.currentTarget.value;
    dispatch(setLanguage(value as LanguageType));
  };

  return (
    <StyledSelectorContent data-testid="country-language-selector">
      <StyledTitle>
        <FormattedMessage id="country-language-selector.country-language" />
      </StyledTitle>
      <StyledDropdownWrapper>
        <Dropdown
          id="select-country"
          label={<FormattedMessage id="country-language-selector.country" />}
          setWidth={pxToRem(250)}
          list={countries}
          value={countryCode}
          onClick={handleCountryCodeChange}
        ></Dropdown>
      </StyledDropdownWrapper>
      {!!languageCode.length &&
        isSelectedLanguageIncluded(languages, languageCode) && (
          <StyledDropdownWrapper>
            <Dropdown
              id="select-language"
              label={
                <FormattedMessage id="country-language-selector.language" />
              }
              list={languages}
              setWidth={pxToRem(250)}
              value={languageCode}
              onClick={handleLanguageCodeChange}
            />
          </StyledDropdownWrapper>
        )}
      <AssortmentFilter />
    </StyledSelectorContent>
  );
};

export default CountryLanguageSelectorPopup;
