import gql from "graphql-tag";

export const LAST_UPDATED_QUERY = gql`
  query LastUpdate {
    LastUpdate {
      pim {
        year
        month
        day
      }
    }
  }
`;
