import styled from "styled-components";
import { Placeholder } from "@eriksdigital/atomic-ui/components";

export const LoadingFiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LoadingFlexWrapper = styled.div`
  display: flex;
`;

export const LoadingFiltersLabelPlaceholder = styled(Placeholder)`
  margin-bottom: 0.2rem;
`;

export const LoadingFiltersOptionPlaceholder = styled(Placeholder)`
  margin-bottom: 0.25rem;
`;

export const LoadingDot = styled(Placeholder)`
  margin-right: ${({ theme }) => theme.spacing.sp4};
`;
