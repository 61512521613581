import { useLazyQuery } from "@apollo/react-hooks";
import { useEffect } from "react";
import { ItemParam } from "interfaces/param";
import {
  getSingleItem,
  getSingleItemVariables,
} from "../../Item/schema/getSingleItem";
import { SINGLE_ITEM_QUERY } from "../../Item/queries";
import {
  getItemRelatedERPItems,
  getItemRelatedERPItemsVariables,
} from "components/ERPItemTable/schema/getItemRelatedERPItems";
import { ITEM_ERP_ITEMS_QUERY } from "components/ERPItemTable/queries";
import { _ERPItemOrdering, _ItemFilter } from "globalTypes";
import {
  SingleItemData,
  SingleERPData,
  ResultData,
  ResultHandler,
} from "interfaces/compare";
import { useSelector } from "react-redux";
import { contextSelector } from "state/selector";
import { CountryType, defaultCountryCode } from "state/context";

/** THIS IS A FIX COMPONENT
 * WE NEED TO CALL EACH QUERY ONE BY ONE
 * USING ONE FILTER "OR" WILL CAUSE A LONG LOAD AND NEGLECTING CACHE
 */
export const getItemFilter = (
  allAssortments: boolean = true,
  countryCode: CountryType = defaultCountryCode
): _ItemFilter => {
  if (allAssortments) return {};
  return {
    erpSystems_contains: [countryCode],
  };
};

export const ItemQueryComponent = ({
  partNumber,
  onDataLoaded,
}: ItemParam & ResultHandler<ResultData<SingleItemData>>) => {
  const { countryCode, languageCode, allAssortments } = useSelector(
    contextSelector
  );

  const [loadData, { data, error, loading }] = useLazyQuery<
    getSingleItem,
    getSingleItemVariables
  >(SINGLE_ITEM_QUERY, {
    variables: {
      partNumber: decodeURIComponent(partNumber),
      languageCode,
      countryCode,
      filter: getItemFilter(allAssortments, countryCode),
    },
  });

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onDataLoaded)
      onDataLoaded({
        data: data?.Item,
        loading,
        error,
      });
  }, [data, error, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export const ERPCodeQueryComponent = ({
  partNumber,
  onDataLoaded,
}: ItemParam & ResultHandler<ResultData<SingleERPData>>) => {
  const { languageCode } = useSelector(contextSelector);
  const [loadData, { data, error, loading }] = useLazyQuery<
    getItemRelatedERPItems,
    getItemRelatedERPItemsVariables
  >(ITEM_ERP_ITEMS_QUERY, {
    variables: {
      partNumber: partNumber,
      languageCode,
      orderBy: [_ERPItemOrdering.shopId_asc],
    },
  });

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onDataLoaded)
      onDataLoaded({
        data: data?.Item?.[0]?.ERPItems,
        brand: data?.Item?.[0]?.brand,
        loading,
        error,
      });
  }, [data, error, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
