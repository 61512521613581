import { Icon } from "@eriksdigital/atomic-ui/components";
import { UserIcon } from "@eriksdigital/atomic-ui/components/Icons";
import { colors } from "@eriksdigital/atomic-ui/styles";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpen } from "state/customer";
import { customerSelector } from "state/selector";
import CopyToClipboard from "components/CopyToClipboard";
import { SpanCenterContainer } from "components/CopyToClipboard/style";
import {
  HeaderIconWrapper,
  IconLabel,
  IconWrapper,
  ItemCountCircle,
} from "./../style";
import { CopyContainer } from "./style";

const CustomerIcon = () => {
  const dispatch = useDispatch();
  const { selectedCustomer } = useSelector(customerSelector);

  return (
    <HeaderIconWrapper>
      <IconWrapper
        data-testid="customer-finder"
        onClick={() => dispatch(setIsOpen(true))}
      >
        {!!selectedCustomer && (
          <ItemCountCircle
            isLargeAmount={false}
            background={colors.text.proceed}
            color="white"
          >
            ✓
          </ItemCountCircle>
        )}
        <Icon
          as={UserIcon}
          fill={colors.heading.primary}
          height={30}
          width={30}
          viewBox="0 0 35 35"
        />
      </IconWrapper>
      <IconLabel>
        {!selectedCustomer && (
          <FormattedMessage id="customer.customer-finder" />
        )}
        {!!selectedCustomer && (
          <span>
            {selectedCustomer.customerName}
            <br />
            <CopyContainer>
              <CopyToClipboard
                Container={SpanCenterContainer}
                text={selectedCustomer.customerNo}
              >
                <>{selectedCustomer.customerNo}</>
              </CopyToClipboard>
            </CopyContainer>
          </span>
        )}
      </IconLabel>
    </HeaderIconWrapper>
  );
};

export default CustomerIcon;
